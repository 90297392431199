import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { Loadingicon } from "../../../../AppIcons";
import BreadCrumbs from "../../../common/BreadCrumbs";
import { useAuth } from "../../../../Context/AuthContext";
import { Formik } from "formik";
import CustomSelect from "../../../common/customselect";
// import { getForumSubject, getForumTopic, postAForumQuestion } from "../../../../api/forum";
import { EDITOR_KEY, ERROR_SOMETHING_WENT_WRONG, TINY_EDITOR_KEY } from "../../../../utils/utils";
import { useSearch } from "../../../../Context/SearchContext";
import { getBlogCategory, getBlogSubCategory, postBlog } from "../../../../api/blog";
// import { Editor } from "@tinymce/tinymce-react";
import { getTutorialCategory, postTutorial } from "../../../../api/tutorial";
import FAQItem from "../faq";

import { HtmlEditor, Image, Inject, Link, QuickToolbar, RichTextEditorComponent, Table, Toolbar } from '@syncfusion/ej2-react-richtexteditor';
import { registerLicense } from '@syncfusion/ej2-base';
// Register your Syncfusion license key
registerLicense(EDITOR_KEY);

function PostATutorial() {
    const moderateEditorRef = useRef(null);
    const [loading, setLoading] = useState(false);
    const [submitBtnLoading, setSubmitBtnLoading] = useState(false);
    const [draftBtnLoading, setDraftBtnLoading] = useState(false);
    const [tagId, setTagId] = useState([]);
    const [isTagLoading, setIsTagLoading] = useState(false);
    const { globalToken } = useAuth();
    const [submitAction, setSubmitAction] = useState();
    const [tagFormatted, setTagFormatted] = useState([]);
    const [faqContainers, setFaqContainers] = useState([{ question: '', answer: '' }]);

    const toolbarSettings = {
        items: ['Bold', 'Italic', 'Underline', 'StrikeThrough',
            'FontName', 'FontSize', 'FontColor', 'BackgroundColor',
            'LowerCase', 'UpperCase', '|',
            'Formats', 'Alignments', 'OrderedList', 'UnorderedList',
            'Outdent', 'Indent', '|',
            'CreateLink', 'Image', '|', 'ClearFormat', 'Print',
            'SourceCode','CreateTable', 'FullScreen', '|', 'Undo', 'Redo']
    };
    const quickToolbarSettings = {
        image: ['Replace', 'Align', 'Caption', 'Remove', 'InsertLink', 'OpenImageLink', '-', 'EditImageLink', 'RemoveImageLink', 'Display', 'AltText', 'Dimension'],
        link: ['Open', 'Edit', 'UnLink']
    };



    const DisplayingErrorMessagesSchema = Yup.object().shape({
        title: Yup.string().required("Title is required"),
        description: Yup.string().required("Description is required"),
        about: Yup.string().required("About Author is required"),
        tag: Yup.array().min(1, "Choose at least one tag").required("Tags are required")
    });

    const navigate = useNavigate();
    useEffect(() => {
        handleFetchAllTutorialTags(1);
    }, [])


    const handlePlusClick = () => {
        // Add a new FAQ container and expand its size
        setFaqContainers([...faqContainers, { question: '', answer: '' }]);
    };


    const handleMinusClick = (index) => {
        // Remove the relevant FAQ container
        setFaqContainers(faqContainers.filter((_, i) => i !== index));
    };

    const handleFetchAllTutorialTags = async (pageNo) => {
        const resAllTutorialTag = await getTutorialCategory({ pageNo: pageNo });
        // console.log(resAllTutorialTag)
        if (resAllTutorialTag?.success) {
            const tutorialTag = resAllTutorialTag?.data?.data;
            if (tutorialTag?.length) {
                const cData = tutorialTag?.map(option => ({
                    value: option.id,
                    label: option.name
                })) || [];


                setTagFormatted(cData);
            }
        } else {
            toast.error(ERROR_SOMETHING_WENT_WRONG)
        }


        setLoading(false);
    }



    const formData = [
        {
            id: 'title',
            title: 'Title',
            type: 'text',
            name: 'title',
            placeholder: 'Blog Title',
            required: true,
            editable: true
        },
        {
            id: 'tag',
            title: 'Tag',
            type: 'text',
            name: 'tag',
            placeholder: 'Pick from the list',
            required: true,
            editable: true,
            isTag: true
        },
        {
            id: 'about',
            title: 'About Author',
            type: 'text',
            name: 'about',
            placeholder: 'About author',
            required: true,
            editable: true,
            width: true
        },
        {
            id: 'description',
            title: 'Description',
            type: 'text',
            name: 'description',
            placeholder: 'Description',
            required: true,
            editable: true,
            width: true
        }
    ]


    const handlePostTutorial = async (values, type) => {
        type=='save'?setSubmitBtnLoading(true):setDraftBtnLoading(true);
       
        const resPostTutorial = await postTutorial({ token: globalToken, faq:faqContainers, title: values?.title, description: values?.description, tag: values?.tag, type: type, image:values?.image,about:values?.about });
        if (resPostTutorial?.success) {
            toast.success('Tutorial posted successfully.');
            navigate('/tutorials/my-tutorials')
        } else {
            toast.error(ERROR_SOMETHING_WENT_WRONG)
        }
        setSubmitBtnLoading(false)
        setDraftBtnLoading(false)
    }

    const handleCustomSelectOnChange = (value, type, label, setFieldValue) => {
        if (type == 'tag') {
            if (value) {
                setTagId(value);
                setFieldValue('tag', value);
            } else {
                setTagId(null);
                setFieldValue('tag', null);
            }
        }
    }


    return (
        <>
            <BreadCrumbs />
            {loading ? (
                <div className=" py-0 px-0 grid grid-cols-1 group-card card-body gap-4s">
                    <div className="w-full flex items-center justify-center mt-20 mb-20 text-themecolor min-h-[400px]">
                        <div className="h-10 w-10">
                            <Loadingicon />
                        </div>
                    </div>
                </div>) : (
                <div className="py-0 px-0 w-100 card-body gap-4s">

                    <div className="p-4 w-100 my-2">
                        <Formik
                            enableReinitialize={true}
                            initialValues={{
                                title: "",
                                description: "",
                                tag: null,
                                about:""
                            }}
                            validationSchema={DisplayingErrorMessagesSchema}
                            onSubmit={(values) => {
                                if (values){
                                    if (submitAction === 'post') {
                                        handlePostTutorial(values, 'save') // Call save function
                                    } else if (submitAction === 'draft') {
                                        handlePostTutorial(values,'draft') // Call draft function
                                    }
                                }
                            }}
                        >
                            {({
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleBlur,
                                setFieldValue,
                                handleSubmit,
                            }) => (
                                <form className="" onSubmit={handleSubmit}>
                                    <div className="row">
                                        {
                                            formData?.map((fieldMeta, _i) => (
                                                <div className={`position-relative  ${fieldMeta.width ? 'col-md-12' : 'col-md-12'}`}>
                                                    <label
                                                        htmlFor={fieldMeta?.id}
                                                        className={`block mb-2 text-sm font-medium text-gray-900 dark:text-gray-500`}
                                                    >
                                                        {fieldMeta?.title}

                                                        {
                                                            fieldMeta?.required ? <span className="text-red-400 text-base">*</span> : null
                                                        }
                                                    </label>
                                                    <input type="password" name="fake-password" autoComplete="new-password" style={{ display: 'none' }} />
                                                    {
                                                        fieldMeta.width ?
                                                            <>

                                                                {/* <Editor
                                                                    apiKey={TINY_EDITOR_KEY}
                                                                    onInit={(evt, editor) => moderateEditorRef.current = editor}
                                                                    init={{
                                                                        height: '200',
                                                                        auto_focus: true,
                                                                        menubar: false,
                                                                        plugins: 'hr lists table textcolor code link image',
                                                                        toolbar: 'undo redo | blocks fontsize | bold italic forecolor link image media | alignleft aligncenter alignright | hr bullist numlist table | subscript superscript | removeformat code',

                                                                        // allow custom url in link? nah just disabled useless dropdown..
                                                                        file_picker_types: 'image',
                                                                        file_picker_callback: function (cb, value, meta) {
                                                                            var input = document.createElement('input');
                                                                            input.setAttribute('type', 'file');
                                                                            input.setAttribute('accept', 'image/*');
                                                                            input.onchange = () => {
                                                                                const file = input.files[0];
                                                                                const reader = new FileReader();

                                                                                reader.onload = () => {
                                                                                    const base64 = reader.result.split(',')[1];
                                                                                    cb('data:image/jpeg;base64,' + base64);
                                                                                };

                                                                                reader.readAsDataURL(file);
                                                                            };

                                                                            input.click();
                                                                        },
                                                                        anchor_top: false,
                                                                        anchor_bottom: false,
                                                                        branding: false,
                                                                        loading: true
                                                                    }}
                                                                    onEditorChange={(content, editor) => {
                                                                        setFieldValue(fieldMeta?.id, content);

                                                                    }}
                                                                /> */}

                                                                <RichTextEditorComponent height={350} toolbarSettings={toolbarSettings}
                                                                    quickToolbarSettings={quickToolbarSettings}
                                                                    id={'editorContent'+_i}
                                                                    change={(e) => {
                                                                    setFieldValue(fieldMeta?.id, e.value); // Update Formik value
                                                                    }}
                                                                    placeholder={fieldMeta?.title=='Description'?"Write description here":"Write about author"}>
                                                                        <div></div>
                                                                    <Inject services={[Toolbar, Image, Link, HtmlEditor, QuickToolbar, Table]}/>
                                                                </RichTextEditorComponent>

                                                                <div className="text-red-500 mt-1 text-sm text-right min-h-[20px]">
                                                                    {touched[fieldMeta?.name] && errors[fieldMeta?.name] && errors[fieldMeta?.name]}
                                                                </div>
                                                            </>
                                                            : fieldMeta.isTag ?
                                                                <div className="w-100" style={{zIndex:100, position:'relative'}}>

                                                                    <div className="d-block w-100" >
                                                                        {
                                                                            <CustomSelect options={tagFormatted} isMultiple={true} isClearable={true} handleCustomSelectOnChange={handleCustomSelectOnChange}
                                                                                setFieldValue={setFieldValue}
                                                                                type={'tag'} isSelectLoading={isTagLoading} placeholder={'Choose among tags'} />
                                                                        }

                                                                    </div>

                                                                    <div className="text-red-500 mt-1 text-sm text-right min-h-[20px]">
                                                                        {touched[fieldMeta?.name] && errors[fieldMeta?.name] && errors[fieldMeta?.name]}
                                                                    </div>
                                                                </div>: <>
                                                                        <input
                                                                            disabled={!fieldMeta?.editable}
                                                                            type={fieldMeta?.type}
                                                                            name={fieldMeta?.id}
                                                                            autoComplete={'off'}
                                                                            id={fieldMeta?.name}
                                                                            className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"

                                                                            placeholder={fieldMeta?.title}
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            value={values[fieldMeta?.id] || ""}
                                                                        />
                                                                        <div className="text-red-500 mt-1 text-sm text-right min-h-[20px]">
                                                                            {touched[fieldMeta?.name] && errors[fieldMeta?.name] && errors[fieldMeta?.name]}
                                                                        </div>
                                                                    </>}

                                                </div>
                                            ))
                                        }
                                        <div className="col-md-12 mb-3">
                                                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-500" >Set Tutorial Image (Supported Formats JPG,PNG,BMP,WEBP)</label>
                                                    <input type="file" onChange={(event) => {
                                                        setFieldValue('image', event.currentTarget.files[0]);
                                                    }}
                                                        onBlur={handleBlur}
                                                        className="border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                                        name="image" />
                                                </div>

                                                <label htmlFor={'faq'}
                                                    className={`block mb-2 text-sm font-medium position-relative text-gray-900 dark:text-gray-500`}
                                                >
                                                    FAQ
                                                    <button className="btn-plus" type="button" onClick={()=> handlePlusClick()}><i className="fa fa-plus"></i></button>
                                                </label>
                                                <div className="col-md-12">                                            {
                                                        faqContainers?.length?faqContainers?.map((fq, index)=>(
                                                            <FAQItem key={fq.id} TINY_EDITOR_KEY={TINY_EDITOR_KEY} moderateEditorRef={moderateEditorRef} faq={faqContainers} fq={fq} setFaqContainers={setFaqContainers} faqIndex={index} handleMinusClick={handleMinusClick} setFieldValue={setFieldValue}/>
                                                        )):null
                                                    }                                                    
                                                </div>

                                    </div>
                                    {/* <div className="col-md-2 mt-3">
                                        <button
                                            type="submit"
                                            disabled={btnLoading}
                                            className="mt-2 w-full text-white bg-themecolor hover:bg-opacity-75 disabled:bg-opacity-50 focus:ring-1 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center flex justify-center"
                                        >
                                            {btnLoading ? (
                                                <div className="h-5 w-5">
                                                    <Loadingicon />
                                                </div>
                                            ) : (
                                                "Submit"
                                            )}
                                        </button>
                                    </div> */}
                                    <div className="col-md-4 d-flex gap-2 mt-3">
                                    <button
                                            type="submit"
                                            disabled={submitBtnLoading}
                                            onClick={() => setSubmitAction('post')}
                                            className="mt-2 w-full text-white bg-themecolor hover:bg-opacity-75 disabled:bg-opacity-50 focus:ring-1 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center flex justify-center"
                                        >
                                            {submitBtnLoading ? (
                                                <div className="h-5 w-5">
                                                    <Loadingicon />
                                                </div>
                                            ) : (
                                                "Submit"
                                            )}
                                        </button>
                                        <button
                                            type="submit"
                                            disabled={draftBtnLoading}
                                            onClick={() => setSubmitAction('draft')}
                                            className="mt-2 w-full text-white bg-themecolor hover:bg-opacity-75 disabled:bg-opacity-50 focus:ring-1 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center flex justify-center"
                                        >
                                            {draftBtnLoading ? (
                                                <div className="h-5 w-5">
                                                    <Loadingicon />
                                                </div>
                                            ) : (
                                                "Save As Draft"
                                            )}
                                        </button>
                                    </div>
                                </form>
                            )}

                        </Formik>
                    </div>
                </div>


            )
            }

        </>
    );
}
export default PostATutorial;
