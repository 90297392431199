import React from 'react';

const Question = ({ question, onOptionChange, selectedOption }) => {
  // Parse options safely, only if it's a string
  let optionsArray = [];
  if (question?.options) {
    try {
      optionsArray = typeof question.options === 'string'
        ? JSON.parse(question.options).map(option => option.option_value)
        : question.options.map(option => option.option_value);
    } catch (error) {
      console.error("Error parsing question options:", error);
    }
  }

  return (
    <div>
      <div className="question-header">
        <span className="question-number">Question {question?.id}</span>
        <div className="info-item marks-section">
          <div>
            <strong>Marks : </strong>
          </div>
          <div className="marks-buttons">
            <button className="positive-mark-btn" disabled>+ {question?.marks}</button>
            <button className="negative-mark-btn" disabled>- 0</button>
          </div>
        </div>
      </div>
      
      <div className="question-text" dangerouslySetInnerHTML={{__html:question?.text}}>
        {}
      </div>
      <hr/>
      <div className="question-options">
        {optionsArray?.map((option, index) => (
          <label key={index} className="option">
            <input
              type="radio"
              name={`question-${question?.id}`}
              value={index}
              checked={selectedOption === index}
              onChange={() => onOptionChange(index)}
            />
            <span className="option-label">{String.fromCharCode(65 + index)}.</span>&nbsp;{option}
          </label>
        ))}
      </div>
    </div>
  );
};

export default Question;
