import React from 'react';
const CustomModal = ({ isOpen, onClose, onSubmit, totalQuestions, answeredCount, notAnsweredCount }) => {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-container">
        <h2>Submit Test</h2>
        <p>Total Questions: {totalQuestions}</p>
        <p>Answered: {answeredCount}</p>
        <p>Not Answered: {notAnsweredCount}</p>
        <div className="modal-buttons">
          <button className="submit-cancel-btn" onClick={onClose}>Cancel</button>
          <button className="submit-btn" onClick={onSubmit}>Submit</button>
        </div>
      </div>
    </div>
  );
};

export default CustomModal;
