import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import styles from "../../../assets/css/styles/Home.module.css";

export const FeedsLoader = () => (
  <>
    <div className={("poscard", styles.postcard)}>
      <div
        className={
          (styles.cardHead,
          "d-flex justify-content-between align-items-center px-3 pt-3")
        }
      >
        <div className={styles.postImage}>
          <div className={(styles.postedbycontainer, "d-flex col-12 px-0")}>
            <div>
            <Skeleton count={1} 
                circle
                width={40}
                height={40}
                className={styles.imgcircle}
              />
            </div>
            <div className="ms-3">
              <div className={styles.postedbyname}>
              <Skeleton count={1} width={100} />
              </div>
              <p className={styles.postedTime}> <Skeleton count={1} height={10} width={70}/></p>
            </div>
          </div>
        </div>
        <div className="rightMoreContainer position-relative">
        <Skeleton count={1} width={18} height={4} />
        </div>
      </div>
      <div className={(styles.postDescription, "px-3")}>
      <Skeleton count={3} height={8}/>
      <Skeleton count={1} width="50%" height={8} />
          </div>
          <Skeleton count={1} width="100%" height={200} borderRadius={0} />
      <div className="d-flex justify-content-between align-items-center py-3 px-3">
        <div className="d-flex align-items-center text-black-50">
        <Skeleton count={1}  width={20}
            height={7}
          />
         
        </div>
        <div>
          <span className="ms-2 small text-black-50 d-flex">
          <Skeleton count={1}  width={20}
          className="ms-1"
            height={7}
          />
             <Skeleton count={1}  width={20}
            height={7}
            className="ms-1"
          />
           <Skeleton count={1}  width={20}
            height={7}
            className="ms-1"
          />
          </span>
        </div>
      </div>
     
    </div>
  </>
);


export const DashboardLoader = () =>(
  <>
    
  </>
)

export const UsersLoader = () =>(
    <>
    <div className="d-flex align-items-center justify-content-between   mb-2  pb-2">
            <div
              className={
                (styles.otherPlatformLink,
                "otherPlatformLink max-60  d-flex align-items-center")
              }
            >
              <div className="d-flex">
                <Skeleton width={28}
                  height={28}
                  cirlce
                  count={1}
                  className="siderbarIco"
                  style={{ borderRadius: 100,filter:'none' }}
                />
              </div>
              <span className="ms-2 d-flex flex-column align-items-start" style={{lineHeight:'12px'}}>
                <Skeleton count={1} width={100} height={8}/>
                <span><Skeleton count={1} width={80} height={5}/></span>
                </span>
            </div>
            <Skeleton count={1} width={15} height={15}/>
          </div>
    </>
)


export const WebStoryLoader = () =>(
  <>
     <div style={{marginTop:-35}}>
           <Skeleton width={28}
                  height={28}
                  cirlce
                  count={1}
                  className="avatarIco"
                  style={{ borderRadius: 100,filter:'none' }}
                />
             <Skeleton count={1} width={113} height={200} borderRadius={10}/>
      </div> 
  </>
)
