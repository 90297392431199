import React from 'react'
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
} from "chart.js";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Doughnut, Bar, Line } from "react-chartjs-2";
import { useState } from 'react';
import { useEffect } from 'react';
import { Loadingicon } from '../../../AppIcons';
import { getOverviewData } from '../../../api/dashboard';
import { toast } from 'react-toastify';
import { DateRangePicker } from 'rsuite';
import 'rsuite/DateRangePicker/styles/index.css';
import trophy from '../../../assets/images/trophy.gif';
import dGraph from '../../../assets/images/d-graph.webp';
import uGraph from '../../../assets/images/u-graph.webp';

ChartJS.register(Tooltip, Legend, ArcElement, CategoryScale, PointElement, LineElement, LinearScale, ChartDataLabels); // Register the plugin

// const CustomDropDown = ({setSelectedOptions, selectedOptions}) => {
  

//   const options = ["Credit Earned", "Credit Spent", "Purchased Credits", "Withdrawals"];

//   const handleCheckboxChange = (option) => {
//     if (selectedOptions.includes(option)) {
//       setSelectedOptions(selectedOptions.filter((item) => item !== option));
//     } else {
//       setSelectedOptions([...selectedOptions, option]);
//     }
//   };

//   return (
//     <div className="dropdown">
//       <button
//         className="btn btn-default dropdown-toggle"
//         type="button"
//         id="dropdownMenuButton"
//         data-bs-toggle="dropdown"
//         aria-expanded="false"
//         style={{fontSize:14}}
//       >
//         {selectedOptions.length > 0
//           ? `Selected (${selectedOptions.length})`
//           : "Select Options"}
//       </button>
//       <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
//         {options.map((option) => (
//           <li key={option} className="dropdown-item flex items-center">
//             <label className="form-check-label flex items-center text-black  py-2" style={{fontSize:14}}>
//               <input
//                 type="checkbox"
//                 className="form-check-input me-2"
//                 value={option}
//                 checked={selectedOptions.includes(option)}
//                 onChange={() => handleCheckboxChange(option)}
//               />
//               {option}
//             </label>
//           </li>
//         ))}
//       </ul>
//     </div>
//   );
// };

const FinanceReportChart = ({selectedOptions}) => {
 
    
  const [filter, setFilter] = useState("today");
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [
      {
        label: "Credits Earned",
        data: [],
        borderColor: "#36A2EB",
        backgroundColor: "rgba(54, 162, 235, 0.2)",
        fill: true,
        tension: 0.4,
      },
      {
        label: "Credits Spent",
        data: [],
        borderColor: "#FF6384",
        backgroundColor: "rgba(255, 99, 132, 0.2)",
        fill: true,
        tension: 0.4,
      }
      ,
      {
        label: "Credits Purchased",
        data: [],
        borderColor: "#4BC0C0",
        backgroundColor: "rgba(75, 192, 192, 0.2)",
        fill: true,
        tension: 0.4,
      },
      {
        label: "Withdrawals",
        data: [],
        borderColor: "#FFCE56",
        backgroundColor: "rgba(255, 206, 86, 0.2)",
        fill: true,
        tension: 0.4,
      },
    ],
  });

  const generateMonthlyLabels = (months = 1, startMonthOffset = 0) => {
    const labels = [];
    const now = new Date();
    for (let i = 0; i < months; i++) {
      const date = new Date(now.getFullYear(), now.getMonth() - startMonthOffset - i);
      labels.unshift(date.toLocaleString("default", { month: "short", year: "numeric" }));
    }
    return labels;
  };

  const fetchData = (filter) => {
    let labels = [];
    let data;

    switch (filter) {
      case "today":
        labels = Array.from({ length: 24 }, (_, i) => `${i}:00`);
        data = {
          earned: Array(24).fill(0).map(() => Math.floor(Math.random() * 100)),
          spent: Array(24).fill(0).map(() => Math.floor(Math.random() * 50)),
        };
        break;

      case "yesterday":
        labels = Array.from({ length: 24 }, (_, i) => `${i}:00`);
        data = {
          earned: Array(24).fill(0).map(() => Math.floor(Math.random() * 100)),
          spent: Array(24).fill(0).map(() => Math.floor(Math.random() * 50)),
        };
        break;

      case "this_month":
        labels = Array.from({ length: 31 }, (_, i) => `Day ${i + 1}`);
        data = {
          earned: Array(31).fill(0).map(() => Math.floor(Math.random() * 500)),
          spent: Array(31).fill(0).map(() => Math.floor(Math.random() * 400)),
        };
        break;

      case "last_month":
        labels = Array.from({ length: 30 }, (_, i) => `Day ${i + 1}`);
        data = {
          earned: Array(30).fill(0).map(() => Math.floor(Math.random() * 500)),
          spent: Array(30).fill(0).map(() => Math.floor(Math.random() * 400)),
        };
        break;

      case "last_3_months":
        labels = generateMonthlyLabels(3);
        data = {
          earned: Array(3).fill(0).map(() => Math.floor(Math.random() * 1000)),
          spent: Array(3).fill(0).map(() => Math.floor(Math.random() * 800)),
        };
        break;

      case "last_6_months":
        labels = generateMonthlyLabels(6);
        data = {
          earned: Array(6).fill(0).map(() => Math.floor(Math.random() * 1500)),
          spent: Array(6).fill(0).map(() => Math.floor(Math.random() * 1200)),
        };
        break;

      case "this_year":
        labels = generateMonthlyLabels(12);
        data = {
          earned: Array(12).fill(0).map(() => Math.floor(Math.random() * 2000)),
          spent: Array(12).fill(0).map(() => Math.floor(Math.random() * 1800)),
        };
        break;

      case "previous_year":
        labels = generateMonthlyLabels(12, 12);
        data = {
          earned: Array(12).fill(0).map(() => Math.floor(Math.random() * 2000)),
          spent: Array(12).fill(0).map(() => Math.floor(Math.random() * 1800)),
        };
        break;

      default:
        labels = [];
        data = { earned: [], spent: [] };
    } 

    setChartData({
      labels: labels,
      datasets: selectedOptions=='cevscs'?[
        { ...chartData.datasets[0], data: data.earned },
        { ...chartData.datasets[1], data: data.spent }
      ]:[
        { ...chartData.datasets[2], data: data.earned.map((d) => d / 2) },
        { ...chartData.datasets[3], data: data.spent.map((d) => d / 2) },
      ],
    });
  };

  useEffect(() => {
    fetchData(filter);
  }, [filter, selectedOptions]);

  return (
    <div>
      
      <Line
        data={chartData}
        options={{
          responsive: true,
          plugins: { legend: { position: "top" } },
          scales: {
            x: { title: { display: true, text: "Date or Time" }, grid: { display: true } },
            y: { title: { display: true, text: "Credits" }, beginAtZero: true, grid: { display: true } },
          },
        }}
      />
    </div>
  );
};

const EngagementDonutChart = () => {
  // Data for the Donut Chart
  const chartData = {
    labels: ["Likes", "Comments", "Shares", "Views"], // Labels for each section
    datasets: [
      {
        data: [150, 75, 50, 100], // Engagement values for each section
        backgroundColor: [
          "#FF9F40", // Soft orange
          "#FFCD56", // Soft yellow
          "#36A2EB", // Soft blue
          "#4BC0C0"
        ],
        hoverBackgroundColor: [
          "#FF7F3F", // Slightly darker orange
          "#FFB344", // Slightly darker yellow
          "#2F7FB9", // Darker blue
          "#36A19B"
        ]
      },
    ],
  };

  // Options to make the chart look like a donut
  const options = {
    responsive: true,
    plugins: {
      tooltip: {
        callbacks: {
          label: function (context) {
            // Format the tooltip to show the number and percentage
            let total = 0;
            context.chart.data.datasets[0].data.forEach((value) => {
              total += value;
            });
            let percentage = ((context.raw / total) * 100).toFixed(1);
            return `${context.label}: ${context.raw} (${percentage}%)`;
          },
        },
      },
      legend: {
        position: "bottom", // Position of the legend
      }
    },
  };
  const totalEngagement = [150, 75, 50, 100].reduce((a, b) => a + b, 0);
  const percentages = [150, 75, 50, 100].map(
    (value) => ((value / totalEngagement) * 100).toFixed(1) // Calculate percentage for each segment
  );

  return (
    <div style={{ width: "280px", height: "280px", textAlign: 'center', position: 'relative', margin: '0 auto' }}>
      <Doughnut data={chartData} options={options} />
      <div
        style={{
          position: 'absolute',
          top: '41%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          color: '#333', // Make sure the text inside the donut is white
          fontSize: '20px',
          fontWeight: 'bold',
        }}
      >
        {totalEngagement}
      </div>
    </div>
  );
};

const VerticalBarChart = () => {
  const moduleData = [
    { module: "Question Bank", likes: 224, comments: 120, shares: 80, views: 400 },
    { module: "Interview Questions", likes: 180, comments: 90, shares: 60, views: 300 },
    { module: "FullForms", likes: 150, comments: 70, shares: 50, views: 250 },
    { module: "Exams", likes: 200, comments: 110, shares: 90, views: 350 },
    { module: "Dictionary", likes: 140, comments: 50, shares: 40, views: 200 },
    { module: "Quizzes", likes: 160, comments: 85, shares: 55, views: 270 },
    { module: "Career News", likes: 190, comments: 100, shares: 70, views: 330 },
    { module: "Career News Hindi", likes: 210, comments: 115, shares: 75, views: 370 },
    { module: "Feeds", likes: 230, comments: 125, shares: 85, views: 390 },
    { module: "Gigs", likes: 140, comments: 50, shares: 40, views: 200 },
    { module: "Quotes", likes: 120, comments: 40, shares: 30, views: 170 },
    { module: "Lyrics", likes: 130, comments: 45, shares: 35, views: 180 },
    { module: "Videos", likes: 220, comments: 130, shares: 90, views: 410 },
    { module: "Articles", likes: 190, comments: 100, shares: 60, views: 340 },
    { module: "Forum", likes: 160, comments: 85, shares: 55, views: 270 },
    { module: "Tutorials", likes: 240, comments: 135, shares: 95, views: 420 },
    { module: "Educators", likes: 180, comments: 90, shares: 60, views: 300 },
    { module: "Corporates", likes: 150, comments: 70, shares: 50, views: 250 },
  ];

  const labels = moduleData.map((item) => item.module);
  const totalEngagementCounts = moduleData.map(
    (item) => item.likes + item.comments + item.shares + item.views
  );

  const chartData = {
    labels, // X-axis labels (module names)
    datasets: [
      {
        label: "",
        data: totalEngagementCounts, // Y-axis data
        backgroundColor: [
          "#FF6384", // Light Red
          "#36A2EB", // Light Blue
          "#FFCE56", // Light Yellow
          "#4BC0C0", // Teal
          "#9966FF", // Light Purple
          "#FF9F40", // Light Orange
          "#FF6384", // Light Red
          "#36A2EB", // Light Blue
          "#FFCE56", // Light Yellow
          "#4BC0C0", // Teal
          "#9966FF", // Light Purple
          "#FF9F40", // Light Orange
          "#FF6384", // Light Red
          "#36A2EB", // Light Blue
          "#FFCE56", // Light Yellow
          "#4BC0C0", // Teal
          "#9966FF", // Light Purple
          "#FF9F40", // Light Orange
        ], // Array of smooth colors for bars
        borderColor: [
          "#FF6384",
          "#36A2EB",
          "#FFCE56",
          "#4BC0C0",
          "#9966FF",
          "#FF9F40",
          "#FF6384",
          "#36A2EB",
          "#FFCE56",
          "#4BC0C0",
          "#9966FF",
          "#FF9F40",
          "#FF6384",
          "#36A2EB",
          "#FFCE56",
          "#4BC0C0",
          "#9966FF",
          "#FF9F40",
        ], // Optional: Match border color to bar color
        borderWidth: 0,
        datalabels: {
          display: false,
        },
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false, // Single dataset, no legend needed
      },
      tooltip: {
        callbacks: {
          label: (context) =>
            `${context.raw.toLocaleString()} Total Engagements`,
        },
      },
    },
    scales: {
      x: {
        title: {
          display: false,
          text: "Modules",
          font: {
            size: 14,
          },
        },
        ticks: {
          font: {
            size: 12,
          },
        },
      },
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: "Total Engagement Count",
          font: {
            size: 14,
          },
        },
        ticks: {
          stepSize: 50, // Adjust step size for readability
        },
      },
    },
  };

  return (
    <Bar data={chartData} options={options} />
  );
};

const Dashboard = () => {
  const [data, setData] = useState(null);
  const [selectedOptions, setSelectedOptions] = useState("cevscs");
  const [loading, setLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [customRange, setCustomRange] = useState({ start: "", end: "" });
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const transactions = [
    {
      description: "Paypal payment for course",
      date: "14 Jun 2024 - 4:21 pm",
      amount: "-3,560",
      type: "debit",
    },
    {
      description: "Stripe payment received",
      date: "13 Jun 2024 - 1:15 pm",
      amount: "+7,200",
      type: "credit",
    },
    {
      description: "Bank transfer to vendor",
      date: "12 Jun 2024 - 10:30 am",
      amount: "-1,500",
      type: "debit",
    },
    {
      description: "Payment refund received",
      date: "11 Jun 2024 - 8:45 pm",
      amount: "+2,800",
      type: "credit",
    },
    {
      description: "Monthly subscription fee",
      date: "10 Jun 2024 - 5:10 pm",
      amount: "-999",
      type: "debit",
    },
    {
      description: "Course purchase",
      date: "9 Jun 2024 - 11:00 am",
      amount: "-4,320",
      type: "debit",
    },
    {
      description: "Affiliate payout",
      date: "8 Jun 2024 - 6:20 pm",
      amount: "+3,450",
      type: "credit",
    },
    {
      description: "Donation received",
      date: "7 Jun 2024 - 9:10 am",
      amount: "+1,200",
      type: "credit",
    },
    {
      description: "Payment for eBook",
      date: "6 Jun 2024 - 3:50 pm",
      amount: "-500",
      type: "debit",
    },
    {
      description: "Stripe payout",
      date: "5 Jun 2024 - 2:00 pm",
      amount: "+5,000",
      type: "credit",
    },
  ];

  const handleOptionChange = async (e) => {
    setLoading(true);
    const value = e.target.value;
    setSelectedOption(value);

    // If custom range is selected, do not immediately send the query
    if (value === "custom") {
      setLoading(false);
      return
    } else {
    };

    const queryMap = {
      today: "today",
      yesterday: "yesterday",
      "past-7-days": "week",
      "past-30-days": "thirty-days",
      "past-60-days": "sixty-days",
      "past-90-days": "three-months",
      "past-6-months": "six-months",
      "past-12-months": "twelve-months",
      "this-month": "this-month",
      "past-month": "past-month",
    };

    const query = `${queryMap[value]}`;
    const response = await getOverviewData({ query });
    setData(response.data);
    setLoading(false);
  };

  const handleCustomRangeSubmit = async () => {
    if (customRange.start && customRange.end) {
      setLoading(true);
      const response = await getOverviewData({ startDate: customRange.start, endDate: customRange.end });
      setData(response.data);
      setLoading(false);
    } else {
      toast.error("Please select both start and end dates.");
    }
  };
  const handleClearFilter = () => {
    setCustomRange({ start: "", end: "" });
    setSelectedOption("");
    fetchOverviewData();
  }

  const fetchOverviewData = async () => {
    setLoading(true);
    const response = await getOverviewData({ query: "today" });
    setData(response.data);
    setLoading(false);
  }
  useEffect(() => {
    fetchOverviewData();
  }, []);
  const doughnutData = {
    labels: [
      "Questions: 224(-20%)", "IQ Questions: 11(+20%)", "FullForms: 22(-20%)", "Exams: 120(1%)", "Dictionary: 24(-14%)", "Quizzes: 224(-20%)",
      "Career News: 1(3%)", "Career News Hindi: 224(-20%)", "Feeds: 110(11%)", "Gigs: 2(2%)", "Quotes: 46(12%)", "Lyrics: 20(20%)",
      "Videos: 224(-20%)", "Articles: 2(7%)", "Forum: 10(4%)", "Tutorial: 34(10%)", "Educators: 224(-20%)", "Corporates: 224(-20%)"
    ],
    datasets: [
      {
        data: [
          data?.qb || 224,  // Default value for Questions
          data?.iq || 11,  // Default value for IQ Questions
          data?.ff || 22,  // Default value for FullForms
          data?.exams || 120,  // Default value for Exams
          data?.dictionary || 24,  // Default value for Dictionary
          data?.quiz || 224,  // Default value for Quizzes
          data?.careernewsCount || 1,  // Default value for Career News
          data?.careernewsHindiCount || 224,  // Default value for Career News Hindi
          data?.feedsCount || 110,  // Default value for Feeds
          data?.gigs || 2,  // Default value for Gigs
          data?.quotes || 46,  // Default value for Quotes
          data?.lyrics || 20,  // Default value for Lyrics
          data?.videos || 224,  // Default value for Videos
          data?.articles || 2,  // Default value for Articles
          data?.forum || 10,  // Default value for Forum
          data?.tutorial || 34,  // Default value for Tutorial
          data?.educatorsCount || 224,  // Default value for Educators
          data?.corporatesCount || 224  // Default value for Corporates
        ],
        backgroundColor: [
          "#FFB3C1", "#FFDAA5", "#FFEBA1", "#AEE8E3", "#B4D8FF", "#D5A6FF",
          "#FF9AA2", "#FFCE8A", "#FFD59E", "#A8E6CF", "#85E3FF", "#CBA1FF",
          "#FFA3A3", "#FFC3A0", "#FFE1A8", "#A3E4DB", "#92DFF3", "#C79FFF"
        ],
        hoverBackgroundColor: [
          "#FFB3C1", "#FFDAA5", "#FFEBA1", "#AEE8E3", "#B4D8FF", "#D5A6FF",
          "#FF9AA2", "#FFCE8A", "#FFD59E", "#A8E6CF", "#85E3FF", "#CBA1FF",
          "#FFA3A3", "#FFC3A0", "#FFE1A8", "#A3E4DB", "#92DFF3", "#C79FFF"
        ]
      },
    ],
  };


  return (
    <>
      {loading ? <div className="w-full flex items-center justify-center mt-20 mb-20 text-themecolor min-h-[400px]">
        <div className="h-10 w-10">
          <Loadingicon />
        </div>
      </div> : <><div className="mb-3 row">
        <div className='col-md-4'>
          <div
            className="card h-100"
            style={{
              padding: "20px",
              boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
              width: "100%",
            }}
          >
            <div className='row'>
              <div className='col-md-8'>
                <h3 style={{ margin: 0, fontSize: '1.125rem', color: '#3c4043' }}>Congratulations, <span className='theme-color'>Olivia!</span></h3>
                <small className='color-gray'>Top community builder</small>
                <button className='mt-3 btn justify-content-center px-md-3 px-2 me-2 d-flex align-items-center border-0 btn-primary w-100 btn-sm credit-btn'>View Profile</button>
              </div>
              <div className='col-md-4'>
                <img src={trophy} style={{ width: '100%' }} />
              </div>
            </div>
            <div className='row mt-4'>
              <hr style={{ borderColor: '#f0f0f0' }} />
              <div className='col-md-4 my-4'>
                <div className='text-center d-flex flex-column align-items-center justify-center'>
                  <h3 style={{ margin: 0, fontSize: '1.125rem', fontWeight: 'bold' }}><a href="javascript:;" className="theme-color">1.5k</a></h3>
                  <small className='color-gray'>Feeds Posted</small>
                </div>
              </div>
              <div className='col-md-4 my-4'>
                <div className='text-center d-flex flex-column align-items-center justify-center'>
                  <h3 className="theme-color" style={{ margin: 0, fontSize: '1.125rem', fontWeight: 'bold' }}><a href="javascript:;" className="theme-color">200</a></h3>
                  <small className='color-gray'>Videos Uploads</small>
                </div>
              </div>
              <div className='col-md-4 my-4'>
                <div className='text-center d-flex flex-column align-items-center justify-center'>
                  <h3 className="theme-color" style={{ margin: 0, fontSize: '1.125rem', fontWeight: 'bold' }}><a href="javascript:;" className="theme-color">150</a></h3>
                  <small className='color-gray'>Courses Posted</small>
                </div>
              </div>
              <div className='col-md-4 mb-5 pt-4'>
                <div className='text-center d-flex flex-column align-items-center justify-center'>
                  <h3 className="theme-color" style={{ margin: 0, fontSize: '1.125rem', fontWeight: 'bold' }}><a href="javascript:;" className="theme-color">10</a></h3>
                  <small className='color-gray'>Blogs Posted</small>
                </div>
              </div>
              <div className='col-md-4 mb-5 pt-4'>
                <div className='text-center d-flex flex-column align-items-center justify-center'>
                  <h3 className="theme-color" style={{ margin: 0, fontSize: '1.125rem', fontWeight: 'bold' }}><a href="javascript:;" className="theme-color">10</a></h3>
                  <small className='color-gray'>Projects Posted</small>
                </div>
              </div>
              <div className='col-md-4 mb-5 pt-4'>
                <div className='text-center d-flex flex-column align-items-center justify-center'>
                  <h3 className="theme-color" style={{ margin: 0, fontSize: '1.125rem', fontWeight: 'bold' }}><a href="javascript:;" className="theme-color">10</a></h3>
                  <small className='color-gray'>My Campaigns</small>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='col-md-8'>
          <div
            className="card h-100"
            style={{
              padding: "20px",
              boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
              width: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              {/* Platform Overview Text */}
              <h2 style={{ margin: 0, fontSize: '1.125rem', color: '#3c4043' }}>System Overview</h2>

              {/* Filter Dropdown */}
              <div
                style={{
                  borderRadius: "5px",
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                }}
              >
                <select
                  className='filter-select'
                  value={selectedOption}
                  onChange={handleOptionChange}
                >
                  <option value="learn">Learn</option>
                  <option value="work">Work</option>
                  <option value="earn">Earn</option>
                  <option value="community">Community</option>
                  <option value="finance">Finance</option>
                </select>
                <select
                  className='filter-select'
                  value={selectedOption}
                  onChange={handleOptionChange}
                >
                  <option value="today">Today</option>
                  <option value="yesterday">Yesterday</option>
                  <option value="past-7-days">Past 7 Days</option>
                  <option value="past-30-days">Past 30 Days</option>
                  <option value="past-60-days">Past 60 Days</option>
                  <option value="past-90-days">Past 90 Days</option>
                  <option value="past-6-months">Past 6 Months</option>
                  <option value="past-12-months">Past 12 Months</option>
                  <option value="this-month">This Month</option>
                  <option value="past-month">Past Month</option>
                  <option value="custom">Custom Range</option>
                </select>
                {selectedOption === "custom" && (<>
                  <div>
                    <DateRangePicker format="MM/dd/yyyy" character=" – " onChange={(e) => { setCustomRange({ start: e[0].toLocaleDateString(), end: e[1].toLocaleDateString() }) }} />

                  </div>
                  <div style={{ display: "flex", height: 35.2 }}>
                    <button
                      className="btn btn-primary btn-sm me-1"
                      style={{
                        padding: "8px 12px",
                        borderRadius: "5px",
                        border: "none",
                        cursor: "pointer",
                      }}
                      onClick={handleCustomRangeSubmit}
                    >
                      Apply
                    </button>
                    {/* <button
                      className="btn btn-outline-secondary btn-sm"
                      style={{
                        padding: "8px 12px",
                        borderRadius: "5px",
                        cursor: "pointer",
                      }}
                      onClick={handleClearFilter}
                    >
                      Clear
                    </button> */}
                  </div>
                </>)
                }
              </div>

              {/* )} */}

            </div>
            <hr style={{ borderColor: '#f0f0f0' }} />
            {/* Doughnut Chart */}
            <div style={{ marginBottom: "20px", height: "250px" }}>
              <Doughnut
                data={doughnutData}
                options={{
                  plugins: {
                    legend: {
                      position: "right",
                      labels: {
                        boxWidth: 28,
                        padding: 10,
                      },
                    },
                    tooltip: {
                      enabled: true,
                    },
                    datalabels: {
                      display: false,
                    },
                  },
                  maintainAspectRatio: false,
                  responsive: true,
                }}
              />
            </div>
          </div>
        </div>
      </div>

        <div className="mb-3 row">

          <div className='col-md-6'>
            <div
              className="card h-100"
              style={{
                padding: "20px",
                boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
                width: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                {/* Platform Overview Text */}
                <h2 style={{ margin: 0, fontSize: '1.125rem', color: '#3c4043' }}>Popular Topics</h2>

                {/* Filter Dropdown */}
                <div
                  style={{
                    borderRadius: "5px",
                    display: "flex",
                    alignItems: "center",
                    gap: "5px",
                  }}
                >
                  <select
                    className='filter-select'
                    value={selectedOption}
                    onChange={handleOptionChange}
                  >
                    <option value="questionbank">Question Bank</option>
                    <option value="IQ Questions">Interview Questions</option>
                    <option value="FullForms">FullForms</option>
                    <option value="Exams">Exams</option>
                    <option value="Dictionary">Dictionary</option>
                    <option value="Quizzes">Quizzes</option>
                    <option value="Career News">Career News</option>
                    <option value="Career News Hindi">Career News Hindi</option>
                    <option value="Feeds">Feeds</option>
                    <option value="Gigs">Gigs</option>
                    <option value="Quotes">Quotes</option>
                    <option value="Lyrics">Lyrics</option>
                    <option value="Videos">Videos</option>
                    <option value="Articles">Articles</option>
                    <option value="Forum">Forum</option>
                    <option value="Tutorials">Tutorials</option>
                    <option value="Educators">Educators</option>
                    <option value="Corporates">Corporates</option>

                  </select>
                  <select
                    className='filter-select'
                    value={selectedOption}
                    onChange={handleOptionChange}
                  >
                    <option value="today">Today</option>
                    <option value="yesterday">Yesterday</option>
                    <option value="past-7-days">Past 7 Days</option>
                    <option value="past-30-days">Past 30 Days</option>
                    <option value="past-60-days">Past 60 Days</option>
                    <option value="past-90-days">Past 90 Days</option>
                    <option value="past-6-months">Past 6 Months</option>
                    <option value="past-12-months">Past 12 Months</option>
                    <option value="this-month">This Month</option>
                    <option value="past-month">Past Month</option>
                    <option value="custom">Custom Range</option>
                  </select>
                  {selectedOption === "custom" && (<>
                    <div>
                      <DateRangePicker format="MM/dd/yyyy" character=" – " onChange={(e) => { setCustomRange({ start: e[0].toLocaleDateString(), end: e[1].toLocaleDateString() }) }} />

                    </div>
                    <div style={{ display: "flex", height: 35.2 }}>
                      <button
                        className="btn btn-primary btn-sm me-1"
                        style={{
                          padding: "8px 12px",
                          borderRadius: "5px",
                          border: "none",
                          cursor: "pointer",
                        }}
                        onClick={handleCustomRangeSubmit}
                      >
                        Apply
                      </button>
                      {/* <button
                      className="btn btn-outline-secondary btn-sm"
                      style={{
                        padding: "8px 12px",
                        borderRadius: "5px",
                        cursor: "pointer",
                      }}
                      onClick={handleClearFilter}
                    >
                      Clear
                    </button> */}
                    </div>
                  </>)
                  }
                </div>
              </div>
              <hr style={{ borderColor: '#f0f0f0' }} />
              <div style={{ marginBottom: "20px" }} className='table-responsive'>

                <table className='table table-bordered rounded c--table'>
                  <thead>
                    <tr>
                      <th>Topic</th>
                      <th>Likes</th>
                      <th>Shares</th>
                      <th>Views</th>
                      <th>Comments</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Technology</td>
                      <td>10</td>
                      <td>12</td>
                      <td>11</td>
                      <td>120</td>
                    </tr>
                    <tr>
                      <td>Data Structure</td>
                      <td>199</td>
                      <td>23</td>
                      <td>32</td>
                      <td>11</td>
                    </tr>
                    <tr>
                      <td>Shopping</td>
                      <td>1</td>
                      <td>21</td>
                      <td>300</td>
                      <td>4k</td>
                    </tr>
                    <tr>
                      <td>Kubernet</td>
                      <td>0</td>
                      <td>113</td>
                      <td>58</td>
                      <td>68</td>
                    </tr>
                    <tr>
                      <td>Data Science</td>
                      <td>8</td>
                      <td>50</td>
                      <td>350</td>
                      <td>3k</td>
                    </tr>
                    <tr>
                      <td>Data Science</td>
                      <td>8</td>
                      <td>50</td>
                      <td>350</td>
                      <td>3k</td>
                    </tr>
                    <tr>
                      <td>DevOps</td>
                      <td>15</td>
                      <td>85</td>
                      <td>450</td>
                      <td>1.2k</td>
                    </tr>
                    <tr>
                      <td>Cybersecurity</td>
                      <td>12</td>
                      <td>140</td>
                      <td>500</td>
                      <td>3.8k</td>
                    </tr>
                    <tr>
                      <td>Software Development</td>
                      <td>9</td>
                      <td>80</td>
                      <td>350</td>
                      <td>2.2k</td>
                    </tr>
                  </tbody>
                </table>
                <a href="" className='theme-color'>Explore Trending Questions</a>
              </div>
            </div>
          </div>
          <div className='col-md-6'>
            <div
              className="card h-100"
              style={{
                padding: "20px",
                boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
                width: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                {/* Platform Overview Text */}
                <h2 style={{ margin: 0, fontSize: '1.125rem', color: '#3c4043' }}>Content Engagement</h2>

                {/* Filter Dropdown */}
                <div
                  style={{
                    borderRadius: "5px",
                    display: "flex",
                    alignItems: "center",
                    gap: "5px",
                  }}
                >

                  <select
                    className='filter-select'
                    value={selectedOption}
                    onChange={handleOptionChange}
                  >
                    <option value="today">Today</option>
                    <option value="yesterday">Yesterday</option>
                    <option value="past-7-days">Past 7 Days</option>
                    <option value="past-30-days">Past 30 Days</option>
                    <option value="past-60-days">Past 60 Days</option>
                    <option value="past-90-days">Past 90 Days</option>
                    <option value="past-6-months">Past 6 Months</option>
                    <option value="past-12-months">Past 12 Months</option>
                    <option value="this-month">This Month</option>
                    <option value="past-month">Past Month</option>
                    <option value="custom">Custom Range</option>
                  </select>
                  {selectedOption === "custom" && (<>
                    <div>
                      <DateRangePicker format="MM/dd/yyyy" character=" – " onChange={(e) => { setCustomRange({ start: e[0].toLocaleDateString(), end: e[1].toLocaleDateString() }) }} />

                    </div>
                    <div style={{ display: "flex", height: 35.2 }}>
                      <button
                        className="btn btn-primary btn-sm me-1"
                        style={{
                          padding: "8px 12px",
                          borderRadius: "5px",
                          border: "none",
                          cursor: "pointer",
                        }}
                        onClick={handleCustomRangeSubmit}
                      >
                        Apply
                      </button>
                      {/* <button
                      className="btn btn-outline-secondary btn-sm"
                      style={{
                        padding: "8px 12px",
                        borderRadius: "5px",
                        cursor: "pointer",
                      }}
                      onClick={handleClearFilter}
                    >
                      Clear
                    </button> */}
                    </div>
                  </>)
                  }
                </div>
              </div>
              <hr style={{ borderColor: '#f0f0f0' }} />
              <VerticalBarChart />
              <hr style={{ borderColor: '#f0f0f0' }} />
              <div className='d-flex items-center justify-between mb-3'>
                <label>Total Engagement</label>
                <strong className='theme-color'>1.5k</strong>

              </div>
              <div className='d-flex items-center justify-between mb-3'>
                <label>Engagement Rate</label>
                <strong className='theme-color'>50%</strong>
              </div>
              <div className='d-flex items-center justify-between'>
                <label>Most Engaged Module</label>
                <strong className='theme-color'>Careernews</strong>
              </div>
            </div>
          </div>
        </div>
        <div className="mb-3 row">
          <div className='col-md-4'>
            <div
              className="card h-100"
              style={{
                padding: "20px",
                boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
                width: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                {/* Platform Overview Text */}
                <h2 style={{ margin: 0, fontSize: '1.125rem', color: '#3c4043' }}>Engagement Rates</h2>

              </div>
              <hr style={{ borderColor: '#f0f0f0' }} />
              {/* Filter Dropdown */}
              <div
                style={{
                  borderRadius: "5px",
                  display: "flex",
                  alignItems: "center",
                  flexDirection: 'column',
                  gap: "5px",
                }}
                className='mb-4'
              >
                <select
                  className='filter-select w-100'
                  value={selectedOption}
                  onChange={handleOptionChange}
                >
                  <option value="questionbank">Question Bank</option>
                  <option value="IQ Questions">Interview Questions</option>
                  <option value="FullForms">FullForms</option>
                  <option value="Exams">Exams</option>
                  <option value="Dictionary">Dictionary</option>
                  <option value="Quizzes">Quizzes</option>
                  <option value="Career News">Career News</option>
                  <option value="Career News Hindi">Career News Hindi</option>
                  <option value="Feeds">Feeds</option>
                  <option value="Gigs">Gigs</option>
                  <option value="Quotes">Quotes</option>
                  <option value="Lyrics">Lyrics</option>
                  <option value="Videos">Videos</option>
                  <option value="Articles">Articles</option>
                  <option value="Forum">Forum</option>
                  <option value="Tutorials">Tutorials</option>
                  <option value="Educators">Educators</option>
                  <option value="Corporates">Corporates</option>

                </select>
                <select
                  className='filter-select w-100'
                  value={selectedOption}
                  onChange={handleOptionChange}
                >
                  <option value="today">Today</option>
                  <option value="yesterday">Yesterday</option>
                  <option value="past-7-days">Past 7 Days</option>
                  <option value="past-30-days">Past 30 Days</option>
                  <option value="past-60-days">Past 60 Days</option>
                  <option value="past-90-days">Past 90 Days</option>
                  <option value="past-6-months">Past 6 Months</option>
                  <option value="past-12-months">Past 12 Months</option>
                  <option value="this-month">This Month</option>
                  <option value="past-month">Past Month</option>
                  <option value="custom">Custom Range</option>
                </select>
                {selectedOption === "custom" && (<>
                  <div>
                    <DateRangePicker format="MM/dd/yyyy" character=" – " onChange={(e) => { setCustomRange({ start: e[0].toLocaleDateString(), end: e[1].toLocaleDateString() }) }} className='w-100' />

                  </div>
                  <div style={{ display: "flex", height: 35.2 }}>
                    <button
                      className="btn btn-primary btn-sm me-1"
                      style={{
                        padding: "8px 12px",
                        borderRadius: "5px",
                        border: "none",
                        cursor: "pointer",
                      }}
                      onClick={handleCustomRangeSubmit}
                    >
                      Apply
                    </button>
                    {/* <button
                      className="btn btn-outline-secondary btn-sm"
                      style={{
                        padding: "8px 12px",
                        borderRadius: "5px",
                        cursor: "pointer",
                      }}
                      onClick={handleClearFilter}
                    >
                      Clear
                    </button> */}
                  </div>
                </>)
                }
              </div>
              <EngagementDonutChart />
              <hr style={{ borderColor: '#f0f0f0' }} />
              <a href="" className='theme-color'>Engage More With Questionbank </a>
            </div>
          </div>
          <div className='col-md-8'>
            <div
              className="card h-100"
              style={{
                padding: "20px",
                boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
                width: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                {/* Platform Overview Text */}
                <h2 style={{ margin: 0, fontSize: '1.125rem', color: '#3c4043' }}>My Contents Stats</h2>

                {/* Filter Dropdown */}
                <div
                  style={{
                    borderRadius: "5px",
                    display: "flex",
                    alignItems: "center",
                    gap: "5px",
                  }}
                >
                  <select
                    className='filter-select'
                    value={selectedOption}
                    onChange={handleOptionChange}
                  >
                    <option value="questionbank">Question Bank</option>
                    <option value="IQ Questions">Interview Questions</option>
                    <option value="FullForms">FullForms</option>
                    <option value="Exams">Exams</option>
                    <option value="Dictionary">Dictionary</option>
                    <option value="Quizzes">Quizzes</option>
                    <option value="Career News">Career News</option>
                    <option value="Career News Hindi">Career News Hindi</option>
                    <option value="Feeds">Feeds</option>
                    <option value="Gigs">Gigs</option>
                    <option value="Quotes">Quotes</option>
                    <option value="Lyrics">Lyrics</option>
                    <option value="Videos">Videos</option>
                    <option value="Articles">Articles</option>
                    <option value="Forum">Forum</option>
                    <option value="Tutorials">Tutorials</option>
                    <option value="Educators">Educators</option>
                    <option value="Corporates">Corporates</option>

                  </select>
                  <select
                    className='filter-select'
                    value={selectedOption}
                    onChange={handleOptionChange}
                  >
                    <option value="today">Today</option>
                    <option value="yesterday">Yesterday</option>
                    <option value="past-7-days">Past 7 Days</option>
                    <option value="past-30-days">Past 30 Days</option>
                    <option value="past-60-days">Past 60 Days</option>
                    <option value="past-90-days">Past 90 Days</option>
                    <option value="past-6-months">Past 6 Months</option>
                    <option value="past-12-months">Past 12 Months</option>
                    <option value="this-month">This Month</option>
                    <option value="past-month">Past Month</option>
                    <option value="custom">Custom Range</option>
                  </select>
                  {selectedOption === "custom" && (<>
                    <div>
                      <DateRangePicker format="MM/dd/yyyy" character=" – " onChange={(e) => { setCustomRange({ start: e[0].toLocaleDateString(), end: e[1].toLocaleDateString() }) }} />

                    </div>
                    <div style={{ display: "flex", height: 35.2 }}>
                      <button
                        className="btn btn-primary btn-sm me-1"
                        style={{
                          padding: "8px 12px",
                          borderRadius: "5px",
                          border: "none",
                          cursor: "pointer",
                        }}
                        onClick={handleCustomRangeSubmit}
                      >
                        Apply
                      </button>
                      {/* <button
                                        className="btn btn-outline-secondary btn-sm"
                                        style={{
                                          padding: "8px 12px",
                                          borderRadius: "5px",
                                          cursor: "pointer",
                                        }}
                                        onClick={handleClearFilter}
                                      >
                                        Clear
                                      </button> */}
                    </div>
                  </>)
                  }
                </div>
              </div>
              <hr style={{ borderColor: '#f0f0f0' }} />
              <div style={{ marginBottom: "20px" }} className='table-responsive'>

                <table className='table table-bordered rounded c--table'>
                  <thead>
                    <tr>
                      <th>Content</th>
                      <th>Engagements</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>How to Boost Your Career with Networking</td>
                      <td>1,200</td>
                      <td><a href="#view1" className='theme-hover-gray'><i class="fa fa-eye" aria-hidden="true"></i></a></td>
                    </tr>
                    <tr>
                      <td>The Future of Artificial Intelligence</td>
                      <td>950</td>
                      <td><a href="#view2" className='theme-hover-gray'><i class="fa fa-eye" aria-hidden="true"></i></a></td>
                    </tr>
                    <tr>
                      <td>Top 10 Programming Languages in 2024</td>
                      <td>1,500</td>
                      <td><a href="#view3" className='theme-hover-gray'><i class="fa fa-eye" aria-hidden="true"></i></a></td>
                    </tr>
                    <tr>
                      <td>Why Cybersecurity is a Growing Concern</td>
                      <td>1,050</td>
                      <td><a href="#view4" className='theme-hover-gray'><i class="fa fa-eye" aria-hidden="true"></i></a></td>
                    </tr>
                    <tr>
                      <td>Mastering Data Science: A Beginner's Guide</td>
                      <td>1,100</td>
                      <td><a href="#view5" className='theme-hover-gray'><i class="fa fa-eye" aria-hidden="true"></i></a></td>
                    </tr>
                    <tr>
                      <td>The Impact of Remote Work on Employee Productivity</td>
                      <td>1,300</td>
                      <td><a href="#view6" className='theme-hover-gray'><i class="fa fa-eye" aria-hidden="true"></i></a></td>
                    </tr>
                    <tr>
                      <td>Top Digital Marketing Strategies for 2024</td>
                      <td>1,400</td>
                      <td><a href="#view7" className='theme-hover-gray'><i class="fa fa-eye" aria-hidden="true"></i></a></td>
                    </tr>
                    <tr>
                      <td>Building a Personal Brand Online</td>
                      <td>1,200</td>
                      <td><a href="#view8" className='theme-hover-gray'><i class="fa fa-eye" aria-hidden="true"></i></a></td>
                    </tr>
                    <tr>
                      <td>How to Start Your Own Online Business</td>
                      <td>1,600</td>
                      <td><a href="#view9" className='theme-hover-gray'><i class="fa fa-eye" aria-hidden="true"></i></a></td>
                    </tr>
                    {/* <tr>
                                      <td>Building a Personal Brand Online</td>
                                      <td>1,200</td>
                                      <td><a href="#view8" className='theme-hover-gray'><i class="fa fa-eye" aria-hidden="true"></i></a></td>
                                    </tr>         */}
                  </tbody>
                </table>

                <hr style={{ borderColor: '#f0f0f0' }} />
                <a href="" className='theme-color'>Post More Courses</a>
              </div>
            </div>
          </div>
        </div>
        <div className="mb-3 row">

          <div className='col-md-8'>
            <div
              className="card h-100"
              style={{
                padding: "20px",
                boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
                width: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                {/* Platform Overview Text */}
                <h2 style={{ margin: 0, fontSize: '1.125rem', color: '#3c4043' }}>Recent Activities</h2>

                {/* Filter Dropdown */}
                <div
                  style={{
                    borderRadius: "5px",
                    display: "flex",
                    alignItems: "center",
                    gap: "5px",
                  }}
                >
                  <select
                    className='filter-select'
                    value={selectedOption}
                    onChange={handleOptionChange}
                  >
                    <option value="questionbank">Question Bank</option>
                    <option value="IQ Questions">Interview Questions</option>
                    <option value="FullForms">FullForms</option>
                    <option value="Exams">Exams</option>
                    <option value="Dictionary">Dictionary</option>
                    <option value="Quizzes">Quizzes</option>
                    <option value="Career News">Career News</option>
                    <option value="Career News Hindi">Career News Hindi</option>
                    <option value="Feeds">Feeds</option>
                    <option value="Gigs">Gigs</option>
                    <option value="Quotes">Quotes</option>
                    <option value="Lyrics">Lyrics</option>
                    <option value="Videos">Videos</option>
                    <option value="Articles">Articles</option>
                    <option value="Forum">Forum</option>
                    <option value="Tutorials">Tutorials</option>
                    <option value="Educators">Educators</option>
                    <option value="Corporates">Corporates</option>

                  </select>
                  <select
                    className='filter-select'
                    value={selectedOption}
                    onChange={handleOptionChange}
                  >
                    <option value="today">Today</option>
                    <option value="yesterday">Yesterday</option>
                    <option value="past-7-days">Past 7 Days</option>
                    <option value="past-30-days">Past 30 Days</option>
                    <option value="past-60-days">Past 60 Days</option>
                    <option value="past-90-days">Past 90 Days</option>
                    <option value="past-6-months">Past 6 Months</option>
                    <option value="past-12-months">Past 12 Months</option>
                    <option value="this-month">This Month</option>
                    <option value="past-month">Past Month</option>
                    <option value="custom">Custom Range</option>
                  </select>
                  {selectedOption === "custom" && (<>
                    <div>
                      <DateRangePicker format="MM/dd/yyyy" character=" – " onChange={(e) => { setCustomRange({ start: e[0].toLocaleDateString(), end: e[1].toLocaleDateString() }) }} />

                    </div>
                    <div style={{ display: "flex", height: 35.2 }}>
                      <button
                        className="btn btn-primary btn-sm me-1"
                        style={{
                          padding: "8px 12px",
                          borderRadius: "5px",
                          border: "none",
                          cursor: "pointer",
                        }}
                        onClick={handleCustomRangeSubmit}
                      >
                        Apply
                      </button>
                      {/* <button
                                        className="btn btn-outline-secondary btn-sm"
                                        style={{
                                          padding: "8px 12px",
                                          borderRadius: "5px",
                                          cursor: "pointer",
                                        }}
                                        onClick={handleClearFilter}
                                      >
                                        Clear
                                      </button> */}
                    </div>
                  </>)
                  }
                </div>
              </div>
              <hr style={{ borderColor: '#f0f0f0' }} />
              <div style={{ marginBottom: "20px" }} className='table-responsive'>

                <table className='table table-bordered rounded c--table'>
                  <thead>
                    <tr>
                      <th>Module</th>
                      <th>Activity Page</th>
                      <th>Action Type</th>
                      <th>Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Questions</td>
                      <td><a href="/activity/questions">General Awareness in Current Affairs 2021 Mock Test Series 2</a></td>
                      <td>Like</td>
                      <td>2024-12-01</td>
                    </tr>
                    <tr>
                      <td>IQ Questions</td>
                      <td><a href="/activity/iq-questions">IQ Test for GATE Exam Preparation</a></td>
                      <td>Comment</td>
                      <td>2024-12-01</td>
                    </tr>
                    <tr>
                      <td>Exams</td>
                      <td><a href="/activity/exams">UPSC Civil Services Exam 2024 - Syllabus</a></td>
                      <td>Share</td>
                      <td>2024-12-02</td>
                    </tr>
                    <tr>
                      <td>Career News</td>
                      <td><a href="/activity/career-news">Latest Job Opportunities for Software Engineers</a></td>
                      <td>Like</td>
                      <td>2024-12-02</td>
                    </tr>
                    <tr>
                      <td>Forum</td>
                      <td><a href="/activity/forum">Discussion on Data Science Job Trends</a></td>
                      <td>View</td>
                      <td>2024-12-03</td>
                    </tr>
                    <tr>
                      <td>Blog</td>
                      <td><a href="/activity/blog">Exploring AI in Healthcare</a></td>
                      <td>Read</td>
                      <td>2024-12-02</td>
                    </tr>
                    <tr>
                      <td>Quizzes</td>
                      <td><a href="/activity/quiz/javascript-basics">JavaScript Basics Quiz</a></td>
                      <td>Take Quiz</td>
                      <td>2024-12-01</td>
                    </tr>
                    <tr>
                      <td>Videos</td>
                      <td><a href="/activity/video/ai-for-beginners">AI for Beginners – Introductory Video</a></td>
                      <td>Watch</td>
                      <td>2024-12-01</td>
                    </tr>
                    <tr>
                      <td>Forum</td>
                      <td><a href="/activity/forum">The Future of Remote Work</a></td>
                      <td>Join Discussion</td>
                      <td>2024-12-03</td>
                    </tr>
                    <tr>
                      <td>Articles</td>
                      <td><a href="/activity/article/digital-transformation">Digital Transformation in Business</a></td>
                      <td>Read</td>
                      <td>2024-12-02</td>
                    </tr>
                  </tbody>
                </table>
                <hr style={{ borderColor: '#f0f0f0' }} />
                <a href="" className='theme-color'>More Activities</a>
              </div>
            </div>
          </div>
          <div className='col-md-4'>
            <div
              className="card mb-3"
              style={{
                padding: "20px",
                boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
                width: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                {/* Platform Overview Text */}
                <h2 style={{ margin: 0, fontSize: '1.125rem', color: '#3c4043' }}>Achievements</h2>

              </div>
              <ul class="timeline">
                <li>Completed 50 Quizzes</li>
                <li>Highest Engagement this Month</li>
              </ul>
            </div>
            <div
              className="card"
              style={{
                padding: "20px",
                boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
                width: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                {/* Platform Overview Text */}
                <h2 style={{ margin: 0, fontSize: '1.125rem', color: '#3c4043' }}>Call To Actions</h2>

              </div>
              <ul class="timeline mb-0">
                <li class="cta-item d-flex flex-column align-items-start w-100">You haven't updated your profile this month—<a href="/update-profile" class="cta-link mb-2">Update Now</a></li>
                <li class="cta-item d-flex flex-column align-items-start w-100">Your article draft ‘Future of AI’ is incomplete—<a href="/edit-article/future-of-ai" class="cta-link mb-2">Finish Writing</a></li>
                <li class="cta-item d-flex flex-column align-items-start w-100">You started a quiz on ‘JavaScript Basics’—<a href="/continue-quiz/javascript-basics" class="cta-link mb-2">Continue Now</a></li>

              </ul>
              <hr style={{ borderColor: '#f0f0f0' }} />
              <a href="" className='theme-color'>View More</a>
            </div>
          </div>
        </div>
        <div className="mb-3 row">

          <div className='col-md-4'>
            <div
              className="card h-100"
              style={{
                padding: "20px",
                boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
                width: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                {/* Platform Overview Text */}
                <h2 style={{ margin: 0, fontSize: '1.125rem', color: '#3c4043' }}>Recent Transactions</h2>

                {/* Filter Dropdown */}
                
              </div>
              
              <hr style={{ borderColor: '#f0f0f0' }} />
              <div
                  style={{
                    borderRadius: "5px",
                    display: "flex",
                    alignItems: "center",
                    gap: "5px",
                  }}
                >
                  <select
                    className='filter-select'
                    value={selectedOption}
                    onChange={handleOptionChange}
                  >
                    <option value="all">All</option>
                    <option value="questionbank">Question Bank</option>
                    <option value="IQ Questions">Interview Questions</option>
                    <option value="FullForms">FullForms</option>
                    <option value="Exams">Exams</option>
                    <option value="Dictionary">Dictionary</option>
                    <option value="Quizzes">Quizzes</option>
                    <option value="Career News">Career News</option>
                    <option value="Career News Hindi">Career News Hindi</option>
                    <option value="Feeds">Feeds</option>
                    <option value="Gigs">Gigs</option>
                    <option value="Quotes">Quotes</option>
                    <option value="Lyrics">Lyrics</option>
                    <option value="Videos">Videos</option>
                    <option value="Articles">Articles</option>
                    <option value="Forum">Forum</option>
                    <option value="Tutorials">Tutorials</option>
                    <option value="Educators">Educators</option>
                    <option value="Corporates">Corporates</option>

                  </select>
                  <select
                    className='filter-select'
                    value={selectedOption}
                    onChange={handleOptionChange}
                  >
                    <option value="today">Today</option>
                    <option value="yesterday">Yesterday</option>
                    <option value="past-7-days">Past 7 Days</option>
                    <option value="past-30-days">Past 30 Days</option>
                    <option value="past-60-days">Past 60 Days</option>
                    <option value="past-90-days">Past 90 Days</option>
                    <option value="past-6-months">Past 6 Months</option>
                    <option value="past-12-months">Past 12 Months</option>
                    <option value="this-month">This Month</option>
                    <option value="past-month">Past Month</option>
                    <option value="custom">Custom Range</option>
                  </select>
                  {selectedOption === "custom" && (<>
                    <div>
                      <DateRangePicker format="MM/dd/yyyy" character=" – " onChange={(e) => { setCustomRange({ start: e[0].toLocaleDateString(), end: e[1].toLocaleDateString() }) }} />

                    </div>
                    <div style={{ display: "flex", height: 35.2 }}>
                      <button
                        className="btn btn-primary btn-sm me-1"
                        style={{
                          padding: "8px 12px",
                          borderRadius: "5px",
                          border: "none",
                          cursor: "pointer",
                        }}
                        onClick={handleCustomRangeSubmit}
                      >
                        Apply
                      </button>
                      {/* <button
                      className="btn btn-outline-secondary btn-sm"
                      style={{
                        padding: "8px 12px",
                        borderRadius: "5px",
                        cursor: "pointer",
                      }}
                      onClick={handleClearFilter}
                    >
                      Clear
                    </button> */}
                    </div>
                  </>)
                  }
                </div>
              <div style={{ marginBottom: "20px" }} className='table-responsive'>
              {transactions.map((transaction, index) => (
                  <div className='transaction-list flex justify-between items-center  my-3'>
                  <div className='transaction-left flex items-center'>
                      <div className='thumb-trans me-2'>
                         <i className='fa fa-credit-card'></i>
                      </div>
                      <div className='flex flex-col'>
                          <p className='m-0'>{transaction?.description}</p>
                          <small className='color-gray'>{transaction.date}</small>
                      </div>
                  </div>
                  <div className='transaction-right'>
                     <span className={transaction?.type=='debit'?'text-green-500':'text-red-500'}>{transaction?.type=='debit'?'+'+transaction.amount:'-'+transaction.amount}</span>
                  </div>
              </div>
              ))
}

<hr style={{ borderColor: '#f0f0f0' }} />
                <a href="" className='theme-color'>More Transactions</a>
              </div>
            </div>
          </div>
          <div className='col-md-8'>
            <div
              className="card h-100"
              style={{
                padding: "20px",
                boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
                width: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                {/* Platform Overview Text */}
                <h2 style={{ margin: 0, fontSize: '1.125rem', color: '#3c4043' }}>Credits & Transactions Stats</h2>

                {/* Filter Dropdown */}
                
              </div>
              <hr style={{ borderColor: '#f0f0f0' }} />

              <div
                  style={{
                    borderRadius: "5px",
                    display: "flex",
                    alignItems: "center",
                    gap: "5px",
                  }}
                >

                  {/* <select
                    className='filter-select'
                    value={selectedOption}
                    onChange={handleOptionChange}
                  > */}
                  <select className='filter-select'  value={selectedOptions}
                    onChange={(e)=>setSelectedOptions(e.target.value)}>
                        <option value="cevscs">Credits Earned Vs Credits Spent</option>
                        <option value="cpvsw">Credits Purchased Vs Withdrawals</option>
        
                  </select>
                  <select
                    className='filter-select'
                    value={selectedOption}
                    onChange={handleOptionChange}
                  >
                    <option value="questionbank">Question Bank</option>
                    <option value="IQ Questions">Interview Questions</option>
                    <option value="FullForms">FullForms</option>
                    <option value="Exams">Exams</option>
                    <option value="Dictionary">Dictionary</option>
                    <option value="Quizzes">Quizzes</option>
                    <option value="Career News">Career News</option>
                    <option value="Career News Hindi">Career News Hindi</option>
                    <option value="Feeds">Feeds</option>
                    <option value="Gigs">Gigs</option>
                    <option value="Quotes">Quotes</option>
                    <option value="Lyrics">Lyrics</option>
                    <option value="Videos">Videos</option>
                    <option value="Articles">Articles</option>
                    <option value="Forum">Forum</option>
                    <option value="Tutorials">Tutorials</option>
                    <option value="Educators">Educators</option>
                    <option value="Corporates">Corporates</option>

                  </select>
                    <select className='filter-select' value={selectedOption}
                    onChange={handleOptionChange}>
                        <option value="today">Today</option>
                        <option value="yesterday">Yesterday</option>
                        <option value="this_month">This Month</option>
                        <option value="last_month">Last Month</option>
                        <option value="last_3_months">Last 3 Months</option>
                        <option value="last_6_months">Last 6 Months</option>
                        <option value="this_year">This Year</option>
                        <option value="previous_year">Previous Year</option>
        
                  </select>
                  {selectedOption === "custom" && (<>
                    <div>
                      <DateRangePicker format="MM/dd/yyyy" character=" – " onChange={(e) => { setCustomRange({ start: e[0].toLocaleDateString(), end: e[1].toLocaleDateString() }) }} />

                    </div>
                    <div style={{ display: "flex", height: 35.2 }}>
                      <button
                        className="btn btn-primary btn-sm me-1"
                        style={{
                          padding: "8px 12px",
                          borderRadius: "5px",
                          border: "none",
                          cursor: "pointer",
                        }}
                        onClick={handleCustomRangeSubmit}
                      >
                        Apply
                      </button>
                      {/* <button
                      className="btn btn-outline-secondary btn-sm"
                      style={{
                        padding: "8px 12px",
                        borderRadius: "5px",
                        cursor: "pointer",
                      }}
                      onClick={handleClearFilter}
                    >
                      Clear
                    </button> */}
                    </div>
                  </>)
                  }
                </div>
                <hr style={{ borderColor: '#f0f0f0' }} />
                {
                  selectedOptions && <FinanceReportChart selectedOptions={selectedOptions} />
                }
             
              <hr style={{ borderColor: '#f0f0f0' }} />
              {
                selectedOptions=='cevsce'?<> <div className='d-flex items-center justify-between mb-3'>
                <div className='flex flex-col'>
                    <p className='m-0'>Earned Credits</p>
                    <small className='color-gray'><strong className='text-green-500 me-1'><i className='fa fa-long-arrow-up me-1'></i>7%</strong>from last week</small>
                </div>
                  <strong className='text-green-500'>1.5K</strong>
  
                </div>
                <div className='d-flex items-center justify-between mb-3'>
                <div className='flex flex-col'>
                    <p className='m-0'>Spend Credits</p>
                    <small className='color-gray'><strong className='text-red-500 me-1'><i className='fa fa-long-arrow-down me-1'></i>1%</strong>from last week</small>
                </div>
                  <strong className='text-red-500'>-2,000</strong>
                </div></>:<>
                <div className='d-flex items-center justify-between mb-3'>
                  <div className='flex flex-col'>
                      <p className='m-0'>Purchased Credits</p>
                      <small className='color-gray'><strong className='text-green-500 me-1'><i className='fa fa-long-arrow-up me-1'></i>0.75%</strong>from last week</small>
                  </div>
                    <strong className='theme-color'>INR 1,000 (500 Credits)</strong>
                  </div>
                  <div className='d-flex items-center justify-between mb-3'>
                  <div className='flex flex-col'>
                      <p className='m-0'>Withdrawals</p>
                      <small className='color-gray'><strong className='text-green-500 me-1'><i className='fa fa-long-arrow-up me-1'></i>20%</strong>from last week</small>
                  </div>
                    <strong className='theme-color'>50K</strong>
                  </div>
                </>
              }
            </div>
          </div>
        </div>
        
      </>}

    </>
  )
}

export default Dashboard
