import React, { useEffect, useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, Title, Tooltip, Legend, ArcElement, CategoryScale, LinearScale } from 'chart.js';
import { NavLink, useParams } from 'react-router-dom';
import { ERROR_SOMETHING_WENT_WRONG } from '../../../../utils/utils';
import { Loadingicon } from '../../../../AppIcons';
import { getExamResult } from '../../../../api/exam';
import { useAuth } from '../../../../Context/AuthContext';
import { toast } from 'react-toastify';

// Register the necessary Chart.js components
ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale, LinearScale);

const ExamResult = () => {
  const params = useParams();
  const { globalToken } = useAuth();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [totalRecordFound, setTotalRecordFound] = useState([]);
  const [examData, setExamData] = useState(null);

  useEffect(() => {
    handleExamsResult();
  }, []);

  const handleExamsResult = async () => {
    const examData = await getExamResult({ token: globalToken , id : params?.examID });
    if (examData?.success) {
      setExamData(examData?.examResults);
      const correctAnswers = examData?.statistics?.correct_answers;
      const incorrectAnswers = examData?.statistics?.wrong_answers;
      const notAttempted = examData?.statistics?.not_answered;
      setTotalRecordFound(examData?.examResults?.length)
      setData({
        ...examData,
        notAttempted,
        correctAnswers,
        incorrectAnswers,
      });
    } else {
      toast.error(ERROR_SOMETHING_WENT_WRONG)
  }
  setLoading(false);
  };
    
  // Chart data
  const doughnutData = {
    labels: ["Not Attempted Questions", "Correct Answers", "Incorrect Answers"],
    datasets: [
      {
        data: [
          data?.notAttempted,
          data?.correctAnswers,
          data?.incorrectAnswers,
        ],
        backgroundColor: ["#C4C4C4", "#28a745", "#FF5C5C"],
        hoverBackgroundColor: ["#C4C4C4", "#28a745", "#FF5C5C"],
      },
    ],
  };

  // Chart options
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            let label = context.label || '';
            let value = context.raw;
            label += `: ${value}`;
            return label;
          },
        },
      },
    },
  };

  return (
    <>
    <div className="d-flex align-items-center justify-content-between">
        <div className="d-flex justify-content-between mb-3">
          <nav
            aria-label="breadcrumb"
            className="pt-0 px-0 pb-0 breadcrumb-card"
          >
            <ol
              className="breadcrumb px-0 pt-0 breadcrumb-main-mobilea"
              style={{ display: "flex", flexWrap: "nowrap" }}
            >
              <li className={`breadcrumb-item capitalize rest-bread`}>
                <NavLink
                  to="/"
                  style={{ fontSize: 14, textDecoration: "none" }}
                >
                  Home
                </NavLink>
              </li>
              <li className={`breadcrumb-item capitalize rest-bread`}>
                <NavLink
                  to="/exams"
                  style={{ fontSize: 14, textDecoration: "none" }}
                >
                  Exams
                </NavLink>
              </li>
              <li
                className={`breadcrumb-item capitalize active clamping ellipsis`}
              >
                Result
              </li>
            </ol>
          </nav>
        </div>        
      </div>
    {loading ? (
        <div className=" py-0 px-0 grid grid-cols-1 group-card card-body gap-4s">
          <div className="w-full flex items-center justify-center mt-20 mb-20 text-themecolor min-h-[400px]">
            <div className="h-10 w-10">
              <Loadingicon />
            </div>
          </div>
        </div>
      ) : (
        <>
        <div className="mt-2">
          <h3 style={{ fontWeight: 'bold', color: '#2c3e50', textAlign: 'center' }}>{data?.exam?.title}</h3>
          <h5 style={{ color: '#34495e', margin: '10px 0', textAlign: 'center' }}>
            Total <span style={{ fontWeight: 'bold', color: '#007bff' }}>{totalRecordFound}</span> Test Attempted
          </h5>
          <hr style={{ border: '1px solid #ddd', width: '80%', margin: '20px auto' }} />
      
          {/* Flexbox Container for Cards and Doughnut Chart */}
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'flex-start',
              gap: '20px',
              flexWrap: 'wrap',
              width: '100%',
              margin: '0 auto',
              padding: '10px',
            }}
          >
            {/* Left Section: Exam Cards */}
            <div style={{ flex: 1, maxWidth: '45%' }}>
              <ul style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 0, margin: 0 }}>
                {examData?.map((exam, index) =>{ 
                const getOrdinalSuffix = (num) => {
                  const suffixes = ["th", "st", "nd", "rd"];
                  const value = num % 100;
                  return suffixes[(value - 20) % 10] || suffixes[value] || suffixes[0];
                };
                const attemptNumber = index + 1;
                return (
                  <div className="col-md-12 " key={`${index}`}>
                    <div
                      className="card mb-3"
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        padding: '15px',
                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                        borderRadius: '8px',
                        width: '100%',
                        border: '1px solid #ddd',
                      }}
                    >
                      <div style={{ flex: 1 }}>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            padding: '15px',
                            backgroundColor: '#f9f9f9',
                            borderRadius: '8px',
                            fontFamily: "'Roboto', sans-serif",
                          }}
                        >
                          {/* Column 1: Marks Details */}
                          <div style={{ flex: 1, marginRight: '20px' }}>
                            <p style={{ margin: '10px 0', fontSize: '1rem', color: '#333', fontWeight: 'bold' }}>
                              Total Marks:
                              <span style={{ marginLeft: '8px', color: '#007BFF' }}>{exam.total_marks}</span>
                            </p>
                            <p style={{ margin: '10px 0', fontSize: '1rem', color: '#333', fontWeight: 'bold' }}>
                              Marks Obtained:
                              <span style={{ marginLeft: '8px', color: '#28A745' }}>{exam.marks_obtained}</span>
                            </p>
                          </div>
      
                          {/* Column 2: Performance Details */}
                          <div style={{ flex: 1, textAlign: 'left' }}>
                            <p style={{ margin: '10px 0', fontSize: '1rem', color: '#333', fontWeight: 'bold' }}>
                              Percentage:
                              <span style={{ marginLeft: '8px', color: '#FFC107' }}>{exam.percentage}%</span>
                            </p>
                            <p
                              style={{
                                margin: '10px 0',
                                fontSize: '1rem',
                                color: exam.exam_status === 'Pass' ? '#28A745' : '#DC3545',
                                fontWeight: 'bold',
                              }}
                            >
                              Exam Status:
                              <span style={{ marginLeft: '8px' }}>{exam.exam_status}</span>
                            </p>
                            
                          </div>
                          
                        </div>
                        <p
                  style={{
                    margin: '10px 0',
                    fontSize: '1rem',
                    color: '#555',
                    fontWeight: 'normal',
                    fontStyle: 'italic',
                  }}
                >
                  {attemptNumber}{getOrdinalSuffix(index + 1)} Attempt On:
                  <span style={{ marginLeft: '8px', color: '#007BFF' }}>
                    {new Date(exam.updated_at).toLocaleDateString()}
                  </span>
                </p>
                      </div>
                    </div>
                  </div>
                )})}
              </ul>
            </div>
      
            {/* Right Section: Doughnut Chart */}
            <div
              className="card doughnut-chart-container"
              style={{
                flex: 1,
                maxWidth: '50%',
                padding: '20px',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                borderRadius: '8px',
                backgroundColor: '#ffffff',
                height: 'fit-content',
              }}
            >
              <h5 style={{ textAlign: 'center', marginBottom: '20px', fontWeight: 'bold', color: '#2c3e50' }}>
                Performance Overview
              </h5>
              <Doughnut data={doughnutData} options={options} />
            </div>
          </div>
        </div>
      </>
      
    
    )}
    </>
  );
};

export default ExamResult;
