import React, { useRef, useState } from "react";
// import Image from "next/image";
// import { constants } from "../constant";
import Favorite from "@material-ui/icons/Favorite";
import FavoriteBorder from "@material-ui/icons/FavoriteBorder";
import { FeedsComments } from "../../../components/common";
import styles from "../../../assets/css/styles/Home.module.css";
import {
  CDN_BASE_URL,
  formatTime,
  nFormatter,
  slugify,
} from "../../../utils/utils";
import Share from "../../../components/common/share";
import MoreOption from "../../../components/common/moreoption";
// import { useRouter } from "next/router";
import { Link } from "@material-ui/core";
import { Loadingicon } from "../../../AppIcons";
import FeedVideo from "../video";
export default function FeedCard({
  feed,
  likeUnlike,
  apiToken,
  user,
  _allFeed,
  setModalOpen,
  modalOpen,
  goToFeedDetail,
  feedRefresh,
  hideReadMore,
  _favoriteFeed,
  feedRefreshMain,
  myFav,
  favBtnLoader,
  emailVerifiedStatus=null
  
}) {
  let likeBtn = useRef();
  // const router = useRouter();
  // console.log(feed)
  const [showHideShare, setShowHideShare] = useState(false);
  const [showHideMoreOption, setShowHideMoreOption] = useState(false);
  const [showHideComments, setShowHideComments] = useState(true);
  const iconShareRef = useRef(null);
  const shareRef = useRef(null);

  const instantLikeUnlike = () => {
    if(!emailVerifiedStatus)
    likeBtn?.current?.classList.toggle("liked");
  };
  let shareContent = {
    url: "https://feeds.tuteehub.com",
    quote: `${feed?.feed?.feed_text || feed?.feed_text}`,
    hashing: `#tuteehub`,
    title: `${feed?.feed?.feed_text || feed?.feed_text}`,
  };
  // console.log(feed)

  const moveToDetail = (feed) => {
    // router.push(
    //   {
    //     pathname: `/feeds/${slugify(feed?.feed?.feed_text || feed?.feed_text)}---${feed?.feed?.id || feed?.id}`,
    //     query: {
    //       popularUsers: JSON.stringify(popularUsers),
    //       feed: "[" + JSON.stringify(feed) + "]",
    //     },
    //   },
    //   `/feeds/${slugify(feed?.feed?.feed_text || feed?.feed_text)}---${feed?.feed?.id || feed?.id}`
    // );
  };

  return feed ? (
    <div className={("poscard", styles.postcard)}>
      <div
        className={
          (styles.cardHead,
          "d-flex justify-content-between align-items-top px-3 pt-3")
        }
      >
        <div className={styles.postImage}>
          <div className={(styles.postedbycontainer, "d-flex col-12 px-0")}>
            <div>
              <img
                alt="postedByImage"
                src={
                  feed?.feed?.orginator_user_detail?.profile_details?.profileImage || feed?.orginator_user_detail?.profile_details?.profileImage
                    ? `${CDN_BASE_URL}${feed?.feed?.orginator_user_detail?.profile_details?.profileImage || feed?.orginator_user_detail?.profile_details?.profileImage}`
                    : require(`../../../assets/images/profile_placeholder.png`)
                }
                width={40}
                height={40}
                className={styles.imgcircle}
              />
            </div>
            <div className="ms-3">
              <div className={styles.postedbyname}>
                {feed?.feed?.user_detail?.name || feed?.user_detail?.name}
              </div>
              <p className={styles.postedTime}>{formatTime(feed)}</p>
            </div>
          </div>
        </div>
        <div className="rightMoreContainer position-relative">
          {

            !myFav?
            feed?.is_favourite_by_user?.length?<>
              {favBtnLoader ? (
                    <div className="h-5 w-5 theme-color">
                        <Loadingicon />
                    </div>
                ) : <>{
                  <Favorite width={30} role="button" onClick={()=>_favoriteFeed(feed, '0')} height={30} style={{color:'#ff7200'}}/>
                }</>}
            </>            
            :<>
            {favBtnLoader ? (
                  <div className="h-5 w-5 theme-color">
                      <Loadingicon />
                  </div>
              ) : <>{
                <FavoriteBorder width={30} height={30} role="button" style={{color:'#585858'}} onClick={()=>_favoriteFeed(feed, '1')}/>
              }</>}
          </>:null
          }
          
          <svg
            fill="currentColor"
            viewBox="0 0 20 20"
            width="1em"
            height="1em"
            className={styles.moreBtn}
            onClick={() => setShowHideMoreOption(!showHideMoreOption)}
          >
            <g fillRule="evenodd" transform="translate(-446 -350)">
              <path d="M458 360a2 2 0 1 1-4 0 2 2 0 0 1 4 0m6 0a2 2 0 1 1-4 0 2 2 0 0 1 4 0m-12 0a2 2 0 1 1-4 0 2 2 0 0 1 4 0"></path>
            </g>
          </svg>
          {showHideMoreOption ? (
            <MoreOption
              setModalOpen={setModalOpen}
              modalOpen={modalOpen}
              _allFeed={_allFeed}
              item={feed}
              apiToken={apiToken}
              user={user}
              setShowHideMoreOption={setShowHideMoreOption}
            />
          ) : null}
        </div>
      </div>
      <div className={(styles.postDescription, "px-3")}>
        {/* href={`/feeds/${slugify(feed?.feed_text)}---${feed?.id}`} moveToDetail(feed)*/}
        {feed?.feed?.feed_text || feed?.feed_text}
        {hideReadMore ? (
          <span
            role={"button"}
            onClick={() => goToFeedDetail(feed?.feed?.id || feed?.id, feed?.feed?.feed_text || feed?.feed_text)}
            style={{ color: "blue" }}
          >
            ...Read More
          </span>
        ) : null}
      </div> 
      {feed?.feed?.feed_media?.length || feed?.feed_media?.length ? (
         <>
         {
           (feed?.feed_media?.[0]?.media_type=='image' || feed?.feed?.feed_media?.[0]?.media_type=='image')?
        <div className={`${styles.postedImage} mt-3 img-cont`}>
          <img
            placeholder="blur"
            // blurDataURL={feed?.feed?.feed_media[0]?.media_url || feed?.feed_media[0]?.media_url}
            alt="postedByImage"
            src={feed?.feed?.feed_media[0]?.media_url || feed?.feed_media[0]?.media_url}
            layout="responsive"
            quality={100}
            width="100%"
            height={"100%"}
            objectfit="contain"
            style={{ maxWidth: 500, width: "100%", marginTop: 20 }}
          />
        </div>:<div className={`${styles.postedImage} mt-3`}>
          <FeedVideo url={feed?.feed?.feed_media[0]?.media_url || feed?.feed_media[0]?.media_url}/>
        </div>
        }
        
        </>
      ) : null}
      <div className="d-flex justify-content-between align-items-center py-3 px-3">
        <div className="d-flex align-items-center text-black-50">
          <img
            src="data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 16 16'%3e%3cdefs%3e%3clinearGradient id='a' x1='50%25' x2='50%25' y1='0%25' y2='100%25'%3e%3cstop offset='0%25' stop-color='%2318AFFF'/%3e%3cstop offset='100%25' stop-color='%230062DF'/%3e%3c/linearGradient%3e%3cfilter id='c' width='118.8%25' height='118.8%25' x='-9.4%25' y='-9.4%25' filterUnits='objectBoundingBox'%3e%3cfeGaussianBlur in='SourceAlpha' result='shadowBlurInner1' stdDeviation='1'/%3e%3cfeOffset dy='-1' in='shadowBlurInner1' result='shadowOffsetInner1'/%3e%3cfeComposite in='shadowOffsetInner1' in2='SourceAlpha' k2='-1' k3='1' operator='arithmetic' result='shadowInnerInner1'/%3e%3cfeColorMatrix in='shadowInnerInner1' values='0 0 0 0 0 0 0 0 0 0.299356041 0 0 0 0 0.681187726 0 0 0 0.3495684 0'/%3e%3c/filter%3e%3cpath id='b' d='M8 0a8 8 0 00-8 8 8 8 0 1016 0 8 8 0 00-8-8z'/%3e%3c/defs%3e%3cg fill='none'%3e%3cuse fill='url(%23a)' xlink:href='%23b'/%3e%3cuse fill='black' filter='url(%23c)' xlink:href='%23b'/%3e%3cpath fill='white' d='M12.162 7.338c.176.123.338.245.338.674 0 .43-.229.604-.474.725a.73.73 0 01.089.546c-.077.344-.392.611-.672.69.121.194.159.385.015.62-.185.295-.346.407-1.058.407H7.5c-.988 0-1.5-.546-1.5-1V7.665c0-1.23 1.467-2.275 1.467-3.13L7.361 3.47c-.005-.065.008-.224.058-.27.08-.079.301-.2.635-.2.218 0 .363.041.534.123.581.277.732.978.732 1.542 0 .271-.414 1.083-.47 1.364 0 0 .867-.192 1.879-.199 1.061-.006 1.749.19 1.749.842 0 .261-.219.523-.316.666zM3.6 7h.8a.6.6 0 01.6.6v3.8a.6.6 0 01-.6.6h-.8a.6.6 0 01-.6-.6V7.6a.6.6 0 01.6-.6z'/%3e%3c/g%3e%3c/svg%3e"
            width={20}
            height={20}
          />
          <span className="ms-2 small text-black-50">
            {nFormatter(feed?.like_count) || 0}
          </span>
        </div>
        <div>
          <span className="ms-2 small text-black-50">
            {nFormatter(feed?.feed?.comment_count || feed?.comment_count) || 0} Comments .{" "}
            {/* {nFormatter(feed?.feed?.views_count || feed?.views_count) || 0} Views .{" "} */}
            {nFormatter(feed?.feed?.share_count || feed?.share_count) || 0} Shares
          </span>
        </div>
      </div>
      {
        !myFav?<div className="postFooter col-12 px-0 py-3  border-top border-bottom overflow-hidden d-flex justify-content-between">
        <div
          className="col d-flex align-items-center justify-content-center"
          role="button"
          onClick={() => {
            apiToken && instantLikeUnlike();
            likeUnlike(feed);
          }}
        >
          <span
            className={
              feed?.is_liked_by_user?.length > 0
                ? `${styles.icons} ${styles.likeIco} liked`
                : `${styles.icons} ${styles.likeIco}`
            }
            ref={likeBtn}
          ></span>{" "}
          <span className="ms-2" style={{ color: "#65676B" }}>
            Like
          </span>
        </div>
        <div
          className="col d-flex align-items-center justify-content-center"
          role={"button"}
          onClick={() =>
            apiToken
              ? setShowHideComments(!showHideComments)
              : setModalOpen(true)
          }
        >
          <span className={`${styles.icons} ${styles.commentIco}`}></span>{" "}
          <span className="ms-2" style={{ color: "#65676B" }}>
            Comment
          </span>
        </div>
        <div className="col d-flex align-items-center justify-content-center position-relative">
          {showHideShare ? <span ref={shareRef} className="d-flex items-center"><Share shareContent={shareContent} isShare={showHideShare} setIsShare={setShowHideShare} iconShareRef={iconShareRef} shareRef={shareRef}/></span>: null}
          <span
            className={`${styles.icons} ${styles.shareIco}`}
            role="button"
            onClick={() => setShowHideShare(!showHideShare)}
          ></span>{" "}
          <span
            className="ms-2"
            style={{ color: "#65676B" }}
            role="button"
            onClick={() => setShowHideShare(!showHideShare)}
          >
            Shares
          </span>
        </div>
      </div>:null}
      <div className="col-12 px-3">
        {!myFav && apiToken && showHideComments ? (
          <FeedsComments
            setModalOpen={setModalOpen}
            modalOpen={modalOpen}
            placeHolderTxt="Write a comment..."
            hideSent={false}
            feed={feed}
            feedRefresh={feedRefresh}
            feedRefreshMain={feedRefreshMain}
          />
        ) : null}
      </div>
    </div>
  ) : null;
}
