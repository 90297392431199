import { useEffect, useState } from "react";
import { changePassword, getUserInfo } from "../../../api/OurService";

import * as Yup from "yup";
import { Loadingicon } from "../../../AppIcons";
import BreadCrumbs from "../../common/BreadCrumbs";
import { Formik, useFormik } from "formik";
import { useAuth } from "../../../Context/AuthContext";
import { Button, Card } from "react-bootstrap";
import { toast } from "react-toastify";
import { onLinkDelete, onMyLinksList, onNewLinkAdd, onProfileVerification, onRaisedTicketsList, onRecentActivityList } from "../../../api/AuthService";
import notFound from '../../../assets/images/not_found_new.png';

import { useNavigate } from "react-router-dom";
import { BASE_URL, CNEWS_BASE_URL, DC_BASE_URL, EX_BASE_URL, FF_BASE_URL, INTBANK_BASE_URL, LYRICS_BASE_URL, QBANK_BASE_URL, QUOTES_BASE_URL, SHOWTALENT_BASE_URL, formatDate } from "../../../utils/utils";


function RecentActivity() {
    const [loading, setLoading] = useState(false);
    const { user, globalToken } = useAuth();
    const [activityLogs, setActivityLogs] = useState([]);
    const [choosenModule, setChoosenModule] = useState('');
    const [choosenDayFilter, setChoosenDayFilter] = useState(7);
    const modules = [
        {
            "name": "All",
            "value": ""
        },
        {
            "name": "Showtalent",
            "value": "showtalent"
        }, {
            "name": "Questionbank",
            "value": "questionbank"
        }, {
            "name": "Dictionary",
            "value": "dictionary"
        }, {
            "name": "Exams",
            "value": "exams"
        }, {
            "name": "Fullform",
            "value": "fullforms"
        }, {
            "name": "Interview Question",
            "value": "interviewquestions"
        }, {
            "name": "Careernews",
            "value": "careernews"
        }, {
            "name": "Feeds",
            "value": "feeds"
        }, {
            "name": "Lyrics",
            "value": "lyrics"
        }, {
            "name": "Quotes",
            "value": "quotes"
        }, {
            "name": "Courses",
            "value": "courses"
        }, {
            "name": "Work",
            "value": "work"
        }, {
            "name": "Gigs",
            "value": "gigs"
        }, {
            "name": "Forum",
            "value": "forum"
        }, {
            "name": "Tutorials",
            "value": "tutorials"
        }, {
            "name": "Blogs",
            "value": "blogs"
        }, {
            "name": "Educators",
            "value": "educators"
        }, {
            "name": "Corporates",
            "value": "corporates"
        }, {
            "name": "Tools",
            "value": "tools"
        }]

    const navigate = useNavigate()
    // const bUrl = (type) => {
    //     if(type=='tuteehub'){
    //         return BASE_URL;
    //     }
    //     if(type=='questionbank'){
    //         return QBANK_BASE_URL;
    //     }
    //     if(type=='interviewq'){
    //         return INTBANK_BASE_URL;
    //     }
    //     if(type=='fullform'){
    //         return FF_BASE_URL;
    //     }
    //     if(type=='dictionary'){
    //         return DC_BASE_URL;
    //     }
    //     if(type=='exams'){
    //         return EX_BASE_URL;
    //     }
    //     if(type=='careernews'){
    //         return CNEWS_BASE_URL;
    //     }
    //     if(type=='quotes'){
    //         return QUOTES_BASE_URL;
    //     }
    //     if(type=='lyrics'){
    //         return LYRICS_BASE_URL;
    //     }
    //     if(type=='showtalent'){
    //         return SHOWTALENT_BASE_URL;
    //     }
    //     if(type=='tools'){
    //         return TO;
    //     }
    //     if(type=='blogs'){
    //         return EX_BASE_URL;
    //     }
    //     if(type=='forum'){
    //         return EX_BASE_URL;
    //     }
    //     if(type=='tutorials'){
    //         return EX_BASE_URL;
    //     }
    //     if(type=='corporates'){
    //         return EX_BASE_URL;
    //     }
    //     if(type=='educators'){
    //         return EX_BASE_URL;
    //     }
    //     if(type=='work'){
    //         return EX_BASE_URL;
    //     }
    //     if(type=='courses'){
    //         return EX_BASE_URL;
    //     }
    // }

    const dayFilterOptions = [
        {
            label: 'Past 7 Days',
            value: 7,
        },
        {
            label: 'Past 30 Days',
            value: 30,
        },
        {
            label: 'Past 90 Days',
            value: 90,
        },
        {
            label: 'Past 365 Days',
            value: 365,
        }
    ];
    
    const handleActivityList = async (module, filterDays) => {
        setLoading(true)
        // const token = localStorage.getItem('___fTkn');

        const res = await onRecentActivityList({ token: globalToken, choosenModule: module, filterDays:filterDays});
    //    console.log(res)
        if (res?.success==200) {
            setActivityLogs(res?.data?.original?.data);
        } else {
            toast.error(res?.message)
        }
        setLoading(false)
    }

    useEffect(() => {
        handleActivityList('', choosenDayFilter);
    }, [])
    
    const handleModuleChange = (e, type) => {
        if(type=='module'){
            setChoosenModule(e.target.value);
            handleActivityList(e.target.value, choosenDayFilter);
        }
        if(type=='days'){
            setChoosenDayFilter(e.target.value);
            handleActivityList(choosenModule, e.target.value);
        }
        // fetchCreditHistory(e.target.value);
    }
    
    return (
        <>
            <BreadCrumbs />

            <div className=" py-4 grid px-3 grid-cols-1 group-card card-body gap-4">
                {loading ? (
                    <div className="w-full flex items-center justify-center mt-20 mb-20 text-themecolor min-h-[400px]">
                        <div className="h-10 w-10">
                            <Loadingicon />
                        </div>
                    </div>
                ) : (
                    <>
                        <div className="card mb-3 border-0 shadow">
                            <div className="card-body d-flex flex-md-row flex-column gap-4">
                                <div className="flex-3">
                                    <label className="mb-2">Filter By Module</label>
                                    <select value={choosenModule} onChange={(e)=>handleModuleChange(e, 'module')} className="form-control">
                                        <option disabled>Filter By Module</option>
                                        {
                                            modules?.map(module => (<option value={module?.value}>{module?.name}</option>))
                                        }
                                    </select>
                                </div>
                                <div className="flex-1">
                                    <label className="mb-2">Filter By Days</label>
                                    <select value={choosenDayFilter} onChange={(e)=>handleModuleChange(e, 'days')} className="form-control">
                                        <option disabled>Filter By Days</option>
                                        {
                                                dayFilterOptions?.map(dayOption => (<option value={dayOption?.value}>{dayOption?.label}</option>))
                                        }
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="px-0">
                        <div className="card mb-3 border-0 shadow">
                            <div className="card-body table-responsive">
                            {
                                activityLogs?.length?(
                                    <table className="table table-bordered mt-2">
                                        <thead>
                                            <tr>
                                                <th>Module</th>
                                                <th>Activity Page</th>
                                                <th>Action Type</th>
                                                <th>IP Address</th>
                                                <th>Device</th>
                                                <th>Location</th>
                                                <th>Date</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                activityLogs?.map(activityLog => (
                                                    <>
                                                        <tr>
                                                            <td>{activityLog?.module}</td>
                                                            <td>{activityLog?.content_title && activityLog?.content_url?<a href={activityLog?.content_url} target="_blank">{activityLog?.content_title}</a>:!activityLog?.content_title && activityLog?.content_url?<a href={activityLog?.content_url} target="_blank">{activityLog?.content_url}</a>:'NA'}</td>
                                                            <td>{activityLog?.action || 'NA'}</td>
                                                            <td>{activityLog?.ip || 'NA'}</td>
                                                            <td>{activityLog?.location || 'NA'}</td>
                                                            <td>{activityLog?.device || 'NA'}</td>
                                                            <td>{formatDate(activityLog?.created_at)}</td>
                                                        </tr>
                                                    </>
                                                ))
                                            }
                                        </tbody>
                                    </table>
                                ):<div className="flex-column not-found d-flex text-center" >
                                <img src={notFound} className="notFoundImg" />
                                <span style={{ opacity: 0.7 }}>No activity logs</span>
                            </div>
                            }
                                    
                            </div>
                        </div>
                        </div>
                    </>
                )
                }
            </div>

        </>
    );
}
export default RecentActivity;
