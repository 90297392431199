import { WhatsApp } from "@material-ui/icons";
import React, { useEffect } from "react";

// import { Facebook, Telegram, Twitter } from 'react-social-sharing'
// import Image from "next/image";
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon,
  WhatsappShareButton,
  WhatsappIcon,
  TelegramShareButton,
  TelegramIcon,
} from "react-share";

export default function Share({shareContent, isCustomPos=false, isShare=false, setIsShare=null, iconShareRef=null, shareRef=null}) {

  const handleOutsideClick = (e) => {
    if (
      shareRef.current &&
      !shareRef.current.contains(e.target) &&
      iconShareRef.current &&
      !iconShareRef.current.contains(e.target)
    ) {
      
      setIsShare(false); 
    }
  };

  useEffect(() => {
    if (isShare) {
      document.addEventListener("mousedown", handleOutsideClick);
    } else {
      document.removeEventListener("mousedown", handleOutsideClick);
    }
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [isShare]);

  return (
    <div className={`col share-container ${isCustomPos?'share-q':''}`}>  
      <FacebookShareButton
        
        url={shareContent?.url}
        quote={shareContent?.quote}
        hashtag={shareContent?.hashtag}
      >
        <FacebookIcon size={32} round />
      </FacebookShareButton>
      <TwitterShareButton
        url={shareContent?.url}
        title={shareContent?.title}
      >
        <TwitterIcon size={32} round />
      </TwitterShareButton>
      <LinkedinShareButton url={shareContent?.url}>
        <LinkedinIcon size={32} round />
      </LinkedinShareButton>
      <WhatsappShareButton
        url={shareContent?.url}
        title={shareContent?.title}
        separator=":: "
      >
        <WhatsappIcon size={32} round />
      </WhatsappShareButton>
      <TelegramShareButton
        url={shareContent?.url}
        title={shareContent?.title}
      >
        <TelegramIcon size={32} round />
      </TelegramShareButton> 
    </div>
  );
}
