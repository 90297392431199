export const getOverviewData = (params) => {
    const { query, startDate, endDate } = params;
    let myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    let url = `https://www.tuteehub.com/api/get-all-platform-counts`;
    if (startDate && endDate) {
        url += `?startdate =${startDate}&enddate=${endDate}`;
    } else if (query) {
        url += `?this_month=${query}`
    }
    let requestOptions = {
        method: "GET",
        headers: myHeaders,
    };
    return fetch(url, requestOptions)
        .then((response) => response.json())
        .catch((error) => console.log("error", error));
};