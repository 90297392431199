import LoadingScreen from "../../common/LoadingScreen";
import InfiniteScroll from "react-infinite-scroller";
import { FeedCard, FeedsComments } from "../../../components/common";
import CameraAlt from "@material-ui/icons/CameraAlt";
import Person from "@material-ui/icons/Person";

import { FeedsLoader, WebStoryLoader } from "../../../components/common/loaders";
import styles from "../../../assets/css/styles/Home.module.css";
import VideoCall from "@material-ui/icons/VideoCall";
import CircularProgress from "@material-ui/core/CircularProgress";
import Description from "@material-ui/icons/Description";
import { GetData, getOverviewData } from "../../../api/feeds/GetData";
import { useEffect, useRef, useState, createContext } from "react";
import { PostDataAuth } from "../../../api/feeds/PostDataAuth";
import { GetDataAuth } from "../../../api/feeds/GetDataAuth";
import { useAuth } from "../../../Context/AuthContext";
import { useFeeds } from "../../../Context/FeedsContext";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { PostData } from "../../../api/feeds/PostData";
import moment from "moment";
import { Button } from "@material-ui/core";
import { getUserInfo } from "../../../api/OurService";
import { USER_INFO_KEY } from "../../../utils/utils";
import { toast } from "react-toastify";
import AdminNotifications from "../../common/adminNotification";
import Nodata from "../../common/NoData";

function Feeds() {
  const navigation = useNavigate();
  let loadFirst = true;
  const webStorySliderConfig = {
    dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 1,
      centerMode: false,
      centerPadding:10
  };
  const librarySliderConfig = {
      dots: false,
      infinite: false,
      variableWidth:true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      centerMode: false,
  };
  // const [loading, setLoading] = useState(false);
  const {
    myFav,
    setMyFav,
    modalOpen,
    setModalOpen,
    myFeeds,
    setMyFeeds,
    feeds,
    setFeeds,
    search,
    setSearch,
    loading,
    setLoading,
  } = useFeeds();
  const [apiToken, setApiToken] = useState(null);
  const [isFromDetail, setIsFromDetail] = useState(
    localStorage.getItem("feedDetail") ? true : false
  );
  let emailVerifiedStatus = true;
  const [favBtnLoader, setFavBtnLoader] = useState(false);
  const { handleLogout, user, fToken, setFinance, setUser, globalToken, setIsEmailVerifyCover } = useAuth();
  const [showHideSidebar, setShowHideSidebar] = useState(false);
  const [popularUsers, setPopularUsers] = useState();
  const [postType, setPostType] = useState("image");
  const [webstory, setWebstory] = useState([]);
  const [recentVideos, setRecentVideos] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const [data, setData] = useState(null);
  const headerRef = useRef();
  const scrollEndHandle = () => {
    if (feeds?.current_page < feeds?.last_page) {
      
      _allFeed(feeds?.current_page + 1);
    }
  };

  const colors =[
    'rgb(239 47 47)',
    'rgb(68 69 96)',
    'rgb(92 68 96)',
    'rgb(36 64 68)',
    'rgb(46 100 75)',
    'rgb(100 46 46)',
    'rgb(65 36 80)',
  ]

  const libraryData = [
    {
      title:"Career News",
      image:require('../../../assets/images/libraries/careernews.webp'),
      link:"https://careernews.tuteehub.com",
    },
    {
      title:"Question Bank",
      image:require('../../../assets/images/libraries/question_bank.png'),
      link:"https://questionbank.tuteehub.com",
    },
    {
      title:"Interview Question",
      image:require('../../../assets/images/libraries/question_paper.webp'),
      link:"https://interviewquestions.tuteehub.com",
    },
    {
      title:"Dictionary",
      image:require('../../../assets/images/libraries/exam.webp'),
      link:"https://dictionary.tuteehub.com",
    },
    {
      title:"Full Forms",
      image:require('../../../assets/images/libraries/intern.webp'),
      link:"https://fullforms.tuteehub.com",
    },
    {
      title:"Exams",
      image:require('../../../assets/images/libraries/sample_papers.webp'),
      link:"https://exams.tuteehub.com",
    }
  ]

  useEffect(() => {
    window.scrollTo(0,0)
    // const token = localStorage.getItem("___fTkn");    
    // setApiToken(token);
    
    showtalentVideoByCategory();
    showtalentRecentVdos();
    // if (localStorage.getItem("feedDetail")) {
      // localStorage.removeItem('feedDetail')
    // } else {
      // alert(2)
      // _allFeed(1);
    // }
    
  }, []);
  

  useEffect(() => {
   
    if (loadFirst && !myFeeds && !myFav) {  
      _allFeed(1);      
	    loadFirst=false;      
    }
  }, [isFromDetail, loadFirst]);

  const handlePostType = (type) => {
    setPostType(type);
  };

  const _favoriteFeed = async (item, favouriteType) => {
    setFavBtnLoader(true);
    if (localStorage.getItem("userDetails")) {
      // const aToken = localStorage.getItem("___fTkn");
      const favouriteDataReturn = await PostDataAuth(
        `feed-favourite/add`,
        globalToken,
        { feedId: item?.id, favourite: favouriteType }
      );
      // console.log(favouriteDataReturn);
      if (favouriteDataReturn?.success) {

        if(favouriteDataReturn?.status==5){
          toast.error(favouriteDataReturn?.errors);
          setTimeout(() => { setIsEmailVerifyCover(true) }, 200)
      }
        _allFeed(1);
      } else {
        if(favouriteDataReturn?.status==5){         
            toast.error(favouriteDataReturn?.errors);
            setTimeout(() => { setIsEmailVerifyCover(true) }, 200)
        }
        setModalOpen(true);
      }
    } else {
      setModalOpen(true);
    }

    setFavBtnLoader(false);

  };

  const showtalentVideoByCategory = async ()=>{
    const videosByCatReturn = await PostData(
      ``,
      'showtalent',
      { }
    );
    const allvid = (videosByCatReturn?.response?.result?.data).sort((a,b)=>moment(b?.videos[0]?.created_at, 'DD-MM-YYYY').diff(moment(a?.videos[0]?.created_at, 'DD-MM-YYYY')));
    const filteredVidCat = allvid.map((av)=>{return {...av, gif:av.videos[0].gif,image:av?.videos[0]?.image,userImage:av?.videos[0]?.userimage,spaceUrl:videosByCatReturn?.response?.result?.SpaceBaseUrl}})
    setWebstory(filteredVidCat)

  }  

  const showtalentRecentVdos = async ()=>{
    const recentVdoReturn = await PostData(
      ``,
      'showtalentVdo',
      { }
    );
    let filteredRecentVdos = (recentVdoReturn?.response?.result?.data?.length)?recentVdoReturn?.response?.result?.data.map((av)=>{return {...av, spaceUrl:recentVdoReturn?.response?.result?.SpaceBaseUrl}}):[]
    // alert(JSON.stringify(1))
    setRecentVideos(filteredRecentVdos)

  } 
  const _allFeed = async (pageNumber) => {
	  
    if (
      user &&
      globalToken
    ) {
      
      // const token = localStorage.getItem("___fTkn");
      // console.log('tkn', token)
      // setApiToken(token);
      
      const allFeeds = await GetDataAuth(
        `get/feed/list/via-authentic-user?page=${pageNumber}`,
        globalToken
      );
      setHasMore(true);
      // console.log(allFeeds)
      if (allFeeds?.current_page !== allFeeds?.last_page && pageNumber !== 1) {
        const mergedFeeds = [...feeds?.data, ...allFeeds?.data];
        setFeeds({ ...feeds, data: mergedFeeds, current_page: pageNumber });
      } else {
        setFeeds(allFeeds);
      }

      
    } else {
      const allFeeds = await GetData(`get/feed/list?page=${pageNumber}`);
      setHasMore(true);
      if (allFeeds?.current_page !== allFeeds?.last_page && pageNumber !== 1) {
        const mergedFeeds = [...feeds?.data, ...allFeeds?.data];
        setFeeds({ ...feeds, data: mergedFeeds, current_page: pageNumber });
      } else {
        setFeeds(allFeeds);
      }
    }
    
    setLoading(false);
  };

  
  const fetchUserInfo = async (userId) => {
    const res = await getUserInfo(userId, user?.access_token);
    if(res.success){
      // console.log(res)
        setUser({...user, credits:res?.data?.credits})
        localStorage.setItem('userDetails',JSON.stringify({...user, credits:res?.data?.credits}))
        localStorage.setItem('____fi', JSON.stringify({__crdt:res.data?.credits}))
    setFinance({__crdt:res.data?.credits}) 
    } 
    // console.log(userDetails?.slug)

}

  const onLikeDislikeHandler = async (item, index) => {
    if (globalToken) {
      const likeUnlikeReturn = await PostDataAuth(
        `feed/like`,
        globalToken,
        { feed_id: item?.id }
      );
      if (likeUnlikeReturn?.success) {
        
            toast.success(likeUnlikeReturn?.message)
          
        
        _allFeed(1);
        fetchUserInfo(user?.tuteehub_id)
      } else {
        if(likeUnlikeReturn?.status==5){   
          emailVerifiedStatus = false;            
          toast.error(likeUnlikeReturn?.errors);
          setTimeout(() => { setIsEmailVerifyCover(true) }, 200)
      }
        setModalOpen(true);
      }
    } else {
      setModalOpen(true);
    }
  };

  const slugify = (text) => {
    return text
      .toLowerCase()
      .replace(/ /g, "-")
      .replace(/[^\w-]+/g, "");
  };
  const goToFeedDetail = (id, slug) => {
    navigation(`${id}/${slugify(slug)}`, { state: { feeds: feeds } });
  };

  const Libraries = () =>(
    <div className=" suggested-slider px-3">
            <Slider {...librarySliderConfig}>  
                {
                  libraryData?.map((library,i) =>(<div key={'library_'+i}>
                    <a  style={{backgroundColor:colors[i]}} className="library-item-container" href={library.link} target="_blank">
                        <span style={{}}>{library.title}</span>
                        <img src={library.image} />
                    </a>
                    </div>
                  ))
                }
             </Slider>
             </div>
  )


  const RecentVideosSection = () =>(
    <>
    {
      recentVideos?.length?(<div className={("mb-3 px-3 overflow-hidden", styles.postcard)}>
      <div className="wetstory-heading d-flex pt-3 px-3 align-items-center gap-2">
          <span><img src={require('../../../assets/images/shawtalent_ico.png')} width="25"/></span>
            <span className="text-bold">Showtalent</span>
            <small className="text-black-50 text-bold">via videos</small>
      </div>
    <div className="p-3 pb-0 trending-vdo">
      <Slider {...webStorySliderConfig}>
        {
          recentVideos?.map((vdo,i)=>(
            <div key={'vdo_Recent_'+i} className="px-0 slick-slide position-relative" style={{opacity:.7}}>
            <div className="team-member py-0 mb-0 px-0 orange-hover">
              <div className="box-line"></div>
              <div className="team-member-photo m-0">
                <a
                  href={window.innerWidth>767?`https://showtalent.tuteehub.com/videos/${(vdo?.cat_name).toLowerCase()}/${vdo?.username}/${vdo?.video_id}/${slugify(vdo?.title || vdo?.sound_name)}`:`https://showtalent.page.link/6SuK`}
                  className="vid-container-new"
                  target="_blank"
                  tabIndex="-1"
                >
                  <img
                    className="img-fluid"
                    src={vdo?.gif?vdo?.spaceUrl+'/'+vdo?.image:vdo?.spaceUrl+'/'+vdo?.image}
                  />
                </a>
              </div>
              <div className="team-img-meta w-100">
                <div className="user px-2 mt-3 w-100">
                  <div className="media d-flex align-items-center flex-column w-100">
                    <div className="profile-img mr-2">
                      <img src={vdo?.spaceUrl+'/'+vdo?.userimage} />
                    </div>
                    <a
                  href={window.innerWidth>767?`https://showtalent.tuteehub.com/videos/${(vdo?.cat_name).toLowerCase()}/${vdo?.username}/${vdo?.video_id}/${slugify(vdo?.title || vdo?.sound_name)}`:`https://showtalent.page.link/6SuK`}
                  className="cursor-pointer"
                  target="_blank"
                  tabIndex="-1"
                >
                    <label className="mb-0 text-white cursor-pointer">{vdo?.title || vdo?.sound_name}</label>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          ))
        }
      </Slider>
    </div>
    <div className="wetstory-heading d-flex p-3 align-items-center gap-2 ">
      <a
          role="button"
          className="d-flex text-black-50 align-items-center postTypeButton p-1 py-2 flex-1 rounded justify-content-center text-center text-decoration-none"
          href="https://showtalent.page.link/6SuK"
          style={{
            backgroundColor:"rgb(249 229 163)",
            color:"#333"
          }}
        >
          <CameraAlt
            className="me-2"
            style={{ fill: "#333", fontSize: 25 }}
          />{" "}
          <strong style={{color:"#333"}}>Create</strong>
        </a>
        <a
            role="button"
            className="d-flex text-black-50 align-items-center postTypeButton p-1 py-2 flex-1 rounded justify-content-center text-center text-decoration-none"
            href="https://showtalent.page.link/6SuK"
            style={{
              backgroundColor:"#f2f2f2",
              color:"#ccc"
            }}
          >
            <Person
              className="me-2"
              style={{ fill: "#ccc", fontSize: 25 }}
            />{" "}
            <strong style={{color:"#ccc"}}>Account</strong>
          </a>
      </div>
  </div>):(<div className={("mb-3 px-3 overflow-hidden", styles.postcard)}>
      <div className="wetstory-heading d-flex pt-3 px-3 align-items-center gap-2">
          <span><img src={require('../../../assets/images/shawtalent_ico.png')} width="25"/></span>
            <span className="text-bold">Showtalents</span>
            <small className="text-black-50 text-bold">via videos</small>
      </div>
    <div className="p-3 pb-0 trending-vdo">
      <Slider {...webStorySliderConfig}>
        <WebStoryLoader key={'storyloader1'}/>
        <WebStoryLoader key={'storyloader1'}/>
        <WebStoryLoader key={'storyloader1'}/>
        <WebStoryLoader key={'storyloader1'}/>
        <WebStoryLoader key={'storyloader1'}/>
      </Slider>
    </div>
    <div className="wetstory-heading d-flex p-3 align-items-center gap-2 ">
      <a
          role="button"
          className="d-flex text-black-50 align-items-center postTypeButton p-1 py-2 flex-1 rounded justify-content-center text-center text-decoration-none"
          href="https://showtalent.page.link/6SuK"
          style={{
            backgroundColor:"rgb(249 229 163)",
            color:"#333"
          }}
        >
          <CameraAlt
            className="me-2"
            style={{ fill: "#333", fontSize: 25 }}
          />{" "}
          <strong style={{color:"#333"}}>Create</strong>
        </a>
        <a
            role="button"
            className="d-flex text-black-50 align-items-center postTypeButton p-1 py-2 flex-1 rounded justify-content-center text-center text-decoration-none"
            href="https://showtalent.page.link/6SuK"
            style={{
              backgroundColor:"#f2f2f2",
              color:"#ccc"
            }}
          >
            <Person
              className="me-2"
              style={{ fill: "#ccc", fontSize: 25 }}
            />{" "}
            <strong style={{color:"#ccc"}}>Account</strong>
          </a>
      </div>
  </div>)
    }
    </>
  )
  return (
    <>
      {loading ? (
        <>
          <div className={("mb-3 px-3 overflow-hidden", styles.postcard)}>
            <div className="wetstory-heading d-flex pt-3 px-3 align-items-center gap-2">
              <span>
                <img
                  src={require("../../../assets/images/shawtalent_ico.png")}
                  width="25"
                />
              </span>
              <span className="text-bold">Showtalent</span>
              <small className="text-black-50 text-bold">via videos</small>
            </div>
            <div className="p-3 pb-0 trending-vdo">
              <Slider {...webStorySliderConfig}>
                <WebStoryLoader key={"storyloader10"} />
                <WebStoryLoader key={"storyloader11"} />
                <WebStoryLoader key={"storyloader12"} />
                <WebStoryLoader key={"storyloader13"} />
                <WebStoryLoader key={"storyloader14"} />
              </Slider>
            </div>
            <div className="wetstory-heading d-flex p-3 align-items-center gap-2 ">
              <a
                role="button"
                className="d-flex text-black-50 align-items-center postTypeButton p-1 py-2 flex-1 rounded justify-content-center text-center text-decoration-none"
                href="https://showtalent.page.link/6SuK"
                style={{
                  backgroundColor: "rgb(249 229 163)",
                  color: "#333",
                }}
              >
                <CameraAlt
                  className="me-2"
                  style={{ fill: "#333", fontSize: 25 }}
                />{" "}
                <strong style={{ color: "#333" }}>Create</strong>
              </a>
              <a
                role="button"
                className="d-flex text-black-50 align-items-center postTypeButton p-1 py-2 flex-1 rounded justify-content-center text-center text-decoration-none"
                href="https://showtalent.page.link/6SuK"
                style={{
                  backgroundColor: "#f2f2f2",
                  color: "#ccc",
                }}
              >
                <Person
                  className="me-2"
                  style={{ fill: "#ccc", fontSize: 25 }}
                />{" "}
                <strong style={{ color: "#ccc" }}>Account</strong>
              </a>
            </div>
          </div>
          <FeedsLoader key={"loader1"} />
          <FeedsLoader key={"loader2"} />
          <FeedsLoader key={"loader3"} />
          <FeedsLoader key={"loader4"} />
        </>
      ) : null}
      {feeds?.data?.length ? (
        <InfiniteScroll
          pageStart={0}
          loadMore={scrollEndHandle}
          hasMore={hasMore}
          loader={
            feeds?.data?.length > 10 ? (
              <>
                <FeedsLoader key={"loader1"} />
                <FeedsLoader key={"loader2"} />
                <div className="loader text-center" key={0}>
                  <CircularProgress
                    size={30}
                    color={"inherit"}
                    className="ms-2"
                  />
                </div>
              </>
            ) : null
          }
        >
          {/* {apiToken ? (
            <>   */}
          {webstory?.length ? (
            <>

            <div className={("mb-3 px-3 overflow-hidden", styles.postcard)}>
              <div className="wetstory-heading d-flex pt-3 px-3 align-items-center gap-2">
                <span>
                  <img
                    src={require("../../../assets/images/shawtalent_ico.png")}
                    width="25"
                  />
                </span>
                <span className="text-bold">Showtalent</span>
                <small className="text-black-50 text-bold">via videos</small>
              </div>
              <div className="p-3 pb-0 trending-vdo">
                <Slider {...webStorySliderConfig}>
                  {webstory?.slice(0, 10)?.map((story, i) => (
                    <div
                      key={"story_" + i}
                      className="px-0 slick-slide position-relative"
                      style={{ opacity: 0.7 }}
                    >
                      <div className="team-member py-0 mb-0 px-0 orange-hover">
                        <div className="box-line"></div>
                        <div className="team-member-photo m-0">
                          <a
                            href={
                              window.innerWidth > 767
                                ? `https://showtalent.tuteehub.com/web-stories/${story?.id}`
                                : `https://showtalent.page.link/6SuK`
                            }
                            className="vid-container-new"
                            target="_blank"
                            tabIndex="-1"
                          >
                            <img
                              className="img-fluid"
                              src={
                                story?.gif
                                  ? story?.spaceUrl + "/" + story?.image
                                  : story?.spaceUrl + "/" + story?.image
                              }
                            />
                          </a>
                        </div>
                        <div className="team-img-meta w-100">
                          <div className="user px-2 mt-3 w-100">
                            <div className="media d-flex align-items-center flex-column w-100">
                              <div className="profile-img mr-2">
                                {story?.userImage ? (
                                  <img
                                    src={
                                      story?.spaceUrl + "/" + story?.userImage
                                    }
                                  />
                                ) : (
                                  <img
                                    src={require("../../../assets/images/profile_placeholder.png")}
                                    width={25}
                                    height={25}
                                  />
                                )}
                              </div>
                              <label className="mb-0 text-white">
                                {story?.name}
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </Slider>
              </div>
              <div className="wetstory-heading d-flex p-3 align-items-center gap-2 ">
                <a
                  role="button"
                  className="d-flex text-black-50 align-items-center postTypeButton p-1 py-2 flex-1 rounded justify-content-center text-center text-decoration-none"
                  href="https://showtalent.page.link/6SuK"
                  style={{
                    backgroundColor: "rgb(249 229 163)",
                    color: "#333",
                  }}
                >
                  <CameraAlt
                    className="me-2"
                    style={{ fill: "#333", fontSize: 25 }}
                  />{" "}
                  <strong style={{ color: "#333" }}>Create</strong>
                </a>
                <a
                  role="button"
                  className="d-flex text-black-50 align-items-center postTypeButton p-1 py-2 flex-1 rounded justify-content-center text-center text-decoration-none"
                  href="https://showtalent.page.link/6SuK"
                  style={{
                    backgroundColor: "#f2f2f2",
                    color: "#ccc",
                  }}
                >
                  <Person
                    className="me-2"
                    style={{ fill: "#ccc", fontSize: 25 }}
                  />{" "}
                  <strong style={{ color: "#ccc" }}>Account</strong>
                </a>
              </div>
            </div>
            </>
          ) : (
            <div className={("mb-3 px-3 overflow-hidden", styles.postcard)}>
              <div className="wetstory-heading d-flex pt-3 px-3 align-items-center gap-2">
                <span>
                  <img
                    src={require("../../../assets/images/shawtalent_ico.png")}
                    width="25"
                  />
                </span>
                <span className="text-bold">Showtalent</span>
                <small className="text-black-50 text-bold">via videos</small>
              </div>
              <div className="p-3 pb-0 trending-vdo">
                <Slider {...webStorySliderConfig}>
                  <WebStoryLoader key={"storyloader5"} />
                  <WebStoryLoader key={"storyloader6"} />
                  <WebStoryLoader key={"storyloader7"} />
                  <WebStoryLoader key={"storyloader8"} />
                  <WebStoryLoader key={"storyloader9"} />
                </Slider>
              </div>
              <div className="wetstory-heading d-flex p-3 align-items-center gap-2 ">
                <a
                  role="button"
                  className="d-flex text-black-50 align-items-center postTypeButton p-1 py-2 flex-1 rounded justify-content-center text-center text-decoration-none"
                  href="https://showtalent.page.link/6SuK"
                  style={{
                    backgroundColor: "rgb(249 229 163)",
                    color: "#333",
                  }}
                >
                  <CameraAlt
                    className="me-2"
                    style={{ fill: "#333", fontSize: 25 }}
                  />{" "}
                  <strong style={{ color: "#333" }}>Create</strong>
                </a>
                <a
                  role="button"
                  className="d-flex text-black-50 align-items-center postTypeButton p-1 py-2 flex-1 rounded justify-content-center text-center text-decoration-none"
                  href="https://showtalent.page.link/6SuK"
                  style={{
                    backgroundColor: "#f2f2f2",
                    color: "#ccc",
                  }}
                >
                  <Person
                    className="me-2"
                    style={{ fill: "#ccc", fontSize: 25 }}
                  />{" "}
                  <strong style={{ color: "#ccc" }}>Account</strong>
                </a>
              </div>
            </div>
          )}

          <div className={("mb-3 px-3", styles.postcard)}>
            <div className="px-3">
              {user || globalToken ? (
                <FeedsComments
                  setModalOpen={setModalOpen}
                  modalOpen={modalOpen}
                  postType={postType}
                  placeHolderTxt={`What's on your mind ${
                    user?.name || user?.data?.name ? ", " : ""
                  } ${user?.name || user?.data?.name || ""}?`}
                  hideSent={true}
                  _allFeed={_allFeed}
                />
              ) : null}
              <div
                className="m-0 col-md-12 px-0"
                style={{ height: 1, backgroundColor: "#f0f0f0" }}
              />
              <div className="postTypes py-2 d-flex justify-content-between">
                <span
                  role="button"
                  className="d-flex text-black-50 align-items-center postTypeButton p-1 flex-1 rounded justify-content-center text-center"
                  onClick={() => handlePostType("image")}
                  style={{
                    backgroundColor: postType === "image" ? "#f2f2f2" : "#fff",
                  }}
                >
                  <VideoCall
                    className="me-2"
                    style={{ fill: "#F3425F", fontSize: 32 }}
                  />{" "}
                  <strong>Video/Image</strong>
                  {/* Video/ */}
                </span>
                <span
                  role="button"
                  className="d-flex text-black-50 align-items-center postTypeButton p-1 text-center justify-content-center flex-1 rounded"
                  onClick={() => handlePostType("text")}
                  style={{
                    backgroundColor: postType === "text" ? "#f2f2f2" : "#fff",
                  }}
                >
                  <Description
                    className="me-2"
                    style={{ fill: "#50c16b", fontSize: 28 }}
                  />{" "}
                  <strong>Text</strong>
                </span>
              </div>
            </div>
          </div>
          {/* </>
          ) : (
            <div className={("mb-3 px-3", styles.postcard)}>
              <div className="px-3">
                <FeedsComments
                  setModalOpen={setModalOpen}
                  modalOpen={modalOpen}
                  placeHolderTxt={`What's on your mind ${
                    user?.name || user?.name ? ", " : ""
                  } ${user?.name || user?.name || ""}?`}
                  hideSent={true}
                  _allFeed={_allFeed}
                />

                <div
                  className="m-0 col-md-12 px-0"
                  style={{ height: 1, backgroundColor: "#f0f0f0" }}
                />
                <div className="postTypes py-2 d-flex justify-content-between">
                  <span
                    role="button"
                    className="d-flex text-black-50 align-items-center postTypeButton p-1 flex-1 rounded justify-content-center text-center"
                    onClick={() => setModalOpen(!modalOpen)}
                  >
                    <VideoCall
                      className="me-2"
                      style={{ fill: "#F3425F", fontSize: 32 }}
                    />{" "}
                    <strong>Video/Image</strong>
                  </span>
                  <span
                    role="button"
                    className="d-flex text-black-50 align-items-center postTypeButton p-1 text-center justify-content-center flex-1 rounded"
                    onClick={() => setModalOpen(!modalOpen)}
                  >
                    <Description
                      className="me-2"
                      style={{ fill: "#50c16b", fontSize: 28 }}
                    />{" "}
                    <strong>Text</strong>
                  </span>
                </div>
              </div>
            </div>
          )} */}
          {feeds?.data?.length && search ? (
            <div className={("mb-3 px-3", styles.postcard)}>
              <div className="p-4 d-flex align-items-center justify-content-between w-100">
                <span className="text-balck-50 ">
                  <strong>{feeds?.total} feeds found</strong>
                </span>
                <span
                  className="text-balck-50 "
                  role="button"
                  onClick={() => {
                    setSearch(false);
                    setMyFav(false);
                    _allFeed(1);
                  }}
                >
                  X
                </span>
              </div>
            </div>
          ) : null}
          {feeds?.data?.length && myFav && !myFeeds ? (
            <div className={("mb-3 px-3", styles.postcard)}>
              <div className="p-4 d-flex align-items-center justify-content-between w-100">
                <span className="text-balck-50 ">
                  <strong>My Favourites ({feeds?.total})</strong>
                </span>
                <span
                  className="text-balck-50 "
                  role="button"
                  onClick={() => {
                    setSearch(false);
                    setMyFeeds(false);
                    setMyFav(false);
                    _allFeed(1);
                  }}
                >
                  X
                </span>
              </div>
            </div>
          ) : null}
          {feeds?.data?.length && myFeeds && !myFav ? (
            <div className={("mb-3 px-3", styles.postcard)}>
              <div className="p-4 d-flex align-items-center justify-content-between w-100">
                <span className="text-balck-50 ">
                  <strong>My Feeds ({feeds?.total})</strong>
                  {/* ({feeds?.total}) */}
                </span>
                <span
                  className="text-balck-50 "
                  role="button"
                  onClick={() => {
                    setMyFeeds(false);
                    setMyFav(false);
                    _allFeed(1);
                  }}
                >
                  X
                </span>
              </div>
            </div>
          ) : null}
          {!feeds?.data?.length ? (
            <div className={("mb-3 px-3", styles.postcard)}>
              <div className="p-4 text-center w-100">
                <h5 className="text-black-50">No feeds available</h5>
              </div>
            </div>
          ) : null}
          {globalToken && feeds?.data?.length
            ? feeds?.data?.map((feed, index) => (
                <>
                  <FeedCard
                    key={"feed_card" + index}
                    apiToken={globalToken}
                    user={user}
                    likeUnlike={onLikeDislikeHandler}
                    feed={feed}
                    _allFeed={_allFeed}
                    setModalOpen={setModalOpen}
                    modalOpen={modalOpen}
                    hideReadMore={true}
                    goToFeedDetail={goToFeedDetail}
                    feedRefreshMain={_allFeed}
                    _favoriteFeed={_favoriteFeed}
                    favBtnLoader={favBtnLoader}
                    myFav={myFav}
                    emailVerifiedStatus={emailVerifiedStatus}
                  />
                  {index % 4 === 0 ? (
                    <div key={"feed" + index}>
                      <RecentVideosSection />
                      <div
                        className={
                          ("mb-3 px-3 overflow-hidden", styles.postcard)
                        }
                        style={{ paddingBottom: 20 }}
                      >
                        <div className="wetstory-heading d-flex p-3 align-items-center gap-2">
                          <span className="text-bold">
                            <strong>Suggested Libraries</strong>
                          </span>
                        </div>
                        <Libraries />
                      </div>
                    </div>
                  ) : null}
                </>
              ))
            : null}
        </InfiniteScroll>
      ) : null}
      {feeds && feeds?.data?.length <= 0 ? (
        <div
          style={{ padding: 20, textAlign: "center" }}
          className={("poscard ", styles.postcard)}
        >
          {" "}
          <Nodata />
        </div>
      ) : null}

      <AdminNotifications />
    </>
  );
}

export default Feeds;
