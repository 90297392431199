import React from 'react'
import {
    Chart as ChartJS,
    ArcElement,
    Tooltip,
    Legend,
    CategoryScale,
    LinearScale,
    BarElement,
  } from "chart.js";
  import ChartDataLabels from 'chartjs-plugin-datalabels';
  import { Doughnut, Bar } from "react-chartjs-2";
// import { getOverviewData } from '../../../api/feeds/GetData';
import { useState } from 'react';
import { useEffect } from 'react';
import { Loadingicon } from '../../../AppIcons';
import { getOverviewData } from '../../../api/dashboard';
import { toast } from 'react-toastify';

ChartJS.register( Tooltip, Legend, ArcElement, CategoryScale, LinearScale,ChartDataLabels); // Register the plugin
const DashboardOld = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [customRange, setCustomRange] = useState({ start: "", end: "" });

  const handleOptionChange = async (e) => {
    setLoading(true);
    const value = e.target.value;
    setSelectedOption(value);
    // If custom range is selected, do not immediately send the query
    if (value === "custom") {
      setLoading(false);
      return
    } else {
    };
    const queryMap = {
      today: "today",
      yesterday: "yesterday",
      "past-7-days": "week",
      "past-30-days": "thirty-days",
      "past-60-days": "sixty-days",
      "past-90-days": "three-months",
      "past-6-months": "six-months",
      "past-12-months": "twelve-months",
      "this-month": "this-month",
      "past-month": "past-month",
    };
    const query = `${queryMap[value]}`;
    const response = await getOverviewData({query});
    setData(response.data);
    setLoading(false);
  };
  const handleCustomRangeSubmit = async () => {
    if (customRange.start && customRange.end) {
      setLoading(true);
      const response = await getOverviewData({startDate : customRange.start, endDate : customRange.end});
    setData(response.data);
    setLoading(false);
    } else {
      toast.error("Please select both start and end dates.");
    }
  };
  const handleClearFilter = () => {
    setCustomRange({ start: "", end: "" });
    setSelectedOption("");
    fetchOverviewData();
  }
  
  const fetchOverviewData = async () => {
    setLoading(true); 
    const response = await getOverviewData({query : "today"});
    setData(response.data);
    setLoading(false);
  }
  useEffect(() => {
    fetchOverviewData();
  }, []);
  const doughnutData = {
    labels: [
      "Questions", "IQ Questions", "FullForms", "Exams", "Dictionary", "Quizzes", 
      "Career News", "Career News Hindi", "Feeds", "Gigs", "Quotes", "Lyrics", 
      "Videos", "Articles", "Forum", "Tutorial", "Educators", "Corporates", 
      "Users", "Push Subscribers", "Email Subscribers"
    ],
    datasets: [
      {
        data: [
          data?.qb, 
          data?.iq, 
          data?.ff, 
          data?.exams,
          data?.dictionary, 
          data?.quiz, 
          data?.careernewsCount,
          data?.careernewsHindiCount, 
          data?.feedsCount,
          data?.gigs,
          data?.quotes,
          data?.lyrics,
          data?.videos,
          data?.articles,
          data?.forum,
          data?.tutorial, 
          data?.educatorsCount,
          data?.corporatesCount,
          data?.usersCount,
          data?.pushSubscribersCount,
          data?.emailSubscribersCount
        ],
        backgroundColor: [
          "#C4C4C4", "#28a745", "#FF5C5C", "#FF9E2C", "#4A90E2", "#A52A2A", 
          "#FF4500", "#FFD700", "#FF6347", "#00BFFF", "#8A2BE2", "#20B2AA", 
          "#FF1493", "#FF69B4", "#DA70D6", "#9370DB", "#FFA07A", "#6A5ACD", 
          "#FFB6C1", "#48D1CC", "#8B0000"
        ],
        hoverBackgroundColor: [
          "#C4C4C4", "#28a745", "#FF5C5C", "#FF9E2C", "#4A90E2", "#A52A2A", 
          "#FF4500", "#FFD700", "#FF6347", "#00BFFF", "#8A2BE2", "#20B2AA", 
          "#FF1493", "#FF69B4", "#DA70D6", "#9370DB", "#FFA07A", "#6A5ACD", 
          "#FFB6C1", "#48D1CC", "#8B0000"
        ],
      },
    ],
  };
  
  const barData = {
    labels: ["Questions", "IQ Questions", "FullForms", "Exams", "Dictionary", "Quizzes", "Career News", "Career News Hindi","Feeds", 
      "Gigs", "Quotes", "Lyrics", "Videos", "Articles", "Forum", "Tutorial", "Educators","Corporates", "Users", "Push Subscribers", 
      "Email Subscribers" ],
    datasets: [
      {
        label: "Content Count",
        data: [
          data?.qb, 
          data?.iq, 
          data?.ff, 
          data?.exams,
          data?.dictionary, 
          data?.quiz, 
          data?.careernewsCount,
          data?.careernewsHindiCount, 
          data?.feedsCount,
          data?.gigs,
          data?.quotes,
          data?.lyrics,
          data?.videos,
          data?.articles,
          data?.forum,
          data?.tutorial, 
          data?.educatorsCount,
          data?.corporatesCount,
          data?.usersCount,
          data?.pushSubscribersCount,
          data?.emailSubscribersCount
        ],
        backgroundColor: [
          "#C4C4C4", "#28a745", "#FF5C5C", "#FF9E2C", "#4A90E2", "#A52A2A", 
          "#FF4500", "#FFD700", "#FF6347", "#00BFFF", "#8A2BE2", "#20B2AA", 
          "#FF1493", "#FF69B4", "#DA70D6", "#9370DB", "#FFA07A", "#6A5ACD", 
          "#FFB6C1", "#48D1CC", "#8B0000"
        ] 
      },
    ],
  };
  return (
    <>
    {loading ? <div className="w-full flex items-center justify-center mt-20 mb-20 text-themecolor min-h-[400px]">
            <div className="h-10 w-10">
              <Loadingicon />
            </div>
          </div> : <div className="mb-3">
                <div
                  className="card"
                  style={{
                    padding: "20px",
                    boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
                    width: "100%",
                  }}
                >
                  {/* Heading */}
                  {/* <h4 className="text-center">Overall Platform Content</h4>
<hr/> */}
<div
    style={{
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    }}
  >
    {/* Platform Overview Text */}
    <h4 style={{ margin: 0 }}>Platform Overview</h4>
    {/* Filter Dropdown */}
    <select
        style={{
          padding: "10px",
          borderRadius: "5px",
          border: "1px solid #ccc",
          background: "#f9f9f9",
          fontSize: "16px",
          width: "200px",
        }}
        value={selectedOption}
        onChange={handleOptionChange}
      >
        <option value="today">Today</option>
        <option value="yesterday">Yesterday</option>
        <option value="past-7-days">Past 7 Days</option>
        <option value="past-30-days">Past 30 Days</option>
        <option value="past-60-days">Past 60 Days</option>
        <option value="past-90-days">Past 90 Days</option>
        <option value="past-6-months">Past 6 Months</option>
        <option value="past-12-months">Past 12 Months</option>
        <option value="this-month">This Month</option>
        <option value="past-month">Past Month</option>
        <option value="custom">Custom Range</option>
      </select>
      {selectedOption === "custom" && (
  <div
    style={{
      marginTop: "15px",
      padding: "10px",
      border: "1px solid #ddd",
      borderRadius: "5px",
      backgroundColor: "#f9f9f9",
      display: "flex",
      alignItems: "center",
      gap: "15px",
    }}
  >
    <div>
      <label style={{ display: "block", fontWeight: "bold", marginBottom: "5px" }}>
        Start Date:
      </label>
      <input
        type="date"
        value={customRange.start}
        style={{
          padding: "8px",
          border: "1px solid #ccc",
          borderRadius: "5px",
          width: "150px",
        }}
        onChange={(e) =>
          setCustomRange({ ...customRange, start: e.target.value })
        }
      />
    </div>
    <div>
      <label style={{ display: "block", fontWeight: "bold", marginBottom: "5px" }}>
        End Date:
      </label>
      <input
        type="date"
        value={customRange.end}
        style={{
          padding: "8px",
          border: "1px solid #ccc",
          borderRadius: "5px",
          width: "150px",
        }}
        onChange={(e) =>
          setCustomRange({ ...customRange, end: e.target.value })
        }
      />
    </div>
    <div style={{ display: "flex", gap: "10px" }}>
      <button
       className="btn btn-primary btn-sm me-2"
        style={{
          padding: "8px 12px",
          borderRadius: "5px",
          border: "none",
          cursor: "pointer",
        }}
        onClick={handleCustomRangeSubmit}
      >
        Apply
      </button>
      <button
      className="btn btn-outline-secondary btn-sm"
        style={{
          padding: "8px 12px",
          borderRadius: "5px",
          cursor: "pointer",
        }}
        onClick={handleClearFilter}
      >
        Clear
      </button>
    </div>
  </div>
)}
  </div>
  <hr/>
                  {/* Doughnut Chart */}
                  <div style={{ marginBottom: "20px", height: "250px" }}>
                    <Doughnut
                      data={doughnutData}
                      options={{
                        plugins: {
                          legend: {
                            position: "right",
                            labels: {
                              boxWidth: 28,
                              padding: 18,
                            },
                          },
                          tooltip: {
                            enabled: true, // Disable tooltips
                          },
                          datalabels: {
                            display: false, // Disable data labels inside the chart
                          },
                        },
                        maintainAspectRatio: false,
                        responsive: true,
                      }}
                    />
                  </div>
<hr/>
                  {/* Bar Chart */}
                  <div style={{ height: "250px" }}>
  <Bar
    data={barData}
    options={{
      plugins: {
        legend: {
          display: false, // Hide the legend for the Bar chart
        },
        tooltip: {
          callbacks: {
            label: (context) =>
              `${context.label}: ${context.raw.toLocaleString()}`, // Format tooltips
          },
        },
        datalabels: {
          display: true, // Show data labels
          formatter: (value) => {
            if (value < 1000) {
              return value.toLocaleString(); // Show actual value if below 1000
            } else {
              return `${(value / 1000).toFixed(0)}k`; // Show "k" for values above 1000
            }
          },
          font: {
            size: 12,
            weight: "bold",
          },
          color: "#000", // Text color for data labels
          anchor: "end", // Position the data label at the end of the bar
          align: "end",  // Align label with the end of the bar
          offset: 10,    // Offset to ensure label doesn't overlap with bar
        },
      },
      scales: {
        y: {
          beginAtZero: true,
          ticks: {
            // Dynamically set max value to ensure visibility of the largest bar
            max: Math.max(...barData.datasets[0].data) + 50000, // Ensure some padding at the top
            stepSize: 20000, // Adjust the step size for clarity
            precision: 0,    // Ensure tick values are displayed without decimals
          },
          grid: {
            offset: true, // Allow space for labels at the top
          },
        },
      },
      maintainAspectRatio: false,
      responsive: true,
      layout: {
        padding: {
          top: 40, // Add extra padding on top to give space for the largest value
        },
      },
    }}
  />
</div>
                </div>
              </div>}
    
              </>
  )
}
export default DashboardOld