import React from 'react';
import Select from 'react-select';

function CustomSelect({ options, handleCustomSelectOnChange, defaultOption, isSelectLoading, type, placeholder, isClearable,  setFieldValue = () => {}, isMultiple=false }) {
    // console.log(defaultOption)

    const formatOptionLabel = ({ label, flag }) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <img src={flag} alt="" style={{ width: 20, height: 20, marginRight: 10 }} />
            {label}
        </div>
    );

    return (
        <Select
            options={options}
            className='bg-gray-50'
            onChange={(selectedOption) => handleCustomSelectOnChange(isMultiple?selectedOption:selectedOption?.value, type,selectedOption?.label, setFieldValue)}
            isSearchable={true} 
            isLoading={isSelectLoading}
            isClearable={isClearable}
            defaultValue={defaultOption}
            // value={defaultOption}
            placeholder={placeholder}
            // name={name}
            isMulti={isMultiple}
            formatOptionLabel={type=='country'?formatOptionLabel:null}
        />
    )
}

export default CustomSelect;
