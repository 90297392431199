import React from 'react'
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
} from "chart.js";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { Doughnut, Bar, Line } from "react-chartjs-2";
import { useState } from 'react';
import { useEffect } from 'react';
import { Loadingicon } from '../../../AppIcons';
import { getOverviewData } from '../../../api/dashboard';
import { toast } from 'react-toastify';
import { DateRangePicker } from 'rsuite';
import 'rsuite/DateRangePicker/styles/index.css';
import trophy from '../../../assets/images/trophy.gif';
import dGraph from '../../../assets/images/d-graph.webp';
import uGraph from '../../../assets/images/u-graph.webp';

import Chart from "react-apexcharts";

ChartJS.register(Tooltip, Legend, ArcElement, CategoryScale, PointElement, LineElement, LinearScale, ChartDataLabels); // Register the plugin


const CustomDropDown = ({setSelectedModules, selectedModules}) => { 

  const options = ["Learn", "Work", "Earn", "Community"];

  const handleCheckboxChange = (option) => {
    
    if (selectedModules.includes(option)) {
      setSelectedModules(selectedModules.filter((item) => item !== option));
    } else {
      setSelectedModules([...selectedModules, option]);
    }
  };

  return (
    <div className="dropdown">
      <button
        className="btn btn-default dropdown-toggle border rounded"
        type="button"
        id="dropdownMenuButton"
        data-bs-toggle="dropdown"
        aria-expanded="false"
        style={{fontSize:14}}
      >
        {selectedModules.length > 0
          ? `Selected (${selectedModules.length})`
          : "Select Options"}
      </button>
      <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
        {options.map((option) => (
          <li key={option} className="dropdown-item flex items-center">
            <label className="form-check-label flex items-center text-black  py-2" style={{fontSize:14}}>
              <input
                type="checkbox"
                className="form-check-input me-2"
                value={option}
                checked={selectedModules.includes(option)}
                onChange={() => handleCheckboxChange(option)}
              />
              {option}
            </label>
          </li>
        ))}
      </ul>
    </div>
  );
};

const FinanceReportChart = ({selectedOptions}) => {
 
    
  const [filter, setFilter] = useState("today");
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [
      {
        label: "Credits Earned",
        data: [],
        borderColor: "#36A2EB",
        backgroundColor: "rgba(54, 162, 235, 0.2)",
        fill: true,
        tension: 0.4,
      },
      {
        label: "Credits Spent",
        data: [],
        borderColor: "#FF6384",
        backgroundColor: "rgba(255, 99, 132, 0.2)",
        fill: true,
        tension: 0.4,
      }
      ,
      {
        label: "Credits Purchased",
        data: [],
        borderColor: "#4BC0C0",
        backgroundColor: "rgba(75, 192, 192, 0.2)",
        fill: true,
        tension: 0.4,
      },
      {
        label: "Withdrawals",
        data: [],
        borderColor: "#FFCE56",
        backgroundColor: "rgba(255, 206, 86, 0.2)",
        fill: true,
        tension: 0.4,
      },
    ],
  });

  const generateMonthlyLabels = (months = 1, startMonthOffset = 0) => {
    const labels = [];
    const now = new Date();
    for (let i = 0; i < months; i++) {
      const date = new Date(now.getFullYear(), now.getMonth() - startMonthOffset - i);
      labels.unshift(date.toLocaleString("default", { month: "short", year: "numeric" }));
    }
    return labels;
  };

  const fetchData = (filter) => {
    let labels = [];
    let data;

    switch (filter) {
      case "today":
        labels = Array.from({ length: 24 }, (_, i) => `${i}:00`);
        data = {
          earned: Array(24).fill(0).map(() => Math.floor(Math.random() * 100)),
          spent: Array(24).fill(0).map(() => Math.floor(Math.random() * 50)),
        };
        break;

      case "yesterday":
        labels = Array.from({ length: 24 }, (_, i) => `${i}:00`);
        data = {
          earned: Array(24).fill(0).map(() => Math.floor(Math.random() * 100)),
          spent: Array(24).fill(0).map(() => Math.floor(Math.random() * 50)),
        };
        break;

      case "this_month":
        labels = Array.from({ length: 31 }, (_, i) => `Day ${i + 1}`);
        data = {
          earned: Array(31).fill(0).map(() => Math.floor(Math.random() * 500)),
          spent: Array(31).fill(0).map(() => Math.floor(Math.random() * 400)),
        };
        break;

      case "last_month":
        labels = Array.from({ length: 30 }, (_, i) => `Day ${i + 1}`);
        data = {
          earned: Array(30).fill(0).map(() => Math.floor(Math.random() * 500)),
          spent: Array(30).fill(0).map(() => Math.floor(Math.random() * 400)),
        };
        break;

      case "last_3_months":
        labels = generateMonthlyLabels(3);
        data = {
          earned: Array(3).fill(0).map(() => Math.floor(Math.random() * 1000)),
          spent: Array(3).fill(0).map(() => Math.floor(Math.random() * 800)),
        };
        break;

      case "last_6_months":
        labels = generateMonthlyLabels(6);
        data = {
          earned: Array(6).fill(0).map(() => Math.floor(Math.random() * 1500)),
          spent: Array(6).fill(0).map(() => Math.floor(Math.random() * 1200)),
        };
        break;

      case "this_year":
        labels = generateMonthlyLabels(12);
        data = {
          earned: Array(12).fill(0).map(() => Math.floor(Math.random() * 2000)),
          spent: Array(12).fill(0).map(() => Math.floor(Math.random() * 1800)),
        };
        break;

      case "previous_year":
        labels = generateMonthlyLabels(12, 12);
        data = {
          earned: Array(12).fill(0).map(() => Math.floor(Math.random() * 2000)),
          spent: Array(12).fill(0).map(() => Math.floor(Math.random() * 1800)),
        };
        break;

      default:
        labels = [];
        data = { earned: [], spent: [] };
    } 

    setChartData({
      labels: labels,
      datasets: selectedOptions=='cevscs'?[
        { ...chartData.datasets[0], data: data.earned },
        { ...chartData.datasets[1], data: data.spent }
      ]:[
        { ...chartData.datasets[2], data: data.earned.map((d) => d / 2) },
        { ...chartData.datasets[3], data: data.spent.map((d) => d / 2) },
      ],
    });
  };

  useEffect(() => {
    fetchData(filter);
  }, [filter, selectedOptions]);

  return (
    <div>
      
      <Line
        data={chartData}
        options={{
          responsive: true,
          plugins: { legend: { position: "top" } },
          scales: {
            x: { title: { display: true, text: "Date or Time" }, grid: { display: true } },
            y: { title: { display: true, text: "Credits" }, beginAtZero: true, grid: { display: true } },
          },
        }}
      />
    </div>
  );
};

const EngagementDonutChart = () => {
  // Data for the Donut Chart
  const chartData = {
    labels: ["Likes", "Comments", "Shares", "Views"], // Labels for each section
    datasets: [
      {
        data: [150, 75, 50, 100], // Engagement values for each section
        backgroundColor: [
          "#FF9F40", // Soft orange
          "#FFCD56", // Soft yellow
          "#36A2EB", // Soft blue
          "#4BC0C0"
        ],
        hoverBackgroundColor: [
          "#FF7F3F", // Slightly darker orange
          "#FFB344", // Slightly darker yellow
          "#2F7FB9", // Darker blue
          "#36A19B"
        ]
      },
    ],
  };

  // Options to make the chart look like a donut
  const options = {
    responsive: true,
    plugins: {
      tooltip: {
        callbacks: {
          label: function (context) {
            // Format the tooltip to show the number and percentage
            let total = 0;
            context.chart.data.datasets[0].data.forEach((value) => {
              total += value;
            });
            let percentage = ((context.raw / total) * 100).toFixed(1);
            return `${context.label}: ${context.raw} (${percentage}%)`;
          },
        },
      },
      legend: {
        position: "bottom", // Position of the legend
      }
    },
  };
  const totalEngagement = [150, 75, 50, 100].reduce((a, b) => a + b, 0);
  const percentages = [150, 75, 50, 100].map(
    (value) => ((value / totalEngagement) * 100).toFixed(1) // Calculate percentage for each segment
  );

  return (
    <div style={{ width: "280px", height: "280px", textAlign: 'center', position: 'relative', margin: '0 auto' }}>
      <Doughnut data={chartData} options={options} />
      <div
        style={{
          position: 'absolute',
          top: '41%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          color: '#333', // Make sure the text inside the donut is white
          fontSize: '20px',
          fontWeight: 'bold',
        }}
      >
        {totalEngagement}
      </div>
    </div>
  );
};

const VerticalBarChart = () => {
  const moduleData = [
    { module: "Question Bank", likes: 224, comments: 120, shares: 80, views: 400 },
    { module: "Interview Questions", likes: 180, comments: 90, shares: 60, views: 300 },
    { module: "FullForms", likes: 150, comments: 70, shares: 50, views: 250 },
    { module: "Exams", likes: 200, comments: 110, shares: 90, views: 350 },
    { module: "Dictionary", likes: 140, comments: 50, shares: 40, views: 200 },
    { module: "Quizzes", likes: 160, comments: 85, shares: 55, views: 270 },
    { module: "Career News", likes: 190, comments: 100, shares: 70, views: 330 },
    { module: "Career News Hindi", likes: 210, comments: 115, shares: 75, views: 370 },
    { module: "Feeds", likes: 230, comments: 125, shares: 85, views: 390 },
    { module: "Gigs", likes: 140, comments: 50, shares: 40, views: 200 },
    { module: "Quotes", likes: 120, comments: 40, shares: 30, views: 170 },
    { module: "Lyrics", likes: 130, comments: 45, shares: 35, views: 180 },
    { module: "Videos", likes: 220, comments: 130, shares: 90, views: 410 },
    { module: "Articles", likes: 190, comments: 100, shares: 60, views: 340 },
    { module: "Forum", likes: 160, comments: 85, shares: 55, views: 270 },
    { module: "Tutorials", likes: 240, comments: 135, shares: 95, views: 420 },
    { module: "Educators", likes: 180, comments: 90, shares: 60, views: 300 },
    { module: "Corporates", likes: 150, comments: 70, shares: 50, views: 250 },
  ];

  const labels = moduleData.map((item) => item.module);
  const totalEngagementCounts = moduleData.map(
    (item) => item.likes + item.comments + item.shares + item.views
  );

  const chartData = {
    labels, // X-axis labels (module names)
    datasets: [
      {
        label: "",
        data: totalEngagementCounts, // Y-axis data
        backgroundColor: [
          "#FF6384", // Light Red
          "#36A2EB", // Light Blue
          "#FFCE56", // Light Yellow
          "#4BC0C0", // Teal
          "#9966FF", // Light Purple
          "#FF9F40", // Light Orange
          "#FF6384", // Light Red
          "#36A2EB", // Light Blue
          "#FFCE56", // Light Yellow
          "#4BC0C0", // Teal
          "#9966FF", // Light Purple
          "#FF9F40", // Light Orange
          "#FF6384", // Light Red
          "#36A2EB", // Light Blue
          "#FFCE56", // Light Yellow
          "#4BC0C0", // Teal
          "#9966FF", // Light Purple
          "#FF9F40", // Light Orange
        ], // Array of smooth colors for bars
        borderColor: [
          "#FF6384",
          "#36A2EB",
          "#FFCE56",
          "#4BC0C0",
          "#9966FF",
          "#FF9F40",
          "#FF6384",
          "#36A2EB",
          "#FFCE56",
          "#4BC0C0",
          "#9966FF",
          "#FF9F40",
          "#FF6384",
          "#36A2EB",
          "#FFCE56",
          "#4BC0C0",
          "#9966FF",
          "#FF9F40",
        ], // Optional: Match border color to bar color
        borderWidth: 0,
        datalabels: {
          display: false,
        },
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false, // Single dataset, no legend needed
      },
      tooltip: {
        callbacks: {
          label: (context) =>
            `${context.raw.toLocaleString()} Total Engagements`,
        },
      },
    },
    scales: {
      x: {
        title: {
          display: false,
          text: "Modules",
          font: {
            size: 14,
          },
        },
        ticks: {
          font: {
            size: 12,
          },
        },
      },
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: "Total Engagement Count",
          font: {
            size: 14,
          },
        },
        ticks: {
          stepSize: 50, // Adjust step size for readability
        },
      },
    },
  };

  return (
    <Bar data={chartData} options={options} />
  );
};

const NewUserDashboard = () => {
  const [data, setData] = useState(null);
  const [selectedOptions, setSelectedOptions] = useState("cevscs");
  const [selectedModules, setSelectedModules] = useState(["Learn", "Work", "Earn", "Community"]);
  const [loading, setLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [customRange, setCustomRange] = useState({ start: "", end: "" });
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [isChartLoaded, setChartLoaded] = useState(false);

  useEffect(() => {
    setChartLoaded(true);
  }, []);

  const earnCreditVsSpentCredits = [
    {
      name: "Earned Credits",
      data: [170, 420, 300, 550, 550, 650, 820],
    },
    {
      name: "Spent Credits",
      data: [320, 300, 650, 400, 750, 650, 600],
    }
  ];
  const creditPurchasedVsWithdrawals = [
    {
      name: "Credits Purchased",
      data: [100, 120, 305, 600, 500, 450, 800],
    },
    {
      name: "Withdrawals",
      data: [420, 280, 600, 350, 690, 650, 500],
    }
  ];

  const creditCamparisonsOptions = {
    chart: {
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    colors: ["#4936F5", "#EC1F00"],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: [2, 2],
    },
    grid: {
      show: true,
      borderColor: "#ECEEF2",
      strokeDashArray: 10,
      xaxis: {
        lines: {
          show: true,
        },
      },
    },
    fill: {
      type: "gradient",
      gradient: {
        stops: [0, 90, 100],
        shadeIntensity: 1,
        opacityFrom: 0,
        opacityTo: 0.5,
      },
    },
    xaxis: {
      categories: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
      axisTicks: {
        show: false,
        color: "#ECEEF2",
      },
      axisBorder: {
        show: true,
        color: "#D5D9E2",
      },
      labels: {
        show: true,
        style: {
          colors: "#8695AA",
          fontSize: "12px",
        },
      },
    },
    yaxis: {
      tickAmount: 5,
      max: 1000,
      min: 0,
      labels: {
        style: {
          colors: "#64748B",
          fontSize: "12px",
        },
      },
      axisBorder: {
        show: true,
        color: "#D5D9E2",
      },
      axisTicks: {
        show: false,
        color: "#ECEEF2",
      },
    },
    legend: {
      show: true,
      position: "top",
      fontSize: "12px",
      horizontalAlign: "left",
      itemMargin: {
        horizontal: 8,
        vertical: 0,
      },
      labels: {
        colors: "#64748B",
      },
      markers: {
        size: 7,
        offsetX: -2,
        offsetY: -0.5,
        shape: "diamond",
      },
    },
  };

  const sectionSeries = [1275, 825, 450, 650];
  const creditEarnedSeries = [1275, 825, 450, 650];
  const creditSentSeries = [1275, 825, 450, 650];
  const moduleSeries = [23, 18, 91, 100, 40, 120, 200, 50, 33];
  const popularitySeries = [23, 18, 91, 100];


  const earnSpentCredits = {
    labels: ["Learn", "Work", "Earn", "Community"],
    colors: [
      "#99BFDF", "#BA85DF", "#E58189", "#E6B172"
    ],
    stroke: {
      width: 1,
      show: true,
      colors: ["#ffffff"],
    },
    legend: {
      show: false,
      position: "top",
      fontSize: "12px",
      horizontalAlign: "center",
      itemMargin: {
        horizontal: 8,
        vertical: 0,
      },
      labels: {
        colors: "#64748B",
      },
      markers: {
        size: 7,
        offsetX: -2,
        offsetY: -0.5,
        shape: "diamond",
      },
    },
    plotOptions: {
      pie: {
        expandOnClick: false,
        donut: {
          labels: {
            show: false,
            name: {
              color: "#64748B",
            },
            value: {
              show: true,
              color: "#3A4252",
              fontSize: "22px",
              fontWeight: "600",
            },
            total: {
              show: true,
              color: "#64748B",
            },
          },
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    tooltip: {
      enabled: true,
    },
  };

  const popularityByEngagement = {
    labels: ["Likes", "Comments", "Shares", "Views"],
    colors: [
      "#99BFDF", "#BA85DF", "#E58189", "#E6B172"
    ],
    stroke: {
      width: 1,
      show: true,
      colors: ["#ffffff"],
    },
    legend: {
      show: false,
      position: "top",
      fontSize: "12px",
      horizontalAlign: "center",
      itemMargin: {
        horizontal: 8,
        vertical: 0,
      },
      labels: {
        colors: "#64748B",
      },
      markers: {
        size: 7,
        offsetX: -2,
        offsetY: -0.5,
        shape: "diamond",
      },
    },
    plotOptions: {
      pie: {
        expandOnClick: false,
        donut: {
          labels: {
            show: false,
            name: {
              color: "#64748B",
            },
            value: {
              show: true,
              color: "#3A4252",
              fontSize: "22px",
              fontWeight: "600",
            },
            total: {
              show: true,
              color: "#64748B",
            },
          },
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    tooltip: {
      enabled: true,
    },
  };
  const activitybyModules = {
    labels: ["Questions In Question Bank", "Fullforms", "Meanings In Dictionary", "Courses", "Tutorials", "Educator", "Tutors In Tutoring", "Exams", "Blogs"],
    colors: [
      "#E598A6", "#E6C58F", "#E6D28A", "#94CFC7",
      "#99BFDF", "#BA85DF", "#E58189", "#E6B172", "#E6B882",
    ],
    stroke: {
      width: 1,
      show: true,
      colors: ["#ffffff"],
    },
    legend: {
      show: false,
      position: "top",
      fontSize: "12px",
      horizontalAlign: "center",
      itemMargin: {
        horizontal: 8,
        vertical: 0,
      },
      labels: {
        colors: "#64748B",
      },
      markers: {
        size: 7,
        offsetX: -2,
        offsetY: -0.5,
        shape: "diamond",
      },
    },
    plotOptions: {
      pie: {
        expandOnClick: false,
        donut: {
          labels: {
            show: false,
            name: {
              color: "#64748B",
            },
            value: {
              show: true,
              color: "#3A4252",
              fontSize: "22px",
              fontWeight: "600",
            },
            total: {
              show: true,
              color: "#64748B",
            },
          },
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    tooltip: {
      enabled: true,
    },
  };

  const activitybySections = {
    labels: ["Learn Activities", "Work Activities", "Earn Activities", "Community Activities"],
    colors: ["#4A90E2", "#A680FF", "#5CD859", "#F5A623"],
    stroke: {
      width: 1,
      show: true,
      colors: ["#ffffff"],
    },
    legend: {
      show: false,
      position: "top",
      fontSize: "12px",
      horizontalAlign: "center",
      itemMargin: {
        horizontal: 8,
        vertical: 0,
      },
      labels: {
        colors: "#64748B",
      },
      markers: {
        size: 7,
        offsetX: -2,
        offsetY: -0.5,
        shape: "diamond",
      },
    },
    plotOptions: {
      pie: {
        expandOnClick: false,
        donut: {
          labels: {
            show: false,
            name: {
              color: "#64748B",
            },
            value: {
              show: true,
              color: "#3A4252",
              fontSize: "22px",
              fontWeight: "600",
            },
            total: {
              show: true,
              color: "#64748B",
            },
          },
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    tooltip: {
      enabled: true,
    },
  };



  const learnModule = [
    {
      label:'Question Bank',
      value:'questionbank'
    },{
      label:'Full Forms',
      value:'fullforms'
    },{
      label:'Dictionary',
      value:'dictionary'
    },{
      label:'Courses',
      value:'courses'
    },{
      label:'Tutorials',
      value:'tutorials'
    },{
      label:'Educators',
      value:'educators'
    },{
      label:'Exams',
      value:'exams'
    },{
      label:'Blogs',
      value:'blogs'
    },{
      label:'Tutoring',
      value:'tutoring'
    }
  ]
  const workModule = [
    {
      label:'Corporates',
      value:'corporates'
    },{
      label:'Interview Questions',
      value:'interviewquestions'
    },{
      label:'Career News',
      value:'careernews'
    },{
      label:'Tools',
      value:'tools'
    },{
      label:'Forum',
      value:'forum'
    }
  ]
  const earnModule = [
    {
      label:'Gigs',
      value:'gigs'
    },{
      label:'Work',
      value:'work'
    }
  ]
  const communityModule = [
    {
      label:'Video',
      value:'video'
    },{
      label:'Lyrics',
      value:'lyrics'
    },{
      label:'Quotes',
      value:'quotes'
    },{
      label:'Feeds',
      value:'feeds'
    }
  ]
  const [moduleOptions, setModuleOptions] = useState([...learnModule, ...workModule, ...earnModule, ...communityModule]);

  useEffect(() => {
    // Dynamically update moduleOptions based on selectedModules
    const moduleMap = {
      Learn: learnModule,
      Work: workModule,
      Earn: earnModule,
      Community: communityModule,
    };

    const selectedOptions = selectedModules.reduce(
      (acc, key) => [...acc, ...moduleMap[key]],
      []
    );

    setModuleOptions(selectedOptions);
  }, [selectedModules]);

  const transactions = [
    {
      description: "Paypal payment for course",
      date: "14 Jun 2024 - 4:21 pm",
      amount: "3,560",
      type: "debit",
    },
    {
      description: "Stripe payment received",
      date: "13 Jun 2024 - 1:15 pm",
      amount: "7,200",
      type: "credit",
    },
    {
      description: "Bank transfer to vendor",
      date: "12 Jun 2024 - 10:30 am",
      amount: "1,500",
      type: "debit",
    },
    {
      description: "Payment refund received",
      date: "11 Jun 2024 - 8:45 pm",
      amount: "2,800",
      type: "credit",
    },
    {
      description: "Monthly subscription fee",
      date: "10 Jun 2024 - 5:10 pm",
      amount: "999",
      type: "debit",
    }
    // ,
    // {
    //   description: "Course purchase",
    //   date: "9 Jun 2024 - 11:00 am",
    //   amount: "-4,320",
    //   type: "debit",
    // },
    // {
    //   description: "Affiliate payout",
    //   date: "8 Jun 2024 - 6:20 pm",
    //   amount: "+3,450",
    //   type: "credit",
    // },
    // {
    //   description: "Donation received",
    //   date: "7 Jun 2024 - 9:10 am",
    //   amount: "+1,200",
    //   type: "credit",
    // },
    // {
    //   description: "Payment for eBook",
    //   date: "6 Jun 2024 - 3:50 pm",
    //   amount: "-500",
    //   type: "debit",
    // },
    // {
    //   description: "Stripe payout",
    //   date: "5 Jun 2024 - 2:00 pm",
    //   amount: "+5,000",
    //   type: "credit",
    // },
  ];

  const handleOptionChange = async (e) => {
    setLoading(true);
    const value = e.target.value;
    setSelectedOption(value);

    // If custom range is selected, do not immediately send the query
    if (value === "custom") {
      setLoading(false);
      return
    } else {
    };

    const queryMap = {
      today: "today",
      yesterday: "yesterday",
      "past-7-days": "week",
      "past-30-days": "thirty-days",
      "past-60-days": "sixty-days",
      "past-90-days": "three-months",
      "past-6-months": "six-months",
      "past-12-months": "twelve-months",
      "this-month": "this-month",
      "past-month": "past-month",
    };

    const query = `${queryMap[value]}`;
    const response = await getOverviewData({ query });
    setData(response.data);
    setLoading(false);
  };

  const handleCustomRangeSubmit = async () => {
    if (customRange.start && customRange.end) {
      setLoading(true);
      const response = await getOverviewData({ startDate: customRange.start, endDate: customRange.end });
      setData(response.data);
      setLoading(false);
    } else {
      toast.error("Please select both start and end dates.");
    }
  };
  const handleClearFilter = () => {
    setCustomRange({ start: "", end: "" });
    setSelectedOption("");
    fetchOverviewData();
  }

  const fetchOverviewData = async () => {
    setLoading(true);
    const response = await getOverviewData({ query: "today" });
    setData(response.data);
    setLoading(false);
  }
  useEffect(() => {
    fetchOverviewData();
  }, []);

  const doughnutData = {
    labels: [
      "Questions: 224(-20%)", "IQ Questions: 11(+20%)", "FullForms: 22(-20%)", "Exams: 120(1%)", "Dictionary: 24(-14%)", "Quizzes: 224(-20%)",
      "Career News: 1(3%)", "Career News Hindi: 224(-20%)", "Feeds: 110(11%)", "Gigs: 2(2%)", "Quotes: 46(12%)", "Lyrics: 20(20%)",
      "Videos: 224(-20%)", "Articles: 2(7%)", "Forum: 10(4%)", "Tutorial: 34(10%)", "Educators: 224(-20%)", "Corporates: 224(-20%)"
    ],
    datasets: [
      {
        data: [
          data?.qb || 224,  // Default value for Questions
          data?.iq || 11,  // Default value for IQ Questions
          data?.ff || 22,  // Default value for FullForms
          data?.exams || 120,  // Default value for Exams
          data?.dictionary || 24,  // Default value for Dictionary
          data?.quiz || 224,  // Default value for Quizzes
          data?.careernewsCount || 1,  // Default value for Career News
          data?.careernewsHindiCount || 224,  // Default value for Career News Hindi
          data?.feedsCount || 110,  // Default value for Feeds
          data?.gigs || 2,  // Default value for Gigs
          data?.quotes || 46,  // Default value for Quotes
          data?.lyrics || 20,  // Default value for Lyrics
          data?.videos || 224,  // Default value for Videos
          data?.articles || 2,  // Default value for Articles
          data?.forum || 10,  // Default value for Forum
          data?.tutorial || 34,  // Default value for Tutorial
          data?.educatorsCount || 224,  // Default value for Educators
          data?.corporatesCount || 224  // Default value for Corporates
        ],
        backgroundColor: [
          "#FFB3C1", "#FFDAA5", "#FFEBA1", "#AEE8E3", "#B4D8FF", "#D5A6FF",
          "#FF9AA2", "#FFCE8A", "#FFD59E", "#A8E6CF", "#85E3FF", "#CBA1FF",
          "#FFA3A3", "#FFC3A0", "#FFE1A8", "#A3E4DB", "#92DFF3", "#C79FFF"
        ],
        hoverBackgroundColor: [
          "#FFB3C1", "#FFDAA5", "#FFEBA1", "#AEE8E3", "#B4D8FF", "#D5A6FF",
          "#FF9AA2", "#FFCE8A", "#FFD59E", "#A8E6CF", "#85E3FF", "#CBA1FF",
          "#FFA3A3", "#FFC3A0", "#FFE1A8", "#A3E4DB", "#92DFF3", "#C79FFF"
        ]
      },
    ],
  };


  return (
    <>
      {loading ? <div className="w-full flex items-center justify-center mt-20 mb-20 text-themecolor min-h-[400px]">
        <div className="h-10 w-10">
          <Loadingicon />
        </div>
      </div> : <>
      {/* <div className='d-flex w-100 items-center justify-between collapse-bar mt-4'>
          <div>
            <h4 className="mb-0" style={{color: '#3c4043'}}>Module Contribution</h4>
            <p className='color-gray mb-0'>Focuses on the user's contribution of content and how it is received.</p>
          </div>
          <i className='fa fa-angle-down cursor-pointer collapse-btn'></i>
        </div> */}
      <div className="mb-4 row">
        <div className='col-md-4'>
          <div
            className="card"
            style={{
              padding: "20px",
              boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
              width: "100%",
            }}
          >
            <div class="bell-icon">
              <i class="fa-regular fa-bell"></i>
            </div>
            <div className='row'>
              <div className='col-md-8'>
                <h3 style={{ marginTop: 20, fontSize: '1.125rem', color: '#3c4043' }}>Congratulations, <span className='theme-color'>Olivia!</span></h3>
                <small className='color-gray'>Top community builder</small>
                
              </div>
              <div className='col-md-4'>
                <img src={trophy} style={{ width: '80%' }} />
              </div>
            </div>
            <div className="d-flex items-center w-100 mt-3 justify-between mb-2">
              <label>Profile Completeness</label>
              <strong className='theme-color'>60%</strong>
            </div>
            <div class="progress" style={{height:6}}>
            <div class="progress-bar progress-bar-striped progress-bar-animated bg-warning" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" style={{width: '75%'}}></div>
            </div>
            <div className='d-flex w-100 gap-2 justify-between'>
                <button className='btn--light mt-3 btn justify-content-center px-md-3 px-2 d-flex align-items-center border-0 btn-primary w-100 btn-sm credit-btn'>View Profile</button>
                <button className='mt-3 btn--light  btn justify-content-center px-md-3 px-2 d-flex text-white align-items-center border-0 w-100 btn-sm offer-btn'>Complete Profile</button>
            </div>
            
            <div className='row mt-4'>
              {/* <hr style={{ borderColor: '#f0f0f0' }} /> */}
              <div className='col-md-4 my-0'>
                <div className='text-center d-flex flex-column align-items-center justify-center stats-my'>
                  <h3 style={{ margin: 0, fontSize: '1.125rem', fontWeight: 'bold' }}><a href="javascript:;" className="theme-color">1.5k</a></h3>
                  <small className='color-gray'>Feeds Posted</small>
                  <p className='m-0'><button className='small-btn small mt-2s'>Post Feed</button></p>
                </div>
              </div>
              <div className='col-md-4 my-0'>
                <div className='text-center d-flex flex-column align-items-center justify-center stats-my'>
                  <h3 className="theme-color" style={{ margin: 0, fontSize: '1.125rem', fontWeight: 'bold' }}><a href="javascript:;" className="theme-color">200</a></h3>
                  <small className='color-gray'>Videos Uploads</small>
                  <p className='m-0'><button className='small-btn small mt-2s'>Upload Video</button></p>
                </div>
              </div>
              <div className='col-md-4 my-0'>
                <div className='text-center d-flex flex-column align-items-center justify-center stats-my'>
                  <h3 className="theme-color" style={{ margin: 0, fontSize: '1.125rem', fontWeight: 'bold' }}><a href="javascript:;" className="theme-color">150</a></h3>
                  <small className='color-gray'>Courses Posted</small>
                  <p className='m-0'><button className='small-btn small mt-2s'>Post Course</button></p>
                </div>
              </div>
              <div className='col-md-4 my-0 pt-4'>
                <div className='text-center d-flex flex-column align-items-center justify-center stats-my'>
                  <h3 style={{ margin: 0, fontSize: '1.125rem', fontWeight: 'bold' }}><a href="javascript:;" className="theme-color">0</a></h3>
                  <small className='color-gray'>Forum Posted</small>
                  <p className='m-0'><button className='small-btn small mt-2s'>Post Question</button></p>
                </div>
              </div>
              <div className='col-md-4 my-0 pt-4'>
                <div className='text-center d-flex flex-column align-items-center justify-center stats-my'>
                  <h3 className="theme-color" style={{ margin: 0, fontSize: '1.125rem', fontWeight: 'bold' }}><a href="javascript:;" className="theme-color">10</a></h3>
                  <small className='color-gray'>Tutorial Posted</small>
                  <p className='m-0'><button className='small-btn small mt-2s'>Post Tutorial</button></p>
                </div>
              </div>
              <div className='col-md-4 my-0 pt-4'>
                <div className='text-center d-flex flex-column align-items-center justify-center stats-my'>
                  <h3 className="theme-color" style={{ margin: 0, fontSize: '1.125rem', fontWeight: 'bold' }}><a href="javascript:;" className="theme-color">150</a></h3>
                  <small className='color-gray'>Posted News</small>
                  <p className='m-0'><button className='small-btn small mt-2s'>Post News</button></p>
                </div>
              </div>
              <div className='col-md-4 pt-4'>
                <div className='text-center d-flex flex-column align-items-center justify-center stats-my'>
                  <h3 className="theme-color" style={{ margin: 0, fontSize: '1.125rem', fontWeight: 'bold' }}><a href="javascript:;" className="theme-color">43</a></h3>
                  <small className='color-gray'>Blogs Posted</small>
                  <p className='m-0'><button className='small-btn small mt-2s'>Post Blog</button></p>
                </div>
              </div>
              <div className='col-md-4 pt-4'>
                <div className='text-center d-flex flex-column align-items-center justify-center stats-my'>
                  <h3 className="theme-color" style={{ margin: 0, fontSize: '1.125rem', fontWeight: 'bold' }}><a href="javascript:;" className="theme-color">1</a></h3>
                  <small className='color-gray'>Projects Posted</small>
                  <p className='m-0'><button className='small-btn small mt-2s'>Post Project</button></p>
                </div>
              </div>
              <div className='col-md-4 pt-4'>
                <div className='text-center d-flex flex-column align-items-center justify-center stats-my'>
                  <h3 className="theme-color" style={{ margin: 0, fontSize: '1.125rem', fontWeight: 'bold' }}><a href="javascript:;" className="theme-color">199</a></h3>
                  <small className='color-gray'>My Campaigns</small>
                  <p className='m-0'><button className='small-btn small mt-2s'>Post Campaign</button></p>
                </div>
              </div>
            </div>
          </div>

          {/* <div
              className="card my-4"
              style={{
                padding: "20px",
                boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
                width: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <h2 style={{ margin: 0, fontSize: '1.125rem', color: '#3c4043' }}>Achievements</h2>

              </div>
              <ul class="timeline">
                <li>Completed 50 Quizzes</li>
                <li>Highest Engagement this Month</li>
              </ul>
            </div> */}
             {/* <div
              className="card my-4"
              style={{
                padding: "0px",
                boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
                width: "100%",
              }}
            >
               <fieldset className="p-3">
                    <legend>Why and How to Get Engaged
                      
                    </legend>
                    <p><strong>Why:</strong> Engaging with the platform helps you learn, grow, and stay connected with the community. Your contributions will increase visibility and help others learn too! <a href="#">Read More</a></p>
                    <p><strong>How:</strong> You can get involved by posting your thoughts, liking or commenting on content, and sharing with your network. The more you interact, the more rewards and recognition you will get. <a href="#">Read More</a></p>
                </fieldset>
            </div>  */}
            <div
              className="card mt-4"
              style={{
                padding: "20px",
                boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
                width: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                {/* Platform Overview Text */}
                <h2 style={{ margin: 0, fontSize: '1.125rem', color: '#3c4043' }}>Tour & Guide</h2>

              </div>
              <ul class="timeline mb-0">
                <li class="cta-item d-flex flex-column align-items-start w-100">
                  <strong>How to Earn Credits:</strong> 
                  <span>Watch this quick guide on earning credits through contributions and collaborations. <a href="/videos/earn-credits" class="cta-link mb-2">Watch Now</a></span>
                </li>
                <li class="cta-item d-flex flex-column align-items-start w-100">
                  <strong>Exam Preparation Tips:</strong> 
                  <span>Learn how to effectively prepare for exams with our expert tutorials. <a href="/videos/exam-preparation" class="cta-link mb-2">Watch Now</a></span>
                </li>
                <li class="cta-item d-flex flex-column align-items-start w-100">
                  <strong>Maximizing Profile Visibility:</strong> 
                  <span>See how updating your profile helps you stand out. <a href="/videos/profile-visibility" class="cta-link mb-2">Watch Now</a></span>
                </li>
                <li class="cta-item d-flex flex-column align-items-start w-100">
                  <strong>Creating Engaging Articles:</strong> 
                  <span>Discover tips on writing articles that attract readers. <a href="/videos/article-tips" class="cta-link mb-2">Watch Now</a></span>
                </li>
                <li class="cta-item d-flex flex-column align-items-start w-100">
                  <strong>Finding the Right Opportunities:</strong> 
                  <span>Learn how to locate and apply for the best opportunities. <a href="/videos/job-opportunities" class="cta-link mb-2">Watch Now</a></span>
                </li>
                <li class="cta-item d-flex flex-column align-items-start w-100">
                  <strong>Interactive Quiz Guide:</strong> 
                  <span>Understand how to participate in quizzes and track your progress. <a href="/videos/quiz-guide" class="cta-link mb-2">Watch Now</a></span>
                </li>
                <li class="cta-item d-flex flex-column align-items-start w-100">
                <strong>Learn How to Spent Credits:</strong>
                <span>Explore different ways to spent credits by contributing, engaging, and participating in activities. <a href="/videos/earn-credits-guide" class="cta-link mb-2">Watch Now</a></span>
              </li>
              </ul>

              <hr style={{ borderColor: '#f0f0f0' }} className='mt-0' />
              <a href="" className='theme-color'>View More</a>
            </div>
        </div>
        <div className='col-md-8'>
        <div
              className="card"
              style={{
                padding: "20px",
                boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
                width: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                {/* Platform Overview Text */}
                <h2 style={{ margin: 0, fontSize: '1.125rem', color: '#3c4043' }}>System Engagement Rate</h2>

                {/* Filter Dropdown */}
                <div
                  style={{
                    borderRadius: "5px",
                    display: "flex",
                    alignItems: "center",
                    gap: "5px",
                  }}
                >
                   <select
                    className='filter-select'
                  >
                    <option value="yesterday">Learn</option>
                    <option value="past-7-days">Work</option>
                    <option value="past-30-days">Earn</option>
                    <option value="past-60-days">Community</option>
                  </select>
                <select
                    className='filter-select'
                    value={selectedOption}
                    onChange={handleOptionChange}
                  >
                    <option value="all">All</option>
                    <option value="questionbank">Question Bank</option>
                    <option value="IQ Questions">Interview Questions</option>
                    <option value="FullForms">FullForms</option>
                    <option value="Exams">Exams</option>
                    <option value="Dictionary">Dictionary</option>
                    <option value="Quizzes">Quizzes</option>
                    <option value="Career News">Career News</option>
                    <option value="Career News Hindi">Career News Hindi</option>
                    <option value="Feeds">Feeds</option>
                    <option value="Gigs">Gigs</option>
                    <option value="Quotes">Quotes</option>
                    <option value="Lyrics">Lyrics</option>
                    <option value="Videos">Videos</option>
                    <option value="Articles">Articles</option>
                    <option value="Forum">Forum</option>
                    <option value="Tutorials">Tutorials</option>
                    <option value="Educators">Educators</option>
                    <option value="Corporates">Corporates</option>
                  </select>
                  <select className='filter-select' value={selectedOption}
                    onChange={handleOptionChange}>
                        <option value="today">Today</option>
                        <option value="today">Yesterday</option>
                        <option value="weekly">Weekly</option>
                        <option value="yesterday">Monthly</option>
                        <option value="this_month">Yearly</option>
                        <option value="last_month">All Time</option>
        
                  </select>
                </div>
              </div>
              <hr style={{ borderColor: '#f0f0f0' }} />
              <div className='row'>
                          <div className='col-md-8'>
                              <div className="flex my-3">
                                  <div className='transaction-left flex items-center flex-1'>
                                        <div className='thumb-trans me-2' style={{background: 'rgb(153 191 223 / 50%)',color: '#fafafa'}}>
                                          <i className='fa fa-thumbs-up'></i>
                                        </div>
                                        <div className='flex flex-col'>
                                            <p className='m-0'><strong>{moduleSeries[0]}</strong></p>
                                            <small className='color-gray'>Likes on Question Bank</small>
                                        </div>
                                    </div>
                                    <div className='transaction-left flex items-center flex-1'>
                                        <div className='thumb-trans me-2' style={{background: 'rgb(186 133 223 / 46%)',color: '#fafafa'}}>
                                          <i className='fa fa-comment'></i>
                                        </div>
                                        <div className='flex flex-col'>
                                            <p className='m-0'><strong>{moduleSeries[1]}</strong></p>
                                            <small className='color-gray'>Comments on Question Bank</small>
                                        </div>
                                    </div>
                              </div>
                              <div className="flex my-3">
                                  <div className='transaction-left flex items-center flex-1'>
                                        <div className='thumb-trans me-2' style={{background: 'rgb(229 129 137 / 46%)',color: '#fafafa'}}>
                                          <i className='fa fa-share-alt'></i>
                                        </div>
                                        <div className='flex flex-col'>
                                            <p className='m-0'><strong>{moduleSeries[2]}</strong></p>
                                            <small className='color-gray'>Shares on Question Bank</small>
                                        </div>
                                    </div>
                                    <div className='transaction-left flex items-center flex-1'>
                                        <div className='thumb-trans me-2' style={{background: 'rgb(230 177 114 / 46%)',color: '#fafafa'}}>
                                          <i className='fa fa-eye'></i>
                                        </div>
                                        <div className='flex flex-col'>
                                            <p className='m-0'><strong>{moduleSeries[3]}</strong></p>
                                            <small className='color-gray'>Views on Question Bank</small>
                                        </div>
                                    </div>
                              </div>
                              
                                                                
                          </div>
                             
                          <div className='col-md-4'>
                          {isChartLoaded && (
                            <Chart
                              options={popularityByEngagement}
                              series={popularitySeries}
                              type="donut"
                              height={140}
                              width={'100%'}
                            />
                          )}
                          </div>
              </div>
              {/* <hr style={{ borderColor: '#f0f0f0' }} /> */}
                      {/* <p><strong className='color-gray'>Recommended Modules Based on Popularity to Contribute</strong></p>                     */}
                     

                      <div className='row'>
              <div className='col'>
              <div
              className="card mt-4 text-center d-flex items-center justify-between"
              style={{
                padding: "20px",
                boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
                width: "100%",
              }}
            >
              <p className='text-orange-400 m-0'>40%</p>
              <small>Engagement Rate</small>
              </div>
              
              </div>
              <div className='col'>
              <div
              className="card mt-4 text-center d-flex items-center justify-between"
              style={{
                padding: "20px",
                boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
                width: "100%",
              }}
            >
              
              <p className='text-green-400 m-0'>Question Bank</p>
              <small>Popular Library</small>
              </div>
              
              </div>
              <div className='col'>
              <div
              className="card mt-4 text-center d-flex items-center justify-between"
              style={{
                padding: "20px",
                boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
                width: "100%",
              }}
            >
              <p className='text-purple-400 m-0'>3K</p>

              <small>Traffic Per Day</small>
              </div>
              
              </div>
              <div className='col'>
              <div
              className="card mt-4 text-center d-flex items-center justify-between"
              style={{
                padding: "20px",
                boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
                width: "100%",
              }}
            >
              <p className='text-cyan-400 m-0'>Health</p>

              <label><small>Popular Topic</small></label>
              </div>
              
              </div>
            </div>

            <fieldset className="p-3 mt-3">
              <legend className='w-100 d-blockw-100 d-block mb-4 position-relative'><span>Best </span> 
                <select className='ms-2' style={{fontSize:'1rem'}}>
                  <option selected disabled>Learning Paths</option>
                  <option>Working Opportunities</option>
                  <option>Earning Opportunities</option>
                  <option>Community Engagement</option>
                </select>
                <span className='mx-2'> In </span> 
                <select style={{fontSize:'1rem'}}>
                  {
                    learnModule?.map((m, _i)=>(<option key={'m'+_i} value={m?.value}>{m?.label}</option>))
                  }
                </select>
              </legend>
              <div className="row mt-4">
                  <div className='col-md-8'>
                  <p className="m-0 d-flex gap-1 w-100"><strong> >> </strong> <span><b>Algebra</b> is a trending topic with over 10K learners this week. <a href="">Explore It</a></span></p>
<p className="m-0 d-flex gap-1 w-100"><strong> >> </strong> <span>Boost your <b>General Knowledge</b> with 3K+ questions across 50+ topics. <a href="">Explore It</a></span></p>
<p className="m-0 d-flex gap-1 w-100"><strong> >> </strong> <span>Master the basics of <b>Programming</b>, already explored by 5K+ beginners. <a href="">Start Learning</a></span></p>
<p className="m-0 d-flex gap-1 w-100"><strong> >> </strong> <span>Discover exciting facts in <b>Science</b>, loved by 8K+ learners. <a href="">Dive In</a></span></p>


                
                  </div>
                  <div className='col-md-4'>
                      {isChartLoaded && (
                        <Chart
                          options={activitybyModules}
                          series={moduleSeries}
                          type="donut"
                          height={140}
                          width={'100%'}
                        />
                      )}
                      <small className="text-gray-400 w-100 text-center d-block">Overall System Stats by <span title="Learn, Work, Earn, Community">Module</span> and Date</small>
                  </div>
              </div>
              </fieldset>
                     {/* <p className='text-center color-gray my-3'> <small>** contribute more with modules by posting to increase your earning & reach **</small></p> */}
                        
              {/* <hr style={{ borderColor: '#f0f0f0' }} />
              <a href="" className='theme-color'>Engage More With Questionbank </a> */}
            </div>
            
            <div
              className="card mt-4"
              style={{
                padding: "20px",
                boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
                width: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                {/* Platform Overview Text */}
                <h2 style={{ margin: 0, fontSize: '1.125rem', color: '#3c4043' }}>Popular Topics</h2>

                {/* Filter Dropdown */}
                <div
                  style={{
                    borderRadius: "5px",
                    display: "flex",
                    alignItems: "center",
                    gap: "5px",
                  }}
                >
                  <select
                    className='filter-select'
                    value={selectedOption}
                    onChange={handleOptionChange}
                  >
                    <option value="questionbank">Question Bank</option>
                    <option value="IQ Questions">Interview Questions</option>
                    <option value="FullForms">FullForms</option>
                    <option value="Exams">Exams</option>
                    <option value="Dictionary">Dictionary</option>
                    <option value="Quizzes">Quizzes</option>
                    <option value="Career News">Career News</option>
                    <option value="Career News Hindi">Career News Hindi</option>
                    <option value="Feeds">Feeds</option>
                    <option value="Gigs">Gigs</option>
                    <option value="Quotes">Quotes</option>
                    <option value="Lyrics">Lyrics</option>
                    <option value="Videos">Videos</option>
                    <option value="Articles">Articles</option>
                    <option value="Forum">Forum</option>
                    <option value="Tutorials">Tutorials</option>
                    <option value="Educators">Educators</option>
                    <option value="Corporates">Corporates</option>

                  </select>
                  <select
                    className='filter-select'
                    value={selectedOption}
                    onChange={handleOptionChange}
                  >
                    <option value="today">Today</option>
                    <option value="yesterday">Yesterday</option>
                    <option value="past-7-days">Past 7 Days</option>
                    <option value="past-30-days">Past 30 Days</option>
                    <option value="past-60-days">Past 60 Days</option>
                    <option value="past-90-days">Past 90 Days</option>
                    <option value="past-6-months">Past 6 Months</option>
                    <option value="past-12-months">Past 12 Months</option>
                    <option value="this-month">This Month</option>
                    <option value="past-month">Past Month</option>
                    <option value="custom">Custom Range</option>
                  </select>
                  {selectedOption === "custom" && (<>
                    <div>
                      <DateRangePicker format="MM/dd/yyyy" character=" – " onChange={(e) => { setCustomRange({ start: e[0].toLocaleDateString(), end: e[1].toLocaleDateString() }) }} />

                    </div>
                    <div style={{ display: "flex", height: 35.2 }}>
                      <button
                        className="btn btn-primary btn-sm me-1"
                        style={{
                          padding: "8px 12px",
                          borderRadius: "5px",
                          border: "none",
                          cursor: "pointer",
                        }}
                        onClick={handleCustomRangeSubmit}
                      >
                        Apply
                      </button>
                      {/* <button
                      className="btn btn-outline-secondary btn-sm"
                      style={{
                        padding: "8px 12px",
                        borderRadius: "5px",
                        cursor: "pointer",
                      }}
                      onClick={handleClearFilter}
                    >
                      Clear
                    </button> */}
                    </div>
                  </>)
                  }
                </div>
              </div>
              <hr style={{ borderColor: '#f0f0f0' }} />
              <div style={{ marginBottom: "20px" }} className='table-responsive'>

                <table className='table table-bordered rounded c--table'>
                  <thead>
                    <tr>
                      <th>Topic</th>
                      <th>Likes</th>
                      <th>Shares</th>
                      <th>Views</th>
                      <th>Comments</th>
                      <th>#</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Technology</td>
                      <td>10</td>
                      <td>12</td>
                      <td>11</td>
                      <td>120</td>
                      <td><a href="#view1" className='theme-hover-gray me-2' title="Explore"><i class="fa fa-eye" aria-hidden="true"></i></a> <a href="#view1" className='theme-hover-gray' title="Post"><i class="fas fa-paper-plane" aria-hidden="true"></i></a></td>
                    </tr>
                    <tr>
                      <td>Data Structure</td>
                      <td>199</td>
                      <td>23</td>
                      <td>32</td>
                      <td>11</td>
                      <td><a href="#view2" className='theme-hover-gray me-2'><i class="fa fa-eye" aria-hidden="true"></i></a>
                      <a href="#view1" className='theme-hover-gray' title="Post"><i class="fas fa-paper-plane" aria-hidden="true"></i></a>
                      </td>
                    </tr>
                    <tr>
                      <td>Shopping</td>
                      <td>1</td>
                      <td>21</td>
                      <td>300</td>
                      <td>4k</td>
                      <td><a href="#view3" className='theme-hover-gray me-2'><i class="fa fa-eye" aria-hidden="true"></i></a>
                      <a href="#view1" className='theme-hover-gray' title="Post"><i class="fas fa-paper-plane" aria-hidden="true"></i></a>
                      </td>
                    </tr>
                    <tr>
                      <td>Kubernet</td>
                      <td>0</td>
                      <td>113</td>
                      <td>58</td>
                      <td>68</td>
                      <td><a href="#view4" className='theme-hover-gray me-2'><i class="fa fa-eye" aria-hidden="true"></i></a>
                      <a href="#view1" className='theme-hover-gray' title="Post"><i class="fas fa-paper-plane" aria-hidden="true"></i></a></td>
                    </tr>
                    <tr>
                      <td>Data Science</td>
                      <td>8</td>
                      <td>50</td>
                      <td>350</td>
                      <td>3k</td>
                      <td><a href="#view5" className='theme-hover-gray me-2'><i class="fa fa-eye" aria-hidden="true"></i></a><a href="#view1" className='theme-hover-gray' title="Post"><i class="fas fa-paper-plane" aria-hidden="true"></i></a></td>
                    </tr>
                    <tr>
                      <td>Data Science</td>
                      <td>8</td>
                      <td>50</td>
                      <td>350</td>
                      <td>3k</td>
                      <td><a href="#view2" className='theme-hover-gray me-2'><i class="fa fa-eye" aria-hidden="true"></i></a>
                      <a href="#view1" className='theme-hover-gray' title="Post"><i class="fas fa-paper-plane" aria-hidden="true"></i></a>
                      </td>
                    </tr>
                    <tr>
                      <td>DevOps</td>
                      <td>15</td>
                      <td>85</td>
                      <td>450</td>
                      <td>1.2k</td>
                      <td><a href="#view2" className='theme-hover-gray me-2'><i class="fa fa-eye" aria-hidden="true"></i></a>
                      <a href="#view1" className='theme-hover-gray' title="Post"><i class="fas fa-paper-plane" aria-hidden="true"></i></a>
                      </td>
                    </tr>
                    <tr>
                      <td>Cybersecurity</td>
                      <td>12</td>
                      <td>140</td>
                      <td>500</td>
                      <td>3.8k</td>
                      <td><a href="#view2" className='theme-hover-gray me-2'><i class="fa fa-eye" aria-hidden="true"></i></a>
                      <a href="#view1" className='theme-hover-gray' title="Post"><i class="fas fa-paper-plane" aria-hidden="true"></i></a>
                      </td>
                    </tr>
                    <tr>
                      <td>Software Development</td>
                      <td>9</td>
                      <td>80</td>
                      <td>350</td>
                      <td>2.2k</td>
                      <td><a href="#view2" className='theme-hover-gray me-2'><i class="fa fa-eye" aria-hidden="true"></i></a>
                      <a href="#view1" className='theme-hover-gray' title="Post"><i class="fas fa-paper-plane" aria-hidden="true"></i></a>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <a href="" className='theme-color'>Explore More Trending Questions</a>
                <p className='text-center color-gray mt-5'> <small>** Engage with popular topics by exploring and posting to increase your learning and earning very fast **</small></p>
              </div>
            </div>
          </div>
        
        
      </div>
     
        
       
        <div className='row'>
          <div className='col-md-12'>
          <div
              className="card"
              style={{
                padding: "20px",
                boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
                width: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                {/* Platform Overview Text */}
                <h2 style={{ margin: 0, fontSize: '1.125rem', color: '#3c4043' }}>Call To Actions</h2>

              </div>
              <ul class="timeline mb-0">
                <li class="cta-item d-flex flex-column align-items-start w-100">You haven't updated your profile this month—<a href="/update-profile" class="cta-link mb-2">Update Now</a></li>
                <li class="cta-item d-flex flex-column align-items-start w-100">Your article draft ‘Future of AI’ is incomplete—<a href="/edit-article/future-of-ai" class="cta-link mb-2">Finish Writing</a></li>
                {/* <li class="cta-item d-flex flex-column align-items-start w-100">You started a quiz on ‘JavaScript Basics’—<a href="/continue-quiz/javascript-basics" class="cta-link mb-2">Continue Now</a></li>
                <li class="cta-item d-flex flex-column align-items-start w-100">Your saved job application is pending—<a href="/continue-quiz/javascript-basics" class="cta-link mb-2">Complete Now</a></li> */}
                {/* <li class="cta-item d-flex flex-column align-items-start w-100">Your Blog Posting draft waiting you—<a href="/continue-quiz/javascript-basics" class="cta-link mb-2">Complete It</a></li> */}
              </ul>
              <hr style={{ borderColor: '#f0f0f0' }} />
              <a href="" className='theme-color'>View More</a>
            </div>
          </div>
        </div>
      

        {/* <div className='d-flex w-100 items-center justify-between collapse-bar mt-4'>
          <div>
            <h4 className="mb-0" style={{color: '#3c4043'}}>Credit & Transaction Overview</h4>
            <p className='color-gray mb-0'>Comprehensive insights into system interactions and credits flow.</p>
          </div>
          <i className='fa fa-angle-down cursor-pointer collapse-btn'></i>
        </div> */}

        <div className="mb-3 row d-none">

        <div className='col-md-8'>
          <div
            className="card h-100"
            style={{
              padding: "20px",
              boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
              width: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              {/* Platform Overview Text */}
              <h2 style={{ margin: 0, fontSize: '1.125rem', color: '#3c4043' }}>System Overview</h2>

              {/* Filter Dropdown */}
              <div
                style={{
                  borderRadius: "5px",
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                }}
              >
                <select
                  className='filter-select'
                  value={selectedOption}
                  onChange={handleOptionChange}
                >
                  <option value="learn">Learn</option>
                  <option value="work">Work</option>
                  <option value="earn">Earn</option>
                  <option value="community">Community</option>
                  <option value="finance">Finance</option>
                </select>
                <select
                  className='filter-select'
                  value={selectedOption}
                  onChange={handleOptionChange}
                >
                  <option value="today">Today</option>
                  <option value="yesterday">Yesterday</option>
                  <option value="past-7-days">Past 7 Days</option>
                  <option value="past-30-days">Past 30 Days</option>
                  <option value="past-60-days">Past 60 Days</option>
                  <option value="past-90-days">Past 90 Days</option>
                  <option value="past-6-months">Past 6 Months</option>
                  <option value="past-12-months">Past 12 Months</option>
                  <option value="this-month">This Month</option>
                  <option value="past-month">Past Month</option>
                  <option value="custom">Custom Range</option>
                </select>
                {selectedOption === "custom" && (<>
                  <div>
                    <DateRangePicker format="MM/dd/yyyy" character=" – " onChange={(e) => { setCustomRange({ start: e[0].toLocaleDateString(), end: e[1].toLocaleDateString() }) }} />

                  </div>
                  <div style={{ display: "flex", height: 35.2 }}>
                    <button
                      className="btn btn-primary btn-sm me-1"
                      style={{
                        padding: "8px 12px",
                        borderRadius: "5px",
                        border: "none",
                        cursor: "pointer",
                      }}
                      onClick={handleCustomRangeSubmit}
                    >
                      Apply
                    </button>
                    {/* <button
                      className="btn btn-outline-secondary btn-sm"
                      style={{
                        padding: "8px 12px",
                        borderRadius: "5px",
                        cursor: "pointer",
                      }}
                      onClick={handleClearFilter}
                    >
                      Clear
                    </button> */}
                  </div>
                </>)
                }
              </div>

              {/* )} */}

            </div>
            <hr style={{ borderColor: '#f0f0f0' }} />
            {/* Doughnut Chart */}
            <div style={{ marginBottom: "20px", height: "250px" }}>
              <Doughnut
                data={doughnutData}
                options={{
                  plugins: {
                    legend: {
                      position: "right",
                      labels: {
                        boxWidth: 28,
                        padding: 10,
                      },
                    },
                    tooltip: {
                      enabled: true,
                    },
                    datalabels: {
                      display: false,
                    },
                  },
                  maintainAspectRatio: false,
                  responsive: true,
                }}
              />
            </div>
          </div>
        </div>        
        </div>
        <div className="mb-4 row mt-4">

        <div className='col-md-4'>
          <div
            className="card mb-4"
            style={{
              padding: "20px",
              boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
              width: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              {/* Platform Overview Text */}
              <h2 style={{ margin: 0, fontSize: '1.125rem', color: '#3c4043' }}>Credits By Module</h2>

              {/* Filter Dropdown */}
             
                <select
                  className='filter-select'
                  value={selectedOption}
                  onChange={handleOptionChange}
                >
                  <option value="today">Today</option>
                  <option value="yesterday">Yesterday</option>
                  <option value="past-7-days">Past 7 Days</option>
                  <option value="past-30-days">Past 30 Days</option>
                  <option value="past-60-days">Past 60 Days</option>
                  <option value="past-90-days">Past 90 Days</option>
                  <option value="past-6-months">Past 6 Months</option>
                  <option value="past-12-months">Past 12 Months</option>
                  <option value="this-month">This Month</option>
                  <option value="past-month">Past Month</option>
                  <option value="custom">Custom Range</option>
                </select>
                {selectedOption === "custom" && (<>
                  <div>
                    <DateRangePicker format="MM/dd/yyyy" character=" – " onChange={(e) => { setCustomRange({ start: e[0].toLocaleDateString(), end: e[1].toLocaleDateString() }) }} />

                  </div>
                  <div style={{ display: "flex", height: 35.2 }}>
                    <button
                      className="btn btn-primary btn-sm me-1"
                      style={{
                        padding: "8px 12px",
                        borderRadius: "5px",
                        border: "none",
                        cursor: "pointer",
                      }}
                      onClick={handleCustomRangeSubmit}
                    >
                      Apply
                    </button>
                    {/* <button
                    className="btn btn-outline-secondary btn-sm"
                    style={{
                      padding: "8px 12px",
                      borderRadius: "5px",
                      cursor: "pointer",
                    }}
                    onClick={handleClearFilter}
                  >
                    Clear
                  </button> */}
                  </div>
                </>)
                }
              
            </div>              
            <hr style={{ borderColor: '#f0f0f0' }} />
            
              <div className='row '>
                        <div className='col-md-8'>
                            <div className="flex mb-3">
                                <div className='transaction-left flex items-center flex-1'>
                                      <div className='thumb-trans me-2' style={{background: 'rgb(153 191 223 / 50%)',color: '#fafafa'}}>
                                        <i className='fa fa-credit-card'></i>
                                      </div>
                                      <div className='flex flex-col'>
                                          <p className='m-0'><strong>{0}</strong></p>
                                          <small className='color-gray'>Learn</small>
                                      </div>
                                  </div>
                                  <div className='transaction-left flex items-center flex-1'>
                                      <div className='thumb-trans me-2' style={{background: 'rgb(186 133 223 / 46%)',color: '#fafafa'}}>
                                        <i className='fa fa-credit-card'></i>
                                      </div>
                                      <div className='flex flex-col'>
                                          <p className='m-0'><strong>{0}</strong></p>
                                          <small className='color-gray'>Work</small>
                                      </div>
                                  </div>
                            </div>
                            <div className="flex mb-3">
                                <div className='transaction-left flex items-center flex-1'>
                                      <div className='thumb-trans me-2' style={{background: 'rgb(229 129 137 / 46%)',color: '#fafafa'}}>
                                        <i className='fa fa-credit-card'></i>
                                      </div>
                                      <div className='flex flex-col'>
                                          <p className='m-0'><strong>{0}</strong></p>
                                          <small className='color-gray'>Earn</small>
                                      </div>
                                  </div>
                                  <div className='transaction-left flex items-center flex-1'>
                                      <div className='thumb-trans me-2' style={{background: 'rgb(230 177 114 / 46%)',color: '#fafafa'}}>
                                        <i className='fa fa-credit-card'></i>
                                      </div>
                                      <div className='flex flex-col'>
                                          <p className='m-0'><strong>{0}</strong></p>
                                          <small className='color-gray'>Community</small>
                                      </div>
                                  </div>
                            </div>                                                                                           
                        </div>                             
                        <div className='col-md-4' style={{filter: 'blur(5px) grayscale(100%)', pointerEvents:'none', userSelect:'none'}}>
                        {isChartLoaded && (
                          <Chart
                            options={earnSpentCredits}
                            series={creditEarnedSeries}
                            type="donut"
                            height={100}
                            width={'100%'}
                          />
                        )}
                        </div>
                      </div>
          </div>
          <div
            className="card mt-4"
            style={{
              padding: "20px",
              boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
              width: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              {/* Platform Overview Text */}
              <h2 style={{ margin: 0, fontSize: '1.125rem', color: '#3c4043' }}>Credits By Library</h2>

              
              
            </div>   

            <hr style={{ borderColor: '#f0f0f0' }} />
            <div
                style={{
                  borderRadius: "5px",
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                }}
              >
                <select
                  className='filter-select'
                  value={selectedOption}
                  onChange={handleOptionChange}
                >
                  <option value="all">Learn</option>
                  <option value="questionbank">Work</option>
                  <option value="IQ Questions">Earn</option>
                  <option value="FullForms">Community</option>
                </select>
                <select
                  className='filter-select'
                  value={selectedOption}
                  onChange={handleOptionChange}
                >
                  <option value="today">Today</option>
                  <option value="yesterday">Yesterday</option>
                  <option value="past-7-days">Past 7 Days</option>
                  <option value="past-30-days">Past 30 Days</option>
                  <option value="past-60-days">Past 60 Days</option>
                  <option value="past-90-days">Past 90 Days</option>
                  <option value="past-6-months">Past 6 Months</option>
                  <option value="past-12-months">Past 12 Months</option>
                  <option value="this-month">This Month</option>
                  <option value="past-month">Past Month</option>
                  <option value="custom">Custom Range</option>
                </select>
                {selectedOption === "custom" && (<>
                  <div>
                    <DateRangePicker format="MM/dd/yyyy" character=" – " onChange={(e) => { setCustomRange({ start: e[0].toLocaleDateString(), end: e[1].toLocaleDateString() }) }} />

                  </div>
                  <div style={{ display: "flex", height: 35.2 }}>
                    <button
                      className="btn btn-primary btn-sm me-1"
                      style={{
                        padding: "8px 12px",
                        borderRadius: "5px",
                        border: "none",
                        cursor: "pointer",
                      }}
                      onClick={handleCustomRangeSubmit}
                    >
                      Apply
                    </button>
                    {/* <button
                    className="btn btn-outline-secondary btn-sm"
                    style={{
                      padding: "8px 12px",
                      borderRadius: "5px",
                      cursor: "pointer",
                    }}
                    onClick={handleClearFilter}
                  >
                    Clear
                  </button> */}
                  </div>
                </>)
                }
              </div>
            <hr style={{ borderColor: '#f0f0f0' }} />

              <div className='row'>
                        <div className='col-md-8'>
                            <div className="flex mb-3">
                                <div className='transaction-left flex items-center flex-1'>
                                      <div className='thumb-trans me-2'>
                                        <i className='fa fa-credit-card'></i>
                                      </div>
                                      <div className='flex flex-col'>
                                          <p className='m-0'><strong>{0}</strong></p>
                                          <small className='color-gray'>Question Bank</small>
                                      </div>
                                  </div>
                                  <div className='transaction-left flex items-center flex-1'>
                                      <div className='thumb-trans me-2'>
                                        <i className='fa fa-credit-card'></i>
                                      </div>
                                      <div className='flex flex-col'>
                                          <p className='m-0'><strong>{0}</strong></p>
                                          <small className='color-gray'>Full Forms</small>
                                      </div>
                                  </div>
                            </div>
                            <div className="flex mb-3">
                                <div className='transaction-left flex items-center flex-1'>
                                      <div className='thumb-trans me-2'>
                                        <i className='fa fa-credit-card'></i>
                                      </div>
                                      <div className='flex flex-col'>
                                          <p className='m-0'><strong>{0}</strong></p>
                                          <small className='color-gray'>Dictionary</small>
                                      </div>
                                  </div>
                                  <div className='transaction-left flex items-center flex-1'>
                                      <div className='thumb-trans me-2'>
                                        <i className='fa fa-credit-card'></i>
                                      </div>
                                      <div className='flex flex-col'>
                                          <p className='m-0'><strong>{0}</strong></p>
                                          <small className='color-gray'>Exams</small>
                                      </div>
                                  </div>
                            </div>                                                                                           
                        </div>                             
                        <div className='col-md-4'  style={{filter: 'blur(5px) grayscale(100%)',pointerEvents:'none', userSelect:'none'}}>
                        {isChartLoaded && (
                          <Chart
                            options={earnSpentCredits}
                            series={creditEarnedSeries}
                            type="donut"
                            height={100}
                            width={'100%'}
                          />
                        )}
                        </div>
                      </div>
          </div>
        </div>
        <div className='col-md-8'>
          <div
            className="card h-100"
            style={{
              padding: "20px",
              boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
              width: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              {/* Platform Overview Text */}
              <h2 style={{ margin: 0, fontSize: '1.125rem', color: '#3c4043' }}>Statistics & More Earning Opportunities</h2>

              {/* Filter Dropdown */}
              
            </div>
            <hr style={{ borderColor: '#f0f0f0' }} />

            <div
                style={{
                  borderRadius: "5px",
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                }}
              >

                {/* <select
                  className='filter-select'
                  value={selectedOption}
                  onChange={handleOptionChange}
                > */}
              
                <select
                  className='filter-select'
                >
                  <option value="yesterday">Learn</option>
                  <option value="past-7-days">Work</option>
                  <option value="past-30-days">Earn</option>
                  <option value="past-60-days">Community</option>
                </select>
                <select
                  className='filter-select'
                  value={selectedOption}
                  onChange={handleOptionChange}
                >
                  <option value="questionbank">Question Bank</option>
                  <option value="IQ Questions">Interview Questions</option>
                  <option value="FullForms">FullForms</option>
                  <option value="Exams">Exams</option>
                  <option value="Dictionary">Dictionary</option>
                  <option value="Quizzes">Quizzes</option>
                  <option value="Career News">Career News</option>
                  <option value="Career News Hindi">Career News Hindi</option>
                  <option value="Feeds">Feeds</option>
                  <option value="Gigs">Gigs</option>
                  <option value="Quotes">Quotes</option>
                  <option value="Lyrics">Lyrics</option>
                  <option value="Videos">Videos</option>
                  <option value="Articles">Articles</option>
                  <option value="Forum">Forum</option>
                  <option value="Tutorials">Tutorials</option>
                  <option value="Educators">Educators</option>
                  <option value="Corporates">Corporates</option>

                </select>
                  <select className='filter-select' value={selectedOption}
                  onChange={handleOptionChange}>
                      <option value="today">Weekly</option>
                      <option value="yesterday">Monthly</option>
                      <option value="this_month">Yearly</option>
                      <option value="last_month">All Time</option>
      
                </select>
               
              </div>
              <hr style={{ borderColor: '#f0f0f0' }} />
             
              <div className='row'>
            <div className='col'>
            <div
            className="card mt-4 text-center d-flex items-center justify-between"
            style={{
              padding: "20px",
              boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
              width: "100%",
            }}
          >
            <h4 className='text-orange-400'>0</h4>
            <label>Total Credits</label>
            </div>
            
            </div>
            <div className='col'>
            <div
            className="card mt-4 text-center d-flex items-center justify-between"
            style={{
              padding: "20px",
              boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
              width: "100%",
            }}
          >
            
            <h4 className='text-green-400'>0</h4>
            <label>Earned Credits</label>
            </div>
            
            </div>
            <div className='col'>
            <div
            className="card mt-4 text-center d-flex items-center justify-between"
            style={{
              padding: "20px",
              boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
              width: "100%",
            }}
          >
            <h4 className='text-purple-400'>0</h4>

            <label>Spent Credits</label>
            </div>
            
            </div>
            <div className='col'>
            <div
            className="card mt-4 text-center d-flex items-center justify-between"
            style={{
              padding: "20px",
              boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
              width: "100%",
            }}
          >
            <h4 className='text-cyan-400'>0</h4>

            <label>Withdrawals</label>
            </div>
            
            </div>
          </div>
          
              {/* // <FinanceReportChart selectedOptions={selectedOptions} /> */}
           
            <hr style={{ borderColor: '#f0f0f0' }} />
            
            <p className='text-center'>
            Earn credits by actively contributing and engaging with the platform. Like, share, view, and upload content to grow your credit balance. Your participation helps enhance the community and unlocks more opportunities for you!
            </p>
            <p><strong className='color-gray'>Expand your earning potential with our Suggested Libraries, tailored to boost your engagement and credit accumulation</strong></p>
            <div className='row'>
            <div className='col-md-3 my-0'>                           
                          <button className='small-btn p-2 border mt-1 small shadows w-100 d-flex justify-between items-center'>Upload Video</button>
                      </div>
                      <div className='col-md-3 my-0'>                           
                          <button className='small-btn p-2 border mt-1 small shadows w-100'>Post Course</button>
                      </div>
                      <div className='col-md-3 my-0'>                           
                          <button className='small-btn p-2 border mt-1 small shadows w-100'>Comment on Forums</button>
                      </div>
                      <div className='col-md-3 my-0'>                           
                          <button className='small-btn p-2 border mt-1 small shadows w-100'>Explore Question Bank</button>
                      </div>
                      </div>
          </div>
        </div>
      </div>
        <div className="mt-4 row">

          <div className='col-md-4'>
            <div
              className="card h-100"
              style={{
                padding: "20px",
                boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
                width: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                {/* Platform Overview Text */}
                <h2 style={{ margin: 0, fontSize: '1.125rem', color: '#3c4043' }}>Recent Transactions</h2>

                {/* Filter Dropdown */}
                
              </div>
              
              <hr style={{ borderColor: '#f0f0f0' }} />
              <div class="message-container">
                <h5>No Recent Transactions Yet!</h5>
                <p>Start earning credits by contributing and engaging with the platform. Check out how you can earn credits and track your progress here.</p>
                <div class="actions">
                  <button class="mt-3 btn--light  btn justify-content-center px-md-3 px-2 d-flex align-items-center border-0 btn-primary w-100 btn-sm credit-btn">Watch Video: How to Earn Credits</button>
                  <a href="/learn-more" class="btn btn-outline theme-color mt-3 btn--light">Learn More</a>
                </div>
              </div>
              <div
                  style={{
                    borderRadius: "5px",
                    display: "flex",
                    alignItems: "center",
                    gap: "5px",
                    filter: 'blur(3px)',
                    pointerEvents:'none', userSelect:'none'
                  }}
                >
                  <select
                    className='filter-select'
                    value={selectedOption}
                    onChange={handleOptionChange}
                  >
                    <option value="all">All</option>
                    <option value="questionbank">Question Bank</option>
                    <option value="IQ Questions">Interview Questions</option>
                    <option value="FullForms">FullForms</option>
                    <option value="Exams">Exams</option>
                    <option value="Dictionary">Dictionary</option>
                    <option value="Quizzes">Quizzes</option>
                    <option value="Career News">Career News</option>
                    <option value="Career News Hindi">Career News Hindi</option>
                    <option value="Feeds">Feeds</option>
                    <option value="Gigs">Gigs</option>
                    <option value="Quotes">Quotes</option>
                    <option value="Lyrics">Lyrics</option>
                    <option value="Videos">Videos</option>
                    <option value="Articles">Articles</option>
                    <option value="Forum">Forum</option>
                    <option value="Tutorials">Tutorials</option>
                    <option value="Educators">Educators</option>
                    <option value="Corporates">Corporates</option>

                  </select>
                  <select
                    className='filter-select'
                    value={selectedOption}
                    onChange={handleOptionChange}
                  >
                    <option value="today">Today</option>
                    <option value="yesterday">Yesterday</option>
                    <option value="past-7-days">Past 7 Days</option>
                    <option value="past-30-days">Past 30 Days</option>
                    <option value="past-60-days">Past 60 Days</option>
                    <option value="past-90-days">Past 90 Days</option>
                    <option value="past-6-months">Past 6 Months</option>
                    <option value="past-12-months">Past 12 Months</option>
                    <option value="this-month">This Month</option>
                    <option value="past-month">Past Month</option>
                    <option value="custom">Custom Range</option>
                  </select>
                  {selectedOption === "custom" && (<>
                    <div>
                      <DateRangePicker format="MM/dd/yyyy" character=" – " onChange={(e) => { setCustomRange({ start: e[0].toLocaleDateString(), end: e[1].toLocaleDateString() }) }} />

                    </div>
                    <div style={{ display: "flex", height: 35.2 }}>
                      <button
                        className="btn btn-primary btn-sm me-1"
                        style={{
                          padding: "8px 12px",
                          borderRadius: "5px",
                          border: "none",
                          cursor: "pointer",
                        }}
                        onClick={handleCustomRangeSubmit}
                      >
                        Apply
                      </button>
                      {/* <button
                      className="btn btn-outline-secondary btn-sm"
                      style={{
                        padding: "8px 12px",
                        borderRadius: "5px",
                        cursor: "pointer",
                      }}
                      onClick={handleClearFilter}
                    >
                      Clear
                    </button> */}
                    </div>
                  </>)
                  }
                </div>
              <div style={{ marginBottom: "20px",filter: 'blur(5px)',pointerEvents:'none', userSelect:'none' }} className='table-responsive'>
              {transactions.map((transaction, index) => (
                  <div className='transaction-list flex justify-between items-center  my-3'>
                  <div className='transaction-left flex items-center'>
                      <div className='thumb-trans me-2'>
                         <i className='fa fa-credit-card'></i>
                      </div>
                      <div className='flex flex-col'>
                          <p className='m-0'>{transaction?.description}</p>
                          <small className='color-gray'>{transaction.date}</small>
                      </div>
                  </div>
                  <div className='transaction-right'>
                     <span className={transaction?.type=='debit'?'text-green-500':'text-red-500'}>{transaction?.type=='debit'?'+'+transaction.amount:'-'+transaction.amount}</span>
                  </div>
              </div>
              ))
}

<hr style={{ borderColor: '#f0f0f0' }} />
                <a href="" className='theme-color'>More Transactions</a>
              </div>
            </div>
          </div>
          <div className='col-md-8'>
            <div
              className="card h-100"
              style={{
                padding: "20px",
                boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
                width: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                {/* Platform Overview Text */}
                <h2 style={{ margin: 0, fontSize: '1.125rem', color: '#3c4043' }}>My Credits & Transactions Stats</h2>

                {/* Filter Dropdown */}
                
              </div>
              <hr style={{ borderColor: '#f0f0f0' }} />
                 <div class="message-container">
                    <h5>No Credit Activity Yet!</h5>
                    <p>Kickstart your journey by earning credits through contributions or purchasing credits to unlock more features.</p>
                    <div class="actions">
                      <button class="btn btn--light  offer-btn w-100 text-white">Purchase Credits</button>
                      <button class="mt-3 btn btn--light  justify-content-center px-md-3 px-2 d-flex align-items-center border-0 btn-primary w-100 btn-sm credit-btn">Watch Video: How to Earn Credits</button>
                      <a href="/learn-more" class="btn btn-outline theme-color mt-3 btn--light ">Learn More About Credit</a>
                    </div>
                  </div>
              <div
                  style={{
                    borderRadius: "5px",
                    display: "flex",
                    alignItems: "center",
                    gap: "5px",
                    filter: 'blur(3px)',pointerEvents:'none', userSelect:'none'
                  }}
                >

                  {/* <select
                    className='filter-select'
                    value={selectedOption}
                    onChange={handleOptionChange}
                  > */}
                  <select className='filter-select'  value={selectedOptions}
                    onChange={(e)=>setSelectedOptions(e.target.value)}>
                        <option value="cevscs">Credits Earned Vs Credits Spent</option>
                        <option value="cpvsw">Credits Purchased Vs Withdrawals</option>
        
                  </select>
                  <select
                    className='filter-select'
                  >
                    <option value="yesterday">Learn</option>
                    <option value="past-7-days">Work</option>
                    <option value="past-30-days">Earn</option>
                    <option value="past-60-days">Community</option>
                  </select>
                  <select
                    className='filter-select'
                    value={selectedOption}
                    onChange={handleOptionChange}
                  >
                    <option value="questionbank">Question Bank</option>
                    <option value="IQ Questions">Interview Questions</option>
                    <option value="FullForms">FullForms</option>
                    <option value="Exams">Exams</option>
                    <option value="Dictionary">Dictionary</option>
                    <option value="Quizzes">Quizzes</option>
                    <option value="Career News">Career News</option>
                    <option value="Career News Hindi">Career News Hindi</option>
                    <option value="Feeds">Feeds</option>
                    <option value="Gigs">Gigs</option>
                    <option value="Quotes">Quotes</option>
                    <option value="Lyrics">Lyrics</option>
                    <option value="Videos">Videos</option>
                    <option value="Articles">Articles</option>
                    <option value="Forum">Forum</option>
                    <option value="Tutorials">Tutorials</option>
                    <option value="Educators">Educators</option>
                    <option value="Corporates">Corporates</option>

                  </select>
                    <select className='filter-select' value={selectedOption}
                    onChange={handleOptionChange}>
                        <option value="today">Weekly</option>
                        <option value="yesterday">Monthly</option>
                        <option value="this_month">Yearly</option>
                        <option value="last_month">All Time</option>
        
                  </select>
                 
                </div>
                <hr style={{ borderColor: '#f0f0f0' }} />
                {
                  selectedOptions && isChartLoaded && (
                    <Chart
                      options={creditCamparisonsOptions}
                      series={selectedOptions=='cevscs'?earnCreditVsSpentCredits:creditPurchasedVsWithdrawals}
                      type="area"
                      height={200}
                      width={"100%"}
                      style={{filter: 'blur(5px) grayscale(100%)',pointerEvents:'none', userSelect:'none'}}
                    />
                  )
                  
                }
                {/* // <FinanceReportChart selectedOptions={selectedOptions} /> */}
             
              <hr style={{ borderColor: '#f0f0f0' }} />
              {
                selectedOptions=='cevscs'?<> <div className='d-flex items-center justify-between mb-3' style={{filter: 'blur(5px)',pointerEvents:'none', userSelect:'none'}}>
                <div className='flex flex-col'>
                    <p className='m-0'>Earned Credits</p>
                    <small className='color-gray'><strong className='text-green-500 me-1'><i className='fa fa-long-arrow-up me-1'></i>7%</strong>from last week</small>
                </div>
                  <strong className='text-green-500'>1.5K</strong>
  
                </div>
                <div className='d-flex items-center justify-between mb-3' style={{filter: 'blur(5px)',pointerEvents:'none', userSelect:'none'}}>
                <div className='flex flex-col'>
                    <p className='m-0'>Spend Credits</p>
                    <small className='color-gray'><strong className='text-red-500 me-1'><i className='fa fa-long-arrow-down me-1'></i>1%</strong>from last week</small>
                </div>
                  <strong className='text-red-500'>-2,000</strong>
                </div></>:<>
                <div className='d-flex items-center justify-between mb-3' style={{filter: 'blur(5px)',pointerEvents:'none', userSelect:'none'}}>
                  <div className='flex flex-col'>
                      <p className='m-0'>Purchased Credits</p>
                      <small className='color-gray'><strong className='text-green-500 me-1'><i className='fa fa-long-arrow-up me-1'></i>0.75%</strong>from last week</small>
                  </div>
                    <strong className='theme-color'>INR 1,000 (500 Credits)</strong>
                  </div>
                  <div className='d-flex items-center justify-between mb-3' style={{filter: 'blur(5px)',pointerEvents:'none', userSelect:'none'}}>
                  <div className='flex flex-col'>
                      <p className='m-0'>Withdrawals</p>
                      <small className='color-gray'><strong className='text-green-500 me-1'><i className='fa fa-long-arrow-up me-1'></i>20%</strong>from last week</small>
                  </div>
                    <strong className='theme-color'>50K</strong>
                  </div>
                </>
              }
            </div>
          </div>
        </div>
        

        {/* activities section  */}

        <div className="mb-3 row mt-4">
          <div className='col-md-6'>
                  <div className='card'
                     style={{
                      padding: "20px",
                      boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
                      width: "100%",
                    }}
                  >
                      <div className='row'>
                        <div className='col-md-12'>
                        
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          {/* Platform Overview Text */}
                          <h2 style={{ margin: 0, fontSize: '1.125rem', color: '#3c4043' }}>Activity By Modules</h2>

                          {/* Filter Dropdown */}
                          <div
                            style={{
                              borderRadius: "5px",
                              display: "flex",
                              alignItems: "center",
                              gap: "5px",
                            }}
                          >
                          
                          
                            <select
                              className='filter-select'
                              value={selectedOption}
                              onChange={handleOptionChange}
                            >
                              <option value="today">Today</option>
                              <option value="yesterday">Yesterday</option>
                              <option value="past-7-days">Past 7 Days</option>
                              <option value="past-30-days">Past 30 Days</option>
                              <option value="past-60-days">Past 60 Days</option>
                              <option value="past-90-days">Past 90 Days</option>
                              <option value="past-6-months">Past 6 Months</option>
                              <option value="past-12-months">Past 12 Months</option>
                              <option value="this-month">This Month</option>
                              <option value="past-month">Past Month</option>
                              <option value="custom">Custom Range</option>
                            </select>
                            {selectedOption === "custom" && (<>
                              <div>
                                <DateRangePicker format="MM/dd/yyyy" character=" – " onChange={(e) => { setCustomRange({ start: e[0].toLocaleDateString(), end: e[1].toLocaleDateString() }) }} />

                              </div>
                              <div style={{ display: "flex", height: 35.2 }}>
                                <button
                                  className="btn btn-primary btn-sm me-1"
                                  style={{
                                    padding: "8px 12px",
                                    borderRadius: "5px",
                                    border: "none",
                                    cursor: "pointer",
                                  }}
                                  onClick={handleCustomRangeSubmit}
                                >
                                  Apply
                                </button>
                              </div>
                            </>)
                            }
                          </div>
                        </div>
                        <hr style={{ borderColor: '#f0f0f0' }} />
                        <div className='row'>
                          <div className='col-md-8'>
                              <div className="flex mb-3">
                                  <div className='transaction-left flex items-center flex-1'>
                                        <div className='thumb-trans me-2'>
                                          <i className='fa fa-credit-card'></i>
                                        </div>
                                        <div className='flex flex-col'>
                                            <p className='m-0'><strong>{0}</strong></p>
                                            <small className='color-gray'>Learn Activities</small>
                                        </div>
                                    </div>
                                    <div className='transaction-left flex items-center flex-1'>
                                        <div className='thumb-trans me-2'>
                                          <i className='fa fa-credit-card'></i>
                                        </div>
                                        <div className='flex flex-col'>
                                            <p className='m-0'><strong>{0}</strong></p>
                                            <small className='color-gray'>Work Activities</small>
                                        </div>
                                    </div>
                              </div>
                              <div className="flex justify-between">
                                  <div className='transaction-left flex items-center flex-1'>
                                        <div className='thumb-trans me-2'>
                                          <i className='fa fa-credit-card'></i>
                                        </div>
                                        <div className='flex flex-col'>
                                            <p className='m-0'><strong>{0}</strong></p>
                                            <small className='color-gray'>Earn Activities</small>
                                        </div>
                                    </div>
                                    <div className='transaction-left flex items-center flex-1'>
                                        <div className='thumb-trans me-2'>
                                          <i className='fa fa-credit-card'></i>
                                        </div>
                                        <div className='flex flex-col'>
                                            <p className='m-0'><strong>{0}</strong></p>
                                            <small className='color-gray'>Community Activities</small>
                                        </div>
                                    </div>
                              </div>                                
                          </div>
                          <div className='col-md-4' style={{filter: 'blur(5px) grayscale(100%)',pointerEvents:'none', userSelect:'none'}}>
                          {isChartLoaded && (
                            <Chart
                              options={activitybySections}
                              series={sectionSeries}
                              type="donut"
                              height={100}
                              width={'100%'}
                            />
                          )}
                            {/* <hr style={{ borderColor: '#f0f0f0' }} />
                            <a href="" className='theme-color'>More Activities</a> */}
                          </div>
                        
                      
                      </div>
                      {/* <hr style={{ borderColor: '#f0f0f0' }} />
                      <p><strong className='color-gray'>Recommended Modules Based on Activities to Explore</strong></p>
                      <div className='row'>
                        <div className='col-md-4 my-0'>                           
                            <button className='small-btn p-2 border mt-1 small shadows w-100 d-flex justify-between items-center'>Question Bank(4L) <span className='text-green-400 ms-1'><i className='fa fa-long-arrow-up me-1'></i>0.75%</span></button>
                        </div>
                        <div className='col-md-4 my-0'>                           
                            <button className='small-btn p-2 border mt-1 small shadows w-100'>Fullforms(50k)<span className='text-green-400 ms-1'><i className='fa fa-long-arrow-up me-1'></i>0.75%</span></button>
                        </div>
                        <div className='col-md-4 my-0'>                           
                            <button className='small-btn p-2 border mt-1 small shadows w-100'>Dictionary(1k)<span className='text-green-400 ms-1'><i className='fa fa-long-arrow-up me-1'></i>1%</span></button>
                        </div>
                        <div className='col-md-4 my-0'>                           
                            <button className='small-btn p-2 border mt-1 small shadows w-100'>Courses(2M)<span className='text-green-400 ms-1'><i className='fa fa-long-arrow-up me-1'></i>10%</span></button>
                        </div>
                        <div className='col-md-4 my-0'>                           
                            <button className='small-btn p-2 border mt-1 small shadows w-100'>Tutorials(2M)<span className='text-green-400 ms-1'><i className='fa fa-long-arrow-up me-1'></i>11%</span></button>
                        </div>
                        <div className='col-md-4 my-0'>                           
                            <button className='small-btn p-2 border mt-1 small shadows w-100'>Educator(1.5L)<span className='text-green-400 ms-1'><i className='fa fa-long-arrow-up me-1'></i>13%</span></button>
                        </div>
                      </div> */}
                       <div className='row'>
                        <div className='col-md-8 m-auto my-3'><button class="mt-3 btn btn--light  justify-content-center px-md-3 px-2 d-flex align-items-center border-0 btn-primary w-100 btn-sm credit-btn ">Watch Video: How to Engage with Libraries</button></div>
                      </div>
                     {/* <p className='text-center color-gray my-4'> <small>** Engage more with modules by view, like, comment, share to increase your earning **</small></p> */}
                        </div>
                      </div>                      
                  </div>

                  <div className='card mt-4'
                     style={{
                      padding: "20px",
                      boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
                      width: "100%",
                    }}
                  >
                      <div className='row'>
                        <div className='col-md-12'>
                        
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          {/* Platform Overview Text */}
                          <h2 style={{ margin: 0, fontSize: '1.125rem', color: '#3c4043' }}>Activity By Library</h2>

                          {/* Filter Dropdown */}
                          <div
                            style={{
                              borderRadius: "5px",
                              display: "flex",
                              alignItems: "center",
                              gap: "5px",
                            }}
                          >
                          
                          <select
                              className='filter-select'
                            >
                              <option value="yesterday">Learn</option>
                              <option value="past-7-days">Work</option>
                              <option value="past-30-days">Earn</option>
                              <option value="past-60-days">Community</option>
                            </select>
                          
                            <select
                              className='filter-select'
                              value={selectedOption}
                              onChange={handleOptionChange}
                            >
                              <option value="today">Today</option>
                              <option value="yesterday">Yesterday</option>
                              <option value="past-7-days">Past 7 Days</option>
                              <option value="past-30-days">Past 30 Days</option>
                              <option value="past-60-days">Past 60 Days</option>
                              <option value="past-90-days">Past 90 Days</option>
                              <option value="past-6-months">Past 6 Months</option>
                              <option value="past-12-months">Past 12 Months</option>
                              <option value="this-month">This Month</option>
                              <option value="past-month">Past Month</option>
                              <option value="custom">Custom Range</option>
                            </select>
                            {selectedOption === "custom" && (<>
                              <div>
                                <DateRangePicker format="MM/dd/yyyy" character=" – " onChange={(e) => { setCustomRange({ start: e[0].toLocaleDateString(), end: e[1].toLocaleDateString() }) }} />

                              </div>
                              <div style={{ display: "flex", height: 35.2 }}>
                                <button
                                  className="btn btn-primary btn-sm me-1"
                                  style={{
                                    padding: "8px 12px",
                                    borderRadius: "5px",
                                    border: "none",
                                    cursor: "pointer",
                                  }}
                                  onClick={handleCustomRangeSubmit}
                                >
                                  Apply
                                </button>
                              </div>
                            </>)
                            }
                          </div>
                        </div>
                        <hr style={{ borderColor: '#f0f0f0' }} />
                        <div className='row'>
                          <div className='col-md-8'>
                              <div className="flex mb-3">
                                  <div className='transaction-left flex items-center flex-1'>
                                        <div className='thumb-trans me-2'>
                                          <i className='fa fa-credit-card'></i>
                                        </div>
                                        <div className='flex flex-col'>
                                            <p className='m-0'><strong>{0}</strong></p>
                                            <small className='color-gray'>{learnModule[0].label} Activities</small>
                                        </div>
                                    </div>
                                    <div className='transaction-left flex items-center flex-1'>
                                        <div className='thumb-trans me-2'>
                                          <i className='fa fa-credit-card'></i>
                                        </div>
                                        <div className='flex flex-col'>
                                            <p className='m-0'><strong>{0}</strong></p>
                                            <small className='color-gray'>{learnModule[1].label} Activities</small>
                                        </div>
                                    </div>
                              </div>
                              <div className="flex mb-3">
                                  <div className='transaction-left flex items-center flex-1'>
                                        <div className='thumb-trans me-2'>
                                          <i className='fa fa-credit-card'></i>
                                        </div>
                                        <div className='flex flex-col'>
                                            <p className='m-0'><strong>{0}</strong></p>
                                            <small className='color-gray'>{learnModule[2].label} Activities</small>
                                        </div>
                                    </div>
                                    <div className='transaction-left flex items-center flex-1'>
                                        <div className='thumb-trans me-2'>
                                          <i className='fa fa-credit-card'></i>
                                        </div>
                                        <div className='flex flex-col'>
                                            <p className='m-0'><strong>{0}</strong></p>
                                            <small className='color-gray'>{learnModule[3].label} Activities</small>
                                        </div>
                                    </div>
                              </div>
                              <div className="flex justify-between mb-3">
                                  <div className='transaction-left flex items-center flex-1'>
                                        <div className='thumb-trans me-2'>
                                          <i className='fa fa-credit-card'></i>
                                        </div>
                                        <div className='flex flex-col'>
                                            <p className='m-0'><strong>{0}</strong></p>
                                            <small className='color-gray'>{learnModule[4].label} Activities</small>
                                        </div>
                                    </div>
                                    <div className='transaction-left flex items-center flex-1'>
                                        <div className='thumb-trans me-2'>
                                          <i className='fa fa-credit-card'></i>
                                        </div>
                                        <div className='flex flex-col'>
                                            <p className='m-0'><strong>{0}</strong></p>
                                            <small className='color-gray'>{learnModule[5].label} Activities</small>
                                        </div>
                                    </div>
                              </div>  
                                                                
                          </div>
                             
                          <div className='col-md-4' style={{filter: 'blur(3px) grayscale(100%)',pointerEvents:'none', userSelect:'none'}}>
                          {isChartLoaded && (
                            <Chart
                              options={activitybyModules}
                              series={moduleSeries}
                              type="donut"
                              height={100}
                              width={'100%'}
                            />
                          )}
                            {/* <hr style={{ borderColor: '#f0f0f0' }} />
                            <a href="" className='theme-color'>More Activities</a> */}
                          </div>
                          <div className="flex justify-between w-100">
                                  <div className='transaction-left flex items-center flex-1'>
                                        <div className='thumb-trans me-2'>
                                          <i className='fa fa-credit-card'></i>
                                        </div>
                                        <div className='flex flex-col'>
                                            <p className='m-0'><strong>{0}</strong></p>
                                            <small className='color-gray'>{learnModule[6].label} Activities</small>
                                        </div>
                                    </div>
                                    <div className='transaction-left flex items-center flex-1'>
                                        <div className='thumb-trans me-2'>
                                          <i className='fa fa-credit-card'></i>
                                        </div>
                                        <div className='flex flex-col'>
                                            <p className='m-0'><strong>{0}</strong></p>
                                            <small className='color-gray'>{learnModule[7].label} Activities</small>
                                        </div>
                                    </div>
                                    <div className='transaction-left flex items-center flex-1'>
                                        <div className='thumb-trans me-2'>
                                          <i className='fa fa-credit-card'></i>
                                        </div>
                                        <div className='flex flex-col'>
                                            <p className='m-0'><strong>{0}</strong></p>
                                            <small className='color-gray'>{learnModule[8].label} Activities</small>
                                        </div>
                                    </div>
                              </div>
                      
                      </div>
                      {/* <hr style={{ borderColor: '#f0f0f0' }} />
                      <p><strong className='color-gray'>Recommended Topics Based on Modules Activities to Explore</strong></p>
                      <div className='row'>
                        <div className='col-md-4 my-0'>                           
                            <button className='small-btn p-2 border mt-1 small shadows w-100 d-flex justify-between items-center'>General Knowledge(4L)</button>
                        </div>
                        <div className='col-md-4 my-0'>                           
                            <button className='small-btn p-2 border mt-1 small shadows w-100'>Meanings In Marathi(50k)</button>
                        </div>
                        <div className='col-md-4 my-0'>                           
                            <button className='small-btn p-2 border mt-1 small shadows w-100'>Blog: AI (1k)</button>
                        </div>
                        <div className='col-md-4 my-0'>                           
                            <button className='small-btn p-2 border mt-1 small shadows w-100'>Courses: Kubernet(2M)</button>
                        </div>
                        <div className='col-md-4 my-0'>                           
                            <button className='small-btn p-2 border mt-1 small shadows w-100'>Question Bank: Particle(2M)</button>
                        </div>
                        <div className='col-md-4 my-0'>                           
                            <button className='small-btn p-2 border mt-1 small shadows w-100'>Interview Question: Maths(1.5L)</button>
                        </div>
                      </div> */}
                      <div className='row'>
                        <div className='col-md-8 m-auto mt-3'><button class="mt-3 btn--light  btn justify-content-center px-md-3 px-2 d-flex align-items-center border-0 btn-primary w-100 btn-sm credit-btn">Watch Video: How to Engage with Libraries</button></div>
                      </div>
                      
                     {/* <p className='text-center color-gray my-4'> <small>** Engage more with modules by view, like, comment, share to increase your learning and earning **</small></p> */}
                        </div>
                      </div>                      
                  </div>
          </div>
          <div className='col-md-6'>
            
            <div
              className="card"
              style={{
                padding: "20px",
                boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
                width: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                {/* Platform Overview Text */}
                <h2 style={{ margin: 0, fontSize: '1.125rem', color: '#3c4043' }}>Recent Activities</h2>

                {/* Filter Dropdown */}
                <div
                  style={{
                    borderRadius: "5px",
                    display: "flex",
                    alignItems: "center",
                    gap: "5px",
                    filter: 'blur(5px)',pointerEvents:'none', userSelect:'none'
                  }}
                >
                  {/* <CustomDropDown setSelectedModules={setSelectedModules} selectedModules={selectedModules}/> */}
                  <select
                    className='filter-select'
                    value={selectedOption}
                    onChange={handleOptionChange}
                  >
                    <option value="all">All</option>
                    {
                      moduleOptions?.map((mo, _io)=>(<option key={'recentactivityMo'+_io} value={mo.value}>{mo.label}</option>))
                    }
                    </select>
                  <select
                    className='filter-select'
                    value={selectedOption}
                    onChange={handleOptionChange}
                  >
                    <option value="today">Today</option>
                    <option value="yesterday">Yesterday</option>
                    <option value="past-7-days">Past 7 Days</option>
                    <option value="past-30-days">Past 30 Days</option>
                    <option value="past-60-days">Past 60 Days</option>
                    <option value="past-90-days">Past 90 Days</option>
                    <option value="past-6-months">Past 6 Months</option>
                    <option value="past-12-months">Past 12 Months</option>
                    <option value="this-month">This Month</option>
                    <option value="past-month">Past Month</option>
                    <option value="custom">Custom Range</option>
                  </select>
                  {selectedOption === "custom" && (<>
                    <div>
                      <DateRangePicker format="MM/dd/yyyy" character=" – " onChange={(e) => { setCustomRange({ start: e[0].toLocaleDateString(), end: e[1].toLocaleDateString() }) }} />

                    </div>
                    <div style={{ display: "flex", height: 35.2 }}>
                      <button
                        className="btn btn-primary btn-sm me-1"
                        style={{
                          padding: "8px 12px",
                          borderRadius: "5px",
                          border: "none",
                          cursor: "pointer",
                        }}
                        onClick={handleCustomRangeSubmit}
                      >
                        Apply
                      </button>
                    </div>
                  </>)
                  }
                </div>
              </div>
              <hr style={{ borderColor: '#f0f0f0' }} />
              <div class="message-container">
                    <h5>Get Started to See Your Activity!</h5>
                    <p>Your recent activity will show up here once you start exploring, engaging, and contributing to the community.</p>
                    <div class="actions">
                      <button class="btn  btn--light offer-btn w-100 text-white">Create Your First Blog Post</button>
                      <button class="mt-3 btn  btn--light justify-content-center px-md-3 px-2 d-flex align-items-center border-0 btn-primary w-100 btn-sm credit-btn">Join Your First Discussion</button>
                      <a href="/learn-more" class="btn btn-outline theme-color mt-3 btn--light ">Take Your First Exam</a>
                    </div>
                  </div>
              <div style={{ marginBottom: "20px",filter: 'blur(3px) grayscale(100%)',pointerEvents:'none', userSelect:'none' }} className='table-responsive'>

                <table className='table table-bordered rounded c--table'>
                  <thead>
                    <tr>
                      <th>Activity Page</th>
                      <th>Action Type</th>
                      <th>Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td><a href="/activity/questions">General Awareness in Current Affairs 2021</a>
                      <p className='mb-0 text-gray-400 small mt-1'>Question Bank</p>
                      </td>
                      <td>Like</td>
                      <td>2024-12-01</td>
                    </tr>
                    <tr>
                      <td><a href="/activity/iq-questions">IQ Test for GATE Exam Preparation</a>
                      <p className='mb-0 text-gray-400 small mt-1'>Interview Questions</p>
                      </td>
                      <td>Comment</td>
                      <td>2024-12-01</td>
                    </tr>
                    <tr>
                      <td><a href="/activity/exams">UPSC Civil Services Exam 2024 - Syllabus</a>
                      <p className='mb-0 text-gray-400 small mt-1'>Exams</p></td>
                      <td>Share</td>
                      <td>2024-12-02</td>
                    </tr>
                    <tr>
                      <td><a href="/activity/career-news">Latest Job Opportunities for Software Engineers</a><p className='mb-0 text-gray-400 small mt-1'>Career News</p></td>
                      <td>Like</td>
                      <td>2024-12-02</td>
                    </tr>
                    <tr>
                      <td><a href="/activity/forum">Discussion on Data Science Job Trends</a><p className='mb-0 text-gray-400 small mt-1'>Forum</p></td>
                      <td>View</td>
                      <td>2024-12-03</td>
                    </tr>
                    <tr>
                      <td><a href="/activity/blog">Exploring AI in Healthcare</a><p className='mb-0 text-gray-400 small mt-1'>Blogs</p></td>
                      <td>Read</td>
                      <td>2024-12-02</td>
                    </tr>
                     <tr>
                      <td><a href="/activity/quiz/javascript-basics">JavaScript Basics Quiz</a><p className='mb-0 text-gray-400 small mt-1'>Quizzes</p></td>
                      <td>Take Quiz</td>
                      <td>2024-12-01</td>
                    </tr>
                    <tr>
                      <td><a href="/activity/video/ai-for-beginners">AI for Beginners – Introductory Video</a><p className='mb-0 text-gray-400 small mt-1'>Videos</p></td>
                      <td>Watch</td>
                      <td>2024-12-01</td>
                    </tr>
                    <tr>
                      <td><a href="/activity/forum">The Future of Remote Work</a><p className='mb-0 text-gray-400 small mt-1'>Forum</p></td>
                      <td>Join Discussion</td>
                      <td>2024-12-03</td>
                    </tr>
                    {/* <tr>
                      <td><a href="/activity/article/digital-transformation">Digital Transformation in Business</a><p className='mb-0 text-gray-400 small mt-1'>Blogs</p></td>
                      <td>Read</td>
                      <td>2024-12-02</td>
                    </tr> */}
                  </tbody>
                </table>
                <hr style={{ borderColor: '#f0f0f0' }} />
                <a href="" className='theme-color'>More Activities</a>
              </div>
            </div>
           
          </div>
        </div>
      </>}

    </>
  )
}

export default NewUserDashboard
