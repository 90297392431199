import React, { useEffect, useState } from "react";
import CameraAlt from "@material-ui/icons/CameraAlt";
import FavoriteBorder from "@material-ui/icons/FavoriteBorder";
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import PolicyOutlinedIcon from '@material-ui/icons/PolicyOutlined';
import LiveHelpOutlinedIcon from '@material-ui/icons/LiveHelpOutlined';
import ContactSupportOutlinedIcon from '@material-ui/icons/ContactSupportOutlined';
import PermIdentityOutlinedIcon from '@material-ui/icons/PermIdentityOutlined';
import AccountBalanceWalletOutlinedIcon from '@material-ui/icons/AccountBalanceWalletOutlined';
import FlagOutlinedIcon from '@material-ui/icons/FlagOutlined';
import CardGiftcardOutlinedIcon from '@material-ui/icons/CardGiftcardOutlined';
import CardMembershipOutlinedIcon from '@material-ui/icons/CardMembershipOutlined';
import CheckIcon from '@material-ui/icons/Check';
import PaymentOutlinedIcon from '@material-ui/icons/PaymentOutlined';
import MoneyOutlinedIcon from '@material-ui/icons/MoneyOutlined';
import AlbumOutlinedIcon from '@material-ui/icons/AlbumOutlined';
import ReceiptOutlinedIcon from '@material-ui/icons/ReceiptOutlined';
import MarkunreadMailboxOutlinedIcon from '@material-ui/icons/MarkunreadMailboxOutlined';
import CompareArrowsOutlinedIcon from '@material-ui/icons/CompareArrowsOutlined';
import "react-tooltip/dist/react-tooltip.css";
import { Tooltip } from "react-tooltip";
import Mic from "@material-ui/icons/Mic";
import Home from "@material-ui/icons/Home";
import PowerSettingsNew from "@material-ui/icons/PowerSettingsNew";
import ListAlt from "@material-ui/icons/ListAlt";
import NotificationsIcon from '@material-ui/icons/Notifications';
import { useCookies } from "react-cookie";
import { CDN_BASE_URL, headerIcoUrls, nFormatter } from "../../../utils/utils";
import { useAuth } from "../../../Context/AuthContext";
import { Link, NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import { GetDataAuth } from "../../../api/feeds/GetDataAuth";
import { useFeeds } from "../../../Context/FeedsContext";
import { PostDataAuth } from "../../../api/feeds/PostDataAuth";
import { PostData } from "../../../api/feeds/PostData";
import { createWorker } from "tesseract.js";
import { CircularProgress } from "@material-ui/core";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import { useRef } from "react";
import { Modal } from "react-bootstrap";
import { useSearch } from "../../../Context/SearchContext";
import { AccountBalanceOutlined, HelpOutline, ListAltOutlined, InfoOutlined, PaymentOutlined, PolicyOutlined, ReceiptOutlined, RemoveCircleOutline } from "@material-ui/icons";
import { deleteAccount, getAllUnreadNotifications, getUserToken } from "../../../api/OurService";
import { Loadingicon } from "../../../AppIcons";
import { toast } from "react-toastify";

export default function HeaderAfterLogin({
  handleNavigation,
  sidebarLists,
  showHideLeftBar,
  showProfileMenu,
  setShowProfileMenu,
  handleCustomModal,
  showHideProfileMenu,
}) {
  const params = useParams();
  const location = useLocation();
  const paramLength = Object.keys(params).length;
  const [showMoreMenu, setShowMoreMenu] = useState(false);
  const [isDeleteAccountOpen, setIsDeleteAccountOpen] = useState(false);
  const [deleteAccountBtnLoader, setDeleteAccountBtnLoader] = useState(false);
  const [imageVoiceLoader, setImageVoiceLoader] = useState(false);
  const navigation = useNavigate();
  const [voiceDetectModal, setVoiceDetectModal] = useState(false);
  const { transcript, resetTranscript } = useSpeechRecognition();
  const [searchTerm, setSearchTerm] = useState();
  const {
    listData,
    setListData,
    currentPage,
    setCurrentPage,
    searchValue,
    setSearchValue,
  } = useSearch();

  const {
    setMyFav,
    setModalOpen,
    setFeeds,
    setMyFeeds,
    setSearch,
    loading,
    setLoading,
    feeds,
  } = useFeeds();
  const { handleLogout, user, gigToken, unreadNotifications, setUnreadNotifications, setGigtoken, isHeader, globalToken } = useAuth();
  const [hideTopHeader, setHideTopHeader] = useState(true);
  const [searchWord, setSearchWord] = useState("");
  const [placeholder, setPlaceholder] = useState("Search Feeds...");
  const [ocr, setOcr] = useState("");
  const [imageData, setImageData] = useState(null);
  const [isNotificationDropdown, setIsNotificationDropdown] = useState(false);
  const searchBox = useRef();
  const navigate = useNavigate();

  const showHideNotifiationMenu = ()=>{
    setIsNotificationDropdown(!isNotificationDropdown)
  }
  const convertImageToText = async () => {
    searchBox?.current?.focus();
    if (!imageData) return;
    setImageVoiceLoader(true);

    const worker = await createWorker({
      logger: (m) => {
        console.log(m);
      },
    });

    await worker.load();
    await worker.loadLanguage("eng");
    await worker.initialize("eng");
    const {
      data: { text },
    } = await worker.recognize(imageData);
    setSearchWord(text);
    setImageVoiceLoader(false);
  };

  const handleAccountDelete = async () =>{
   
    setDeleteAccountBtnLoader(true)
    const deleteAccountRes = await deleteAccount({token:globalToken, tuteehubId:user?.tuteehub_id});
    // console.log(deleteAccountRes)
    if(deleteAccountRes?.success){
      openHideDeleteAccountConfirmationModal();
      toast.success(deleteAccountRes?.message);
      handleLogout();

    }else{
      toast.error(deleteAccountRes?.message);
    }
    setDeleteAccountBtnLoader(false)
  }

  const handleVoiceDetectModal = () => {
    setVoiceDetectModal(!voiceDetectModal);
    SpeechRecognition.startListening();
    setTimeout(() => {
      SpeechRecognition.stopListening();
    }, 3000);

    setTimeout(() => {
      setSearchWord(transcript);
      searchBox?.current?.focus();
      setVoiceDetectModal(false);
    }, 4000);
  };

  // const _allFeed = async (pageNumber) => {
  //   if (
  //     localStorage.getItem("userDetails") &&
  //     localStorage.getItem("___fTkn")
  //   ) {
  //     const token = localStorage.getItem("___fTkn");
  //     await setApiToken(token);
  //     const allFeeds = await GetDataAuth(
  //       `api/fd/get/feed/list/via-authentic-user?page=${pageNumber}`,
  //       token
  //     );
  //     if (allFeeds?.current_page !== allFeeds?.last_page && pageNumber !== 1) {
  //       const mergedFeeds = [...feeds?.data, ...allFeeds?.data];
  //       setFeeds({ ...feeds, data: mergedFeeds, current_page: pageNumber });
  //     } else {
  //       setFeeds(allFeeds);
  //     }
  //   } else {
  //     const allFeeds = await GetData(`api/fd/get/feed/list?page=${pageNumber}`);
  //     if (allFeeds?.current_page !== allFeeds?.last_page && pageNumber !== 1) {
  //       const mergedFeeds = [...feeds?.data, ...allFeeds?.data];
  //       setFeeds({ ...feeds, data: mergedFeeds, current_page: pageNumber });
  //     } else {
  //       setFeeds(allFeeds);
  //     }
  //   }
  //   setLoading(false)
  // };
  const myAccountRef = useRef(null)
  const notificationRef = useRef(null);
  const myAccountDropdownRef = useRef(null)
  const myNotiDropdownRef = useRef(null);
  const handleBodyClick = (event) => {
   
    const clickedElement = event.target;

    // Check if the clicked element is the button or the div or its inner elements
    if (
      myAccountRef.current.contains(clickedElement) ||
      (myAccountDropdownRef.current && myAccountDropdownRef.current.contains(clickedElement))
    ) {
      // Clicked inside the button or the div or its inner elements
      
    }else{
      // showHideProfileMenu()
    }

  };
  

  const openHideDeleteAccountConfirmationModal = () =>{
    setIsDeleteAccountOpen(!isDeleteAccountOpen);
  }
  

  useEffect(() => {
    if(!gigToken?.token){
      // alert(1)
      // generateShortTaskToken();
    }
   
    document.body.addEventListener('click', handleBodyClick);
    document.addEventListener('click', function(event) {
      // Check if the clicked element does not have the class 'account-toggle'
      if (!event.target?.classList.contains('account-toggle')) {
       
        if (!event.target.closest('.account-toggle')) {
        
          setShowProfileMenu(false);
        }
      }
      if (!event.target?.classList.contains('noti-toggle')) {
        if (!event.target.closest('.noti-toggle')) {
          setIsNotificationDropdown(false);
        }
      }
      // else{

      // }
  });

    return () => {
      document.body.removeEventListener('click', handleBodyClick);
    };
  }, []);

  useEffect(() => {
    

    if (!params?.qName) {
      setSearchValue("");
    } else {
      setCurrentPage(1);
      setListData([]);
    }
    // setSearchWord("");
    handlePlaceholder();
    localStorage.removeItem("feedDetail");
  }, [navigation]);

  useEffect(() => {
    convertImageToText();
  }, [imageData]);

  function handleImageChange(e) {
    const file = e.target.files[0];
    if (!file) return;
    const reader = new FileReader();
    reader.onloadend = () => {
      const imageDataUri = reader.result;
      console.log({ imageDataUri });
      setImageData(imageDataUri);
    };
    reader.readAsDataURL(file);
  }

  const _favFeedList = async () => {
   

    setMyFeeds(false);
    setSearch(false);
    if (localStorage.getItem("userDetails")) {
      // const aToken = localStorage.getItem("___fTkn");
      const favListDataReturn = await GetDataAuth(
        `feed-favourite/list`,
        globalToken
      );

      if (favListDataReturn?.success) {
        setMyFav(true);
        setFeeds(favListDataReturn);
        localStorage.removeItem("feedDetail");
      } else {
        setMyFav(false);
        setModalOpen(true);
      }
    } else {
      setMyFav(false);
      setModalOpen(true);
    }
    
    setLoading(false);
    navigation("/feeds");
  };

  const handlePlaceholder = () => {
    
    const queryParams = new URLSearchParams(location.search);
    if(queryParams?.get('searchTerm'))
    setSearchWord(queryParams?.get('searchTerm'));
  
    if (paramLength === 0 || params?.slug) {
      setPlaceholder("Search Feeds...");
    }
    if (
      params?.name === "question-Bank" ||
      (params?.name === "question-Bank" &&
        (params?.catName || params?.subCatName || params?.qName))
    ) {
      setPlaceholder("Search Solved Questions Across Any Subject Or Topic...");
    }

    if (
      location?.pathname.includes('/corporates')
    ) {
      setPlaceholder("Search Companies Across Any Categories ...");
    }

    if (
      location?.pathname.includes('/forum')
    ) {
      setPlaceholder("Search Questions");
    }

    if (
      location?.pathname.includes('/gigs') ||
      (location?.pathname.includes('/gigs') &&
        (params?.catName || params?.subCatName || params?.qName))
    ) {
      setPlaceholder("What's on your mind?");
    }

    if (
      location?.pathname.includes('/lyrics')
    ) {
      setPlaceholder("Search Lyrics By Artist, Lyricist Or Album ...");
    }
    if (
      location?.pathname.includes('/exams')
    ) {
      setPlaceholder("Search Exams Across Any Subject Or Topic ...");
    }

    if (
      location?.pathname.includes('/quotes')
    ) {
      setPlaceholder("Search Quotes on any Topic or by any Author ...");
    }

    if (
      location?.pathname.includes('/educators')
    ) {
      setPlaceholder("Search Educators Across Any Categories ...");
    }

    if (
      location?.pathname.includes('/blogs')
    ) {
      setPlaceholder("Search Blogs");
    }

    if (
      location?.pathname.includes('/career-news')
    ) {
      const currentYear = new Date().getFullYear();
      setPlaceholder(`Search for Top Exams, Job Alerts, Syllabus, Pattern, Admit Card, Result News of ${currentYear}`);
    }
    if (
      params?.name === "interview-questions" ||
      (params?.name === "interview-questions" &&
        (params?.catName || params?.subCatName || params?.qName))
    ) {
      setPlaceholder("Find Answers to Job Interview Questions...");
    }
    if (
      params?.name === "fullforms" ||
      (params?.name === "fullforms" &&
        (params?.catName || params?.subCatName || params?.qName))
    ) {
      setPlaceholder("Search Full Forms Across Any Categories...");
    }
    if (
      params?.name === "dictionary" ||
      (params?.name === "dictionary" &&
        (params?.catName || params?.subCatName || params?.qName))
    ) {
      setPlaceholder("Search Dictionary/Word Meaning Across Any Categories...");
    }
    if (
      location?.pathname.includes('/tutorials') ||
      (location?.pathname.includes('/tutorials') &&
        (params?.catName || params?.subCatName || params?.qName))
    ) {
      setPlaceholder("Search Tutorial");
    }
  };
  const searchFeeds = async (searchText) => {
    // resetTranscript();
    // setImageVoiceLoader(true);
    navigation("/feeds");
    setMyFeeds(false);
    setMyFav(false);
    setLoading(true);
    let returnSearchResult = null;
    // const apiToken = localStorage.getItem("___fTkn");

    if (globalToken) {
      returnSearchResult = await PostDataAuth(
        `search/feed/list/via-authentic-user`,
        globalToken,
        { keyword: searchText }
      );
    } else {
      returnSearchResult = await PostData(`search/feed/list`, "", {
        keyword: searchText,
      });
    }
    if (searchText === "") {      
      const pageNumber = 1;
      const allFeeds = await GetDataAuth(
        `get/feed/list/via-authentic-user?page=${pageNumber}`,
        globalToken
      );
      if (allFeeds?.current_page !== allFeeds?.last_page && pageNumber !== 1) {
        const mergedFeeds = [...feeds?.data, ...allFeeds?.data];
        setFeeds({ ...feeds, data: mergedFeeds, current_page: pageNumber });
      } else {
        setFeeds(allFeeds);
      }
      setImageVoiceLoader(false);
    } else {
      setFeeds(returnSearchResult);
      setImageVoiceLoader(false);
    }
    setSearch(true);
    setLoading(false);
    localStorage.removeItem("feedDetail");
  };

  const handleMyFeeds = async () => {
    

    setMyFav(false);
    setSearch(false);

    if (localStorage.getItem("userDetails")) {
      // const aToken = localStorage.getItem("___fTkn");
      const returnMyFeeds = await GetDataAuth(`feed/my`, globalToken);
      if (returnMyFeeds?.success) {
        setFeeds(returnMyFeeds);
        setMyFeeds(true);
        localStorage.removeItem("feedDetail");
      } else {
        setMyFeeds(true);
      }
    } else {
      console.log("");
    }
    setLoading(false);
    navigation("/feeds");
  };

  const showHideMoreMenu = () => {
    setShowMoreMenu(!showMoreMenu);
  };

  const handleSearchOnClick = (searchValue) => {
    
    if (location?.pathname.includes('/career-news')) {
        setSearchValue(searchValue);
        setCurrentPage(1);
        setListData([]);
        navigate(`career-news/search-news?searchTerm=${searchValue}`)
    }
    if (location?.pathname.includes('/exams')) {
      setSearchValue(searchValue);
      setCurrentPage(1);
      setListData([]);
      navigate(`exams/search-exams?searchTerm=${searchValue}`)
  }
    if (location?.pathname.includes('/corporates')) {
        setSearchValue(searchValue);
        setCurrentPage(1);
        setListData([]);
        navigate(`corporates/search-companies?searchTerm=${searchValue}`)
    }
    if (location?.pathname.includes('/blogs')) {
      setSearchValue(searchValue);
      setCurrentPage(1);
      setListData([]);
      navigate(`blogs/search-blogs?searchTerm=${searchValue}`)
  }
  if (location?.pathname.includes('/educators')) {
    setSearchValue(searchValue);
    setCurrentPage(1);
    setListData([]);
    navigate(`educators/search-educators?searchTerm=${searchValue}`)
  }
  if (location?.pathname.includes('/tutorials')) {
    setSearchValue(searchValue);
    setCurrentPage(1);
    setListData([]);
    navigate(`tutorials/search-tutorials?searchTerm=${searchValue}`)
  }
  if (location?.pathname.includes('/forum')) {
    setSearchValue(searchValue);
    setCurrentPage(1);
    setListData([]);
    navigate(`forum/search-forum?searchTerm=${searchValue}`)
  }
  if (location?.pathname.includes('/lyrics')) {
    setSearchValue(searchValue);
    setCurrentPage(1);
    setListData([]);
    navigate(`lyrics/search-album?searchTerm=${searchValue}`)
  }
  if (location?.pathname.includes('/quotes')) {
    setSearchValue(searchValue);
    setCurrentPage(1);
    setListData([]);
    navigate(`quotes/search-topics?searchTerm=${searchValue}`)
  }
  }

  const handleSearchOnKeypup = (e) => {
    if (params?.name && !location?.pathname.includes('/career-news')) {
      if (e.key === "Enter") {
        setSearchValue(e.target.value);
        setCurrentPage(1);
        setListData([]);
      }
      return;
    }
    if (location?.pathname.includes('/career-news')) {
      if (e.key === "Enter") {
        setSearchValue(e.target.value || 'c');
        setCurrentPage(1);
        setListData([]);
        navigate(`career-news/search-news?searchTerm=${e.target.value}`)
      }
    }
    if (location?.pathname.includes('/exams')) {
      if (e.key === "Enter") {
        setSearchValue(e.target.value || 'c');
        setCurrentPage(1);
        setListData([]);
        navigate(`exams/search-exams?searchTerm=${e.target.value}`)
      }
    }

    if (location?.pathname.includes('/corporates')) {
      if (e.key === "Enter") {
        setSearchValue(e.target.value || 'c');
        setCurrentPage(1);
        setListData([]);
        navigate(`corporates/search-companies?searchTerm=${e.target.value}`)
      }
    }

    if (location?.pathname.includes('/blogs')) {
      if (e.key === "Enter") {
        setSearchValue(e.target.value || 'b');
        setCurrentPage(1);
        setListData([]);
        navigate(`blogs/search-blogs?searchTerm=${e.target.value}`)
      }
    }

    if (location?.pathname.includes('/educators')) {
      if (e.key === "Enter") {
        setSearchValue(e.target.value || 'e');
        setCurrentPage(1);
        setListData([]);
        navigate(`educators/search-educators?searchTerm=${e.target.value}`)
      }
    }

    if (location?.pathname.includes('/tutorials')) {
      if (e.key === "Enter") {
        setSearchValue(e.target.value || 't');
        setCurrentPage(1);
        setListData([]);
        navigate(`tutorials/search-tutorials?searchTerm=${e.target.value}`)
      }
    }
    if (location?.pathname.includes('/forum')) {
      if (e.key === "Enter") {
        setSearchValue(e.target.value || 'f');
        setCurrentPage(1);
        setListData([]);
        navigate(`forum/search-forum?searchTerm=${e.target.value}`)
      }
    }
    if (location?.pathname.includes('/lyrics')) {
      if (e.key === "Enter") {
        setSearchValue(e.target.value || 'l');
        setCurrentPage(1);
        setListData([]);
        navigate(`lyrics/search-album?searchTerm=${e.target.value}`)
      }
    }
    if (location?.pathname.includes('/quotes')) {
      if (e.key === "Enter") {
        setSearchValue(e.target.value || 'q');
        setCurrentPage(1);
        setListData([]);
        navigate(`quotes/search-topics?searchTerm=${e.target.value}`)
      }
    }

    if ((paramLength === 0 || params?.slug) && (location?.pathname.includes('/feeds') || location?.pathname=='/')) {      
      setSearchWord(e.target.value);
      if (e.key === "Enter") {
        searchFeeds(e.target.value);
      }
    }
  };

  

const handleNotificationClick = (notification) => {
  localStorage.setItem('noti', JSON.stringify(notification));
  setIsNotificationDropdown(false)
  navigate('/gigs/notifications');
}

const getNotificationCount = () =>{
  let nCount = 0;
  if(unreadNotifications?.notifications && unreadNotifications?.notifications?.length > 0){
    nCount = unreadNotifications?.notifications.filter((f)=>f.viewed==0)?.length;
  }
  return nCount;
}

const toggleDropdown = () => {
    setIsNotificationDropdown(!isNotificationDropdown); // Toggle the dropdown state
 
};

  return (
    <>
      <div className="d-flex align-items-center justify-content-between px-3 py-2 sticky-head sticky-top bg-white" style={{opacity:isHeader?1:0}}>
        <span className="d-flex align-items-center flex-1">
          <div style={{ width: 43, height: 43 }} className="logo-container">
            <Link to={"/"}>
              <img
                src={require(`../../../assets/images/logo_other_platform.webp`)}
                alt="logo.png"
                width={43}
                height={43}
                role="button"
                layout="fixed"
              />
            </Link>
          </div>

          {/* added searhbar inside */}
          <span className="d-flex align-items-center flex-2 px-md-4 px-2 justify-content-between header-centers d-nones d-flex">
            <div className="search-box flex-1 m-0 px-2 d-flex align-items-center">
              <input
                type="text"
                ref={searchBox}
                onKeyUp={(e) => handleSearchOnKeypup(e)}
                value={searchWord}
                onChange={(e) => setSearchWord(e.target.value)}
                autoComplete="chrome-off"
                className="searchInput px-md-3 px-0 form-control border-0 bg-transparent outline-0"
                placeholder={placeholder}
              />
              <i
            className="fa fa-search opacity-1 cursor-pointer"
            style={{ color: "#ff7200", fontSize: '20px' }}
            onClick={() => handleSearchOnClick(searchWord)}
          ></i>
              {imageVoiceLoader ? (
                <CircularProgress
                  size={15}
                  color={"inherit"}
                  className="ms-2"
                />
              ) : null}
              {/* <input
              type="email"
              tabIndex={0}
              readOnly
              style={{ opacity: 0, width: 0, height: 0, position: "absolute" }}
            /> */}
              {/*  */}
              {/* <span className="d-flex align-items-center d-md-none position-relative">
                <Mic
                  className="me-md-3 me-1 theme-color cursor-pointer"
                  style={{ fill: "rgb(255 157 157)" }}
                  onClick={() => handleVoiceDetectModal()}
                />{" "}
                <CameraAlt
                  className="me-md-2 me-0 theme-color cursor-pointer"
                  style={{ fill: "rgb(255 157 157)" }}
                />
                <input
                  type="file"
                  name=""
                  id=""
                  onChange={handleImageChange}
                  accept="image/*"
                  style={{
                    opacity: 0,
                    width: 20,
                    height: 20,
                    right: 11,
                    position: "absolute",
                  }}
                />
              </span> */}
              {/* <span className="d-flex align-items-center d-none d-md-block">
                <Mic
                  className="me-2 theme-color cursor-pointer"
                  style={{ fill: "rgb(255 157 157)" }}
                  onClick={() => handleVoiceDetectModal()}
                />{" "}
                <CameraAlt
                  className="me-2 theme-color cursor-pointer"
                  style={{ fill: "rgb(255 157 157)" }}
                />
                <input
                  type="file"
                  name=""
                  id=""
                  onChange={handleImageChange}
                  accept="image/*"
                  style={{
                    opacity: 0,
                    width: 20,
                    height: 20,
                    marginLeft: -30,
                    position: "absolute",
                  }}
                />
              </span> */}
            </div>
            

            {showMoreMenu ? (
              <ul className="more-menu px-4 libraries-dropdown">
                {sidebarLists
                  ? sidebarLists.map((sList, i) => (
                      <li key={'sidebar_'+i}>
                        <div
                          onClick={(e) => handleNavigation(sList.path, e)}
                          className="text-decoration-none w-100 d-flex p-2 cursor-pointer"
                        >
                          {sList?.path === "full-forms" ? (
                            <span
                              className="sidebar_ico ff d-block"
                              style={{
                                filter:
                                  "drop-shadow(2px 4px 1px rgba(0, 0, 0, .1))",
                              }}
                            ></span>
                          ) : sList?.path === "career-news" ? (
                            <svg
                              width="30"
                              height="29"
                              viewBox="0 0 30 29"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              style={{
                                fill: "none",
                                height: 23,
                                opacity: 0.9,
                                filter:
                                  "drop-shadow(2px 4px 1px rgba(0, 0, 0, .1))",
                              }}
                            >
                              <path
                                d="M11 28H29"
                                stroke="#ff1ec4"
                                strokeWidth="2"
                                strokeLinecap="round"
                              ></path>
                              <path
                                d="M3.73 22.042C3.086 22.042 2.764 21.867 2.764 21.517V7.783C2.764 7.433 3.086 7.258 3.73 7.258H4.297C4.563 7.258 4.773 7.293 4.927 7.363C5.081 7.419 5.214 7.531 5.326 7.699L11.374 16.897C11.528 17.107 11.661 17.31 11.773 17.506C11.885 17.702 11.99 17.912 12.088 18.136L12.172 18.115C12.158 17.877 12.144 17.66 12.13 17.464C12.13 17.254 12.13 16.995 12.13 16.687V7.783C12.13 7.433 12.452 7.258 13.096 7.258H13.369C14.013 7.258 14.335 7.433 14.335 7.783V21.517C14.335 21.867 14.013 22.042 13.369 22.042H12.844C12.62 22.042 12.431 22.014 12.277 21.958C12.137 21.902 12.018 21.804 11.92 21.664L5.725 12.277C5.585 12.067 5.459 11.864 5.347 11.668C5.235 11.458 5.123 11.241 5.011 11.017L4.927 11.038C4.955 11.276 4.969 11.5 4.969 11.71C4.969 11.906 4.969 12.158 4.969 12.466V21.517C4.969 21.867 4.647 22.042 4.003 22.042H3.73ZM18.7188 22C18.2568 22 18.0258 21.769 18.0258 21.307V7.993C18.0258 7.531 18.2568 7.3 18.7188 7.3H26.0478C26.3698 7.3 26.5308 7.552 26.5308 8.056V8.476C26.5308 8.98 26.3698 9.232 26.0478 9.232H20.2728V13.516H25.4388C25.7608 13.516 25.9218 13.768 25.9218 14.272V14.692C25.9218 15.196 25.7608 15.448 25.4388 15.448H20.2728V20.068H26.0688C26.3908 20.068 26.5518 20.32 26.5518 20.824V21.244C26.5518 21.748 26.3908 22 26.0688 22H18.7188Z"
                                fill="#0a99ff"
                              ></path>
                              <path
                                d="M1 1H19"
                                stroke="black"
                                strokeWidth="2"
                                strokeLinecap="round"
                              ></path>
                            </svg>
                          ) : sList?.path === "video" ? (
                            <svg
                              width="30"
                              height="29"
                              viewBox="0 0 30 29"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              style={{
                                fill: "none",
                                height: 23,
                                opacity: 0.9,
                                filter:
                                  "drop-shadow(2px 4px 1px rgba(0, 0, 0, .1))",
                              }}
                            >
                              <path
                                d="M1 3V26C1 27.1046 1.89543 28 3 28H27C28.1046 28 29 27.1046 29 26V3C29 1.89543 28.1046 1 27 1H3C1.89543 1 1 1.89543 1 3Z"
                                stroke="#ef00ff"
                                strokeWidth="2"
                              ></path>
                              <path
                                d="M6 23H24"
                                stroke="black"
                                strokeWidth="2"
                                strokeLinecap="round"
                              ></path>
                              <path
                                d="M19.9 10.7874C20.8333 11.3263 20.8333 12.6735 19.9 13.2123L13.6 16.8496C12.6667 17.3885 11.5 16.7149 11.5 15.6372V8.36257C11.5 7.28485 12.6667 6.61128 13.6 7.15014L19.9 10.7874Z"
                                fill="#ff00f7"
                              ></path>
                            </svg>
                          ) : sList?.path === "feeds" ? (
                            <svg
                              width="30"
                              height="29"
                              viewBox="0 0 30 29"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              style={{
                                fill: "none",
                                height: 23,
                                opacity: 0.9,
                                filter:
                                  "drop-shadow(2px 4px 1px rgba(0, 0, 0, .1))",
                              }}
                            >
                              <path
                                d="M1 3V26C1 27.1046 1.89543 28 3 28H27C28.1046 28 29 27.1046 29 26V3C29 1.89543 28.1046 1 27 1H3C1.89543 1 1 1.89543 1 3Z"
                                stroke="#b973ff"
                                strokeWidth="2"
                              ></path>
                              <path
                                d="M6 10H24"
                                stroke="#e589a3"
                                strokeWidth="5"
                                strokeLinecap="round"
                              ></path>
                              <path
                                d="M6 16H24"
                                stroke="#e589a3"
                                strokeWidth="2"
                                strokeLinecap="round"
                              ></path>
                              <path
                                d="M6 20H20"
                                stroke="#e589a3"
                                strokeWidth="2"
                                strokeLinecap="round"
                              ></path>
                            </svg>
                          ) : (
                            <img
                              src={sList?.icon}
                              width={24}
                              height={24}
                              className="siderbarIco"
                            />
                          )}
                          <span className="ms-2">{sList?.name}</span>
                        </div>
                      </li>
                    ))
                  : null}
              </ul>
            ) : null}
          </span>
          {/* added searchbar inside */}
        </span>

        <div className="d-flex align-items-center flex-11 lost-flex-1 justify-content-end">
          <svg
            className="gb_We show-features d-md-none d-block me-0 me-md-2"
            style={{ width: 30, fill: "#ccc" }}
            onClick={() => showHideLeftBar()}
            role="button"
            focusable="false"
            viewBox="0 0 24 24"
          >
            <path d="M6,8c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM12,20c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM6,20c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM6,14c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM12,14c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM16,6c0,1.1 0.9,2 2,2s2,-0.9 2,-2 -0.9,-2 -2,-2 -2,0.9 -2,2zM12,8c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM18,14c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM18,20c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2z"></path>
          </svg>
          {/* <Link href="https://tuteehub.com/how-it-works">
            <a
              target={"_blank"}
              className="text-black-50 theme-color d-md-block d-none text-decoration-none mx-2 fsize-12"
            >
              How It Works
            </a>
          </Link> */}
          {/* {!user ? (
            <div className="d-md-block d-none">
              <Link href="https://tuteehub.com/multiuser-register">
                <a
                  target={"_blank"}
                  className="text-black-50 theme-color text-decoration-none mx-2 fsize-12"
                >
                  Sign Up
                </a>
              </Link>
              <span
                className="theme-color text-decoration-none mx-2 fsize-12 sign-in-btn rounded"
                role="button"
                onClick={()=>setModalOpen(!modalOpen)}
              >
                Sign In
              </span>
            </div>
          ) : null} */}
          {/* <Link href="https://play.google.com/store/apps/details?id=com.tuteehub.babble"><a target="_blank"><CommentOutlined className="me-3" style={{opacity:0.5}}/></a></Link> */}
          {/* <svg
            className="gb_We show-features d-md-none d-block"
            style={{ width: 30, fill: "#ccc" }}
            onClick={() => showHideLeftBar()}
            role="button"
            focusable="false"
            viewBox="0 0 24 24"
          >
            <path d="M6,8c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM12,20c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM6,20c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM6,14c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM12,14c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM16,6c0,1.1 0.9,2 2,2s2,-0.9 2,-2 -0.9,-2 -2,-2 -2,0.9 -2,2zM12,8c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM18,14c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM18,20c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2z"></path>
          </svg>
          {!user ? (
            <Person
              className="d-block d-md-none"
              style={{ marginLeft: 5, color: "#ccc" }}
              onClick={()=>setModalOpen(!modalOpen)}
            />
          ) : null} */}
          {hideTopHeader ? (<>
            <div className="btn-group">
            <div className="d-flex align-item-center justify-content-between">
            {/* <div className="d-md-flex d-none align-items-center me-2">
                 <button onClick={()=>navigation('/offers')} className="btn offer-btn justify-content-center px-4 me-2 d-flex align-items-center border-0 btn-primarys w-100 btn-sm credit-btn">       <img src={require('../../../assets/images/discount.png')} width={20} height={20} />Offers</button>
            
            </div> */}
            <div className="d-md-flex d-none w-100 align-items-center">
    
                <button onClick={()=>{navigation('/credit-history');window.location.reload();}} className="btn justify-content-center px-md-3 px-2 me-2 d-flex align-items-center border-0 btn-primary w-100 btn-sm credit-btn">
                    <img src={require('../../../assets/images/star_white.png')} width={20} height={20} />
                    {nFormatter(user?.credits)} Credits</button>
            </div>
            <div className="position-relative">
                <button  className="btn btn-default dropdown-toggle notification-dropdown" onClick={()=>{navigate('/inbox')}} type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                  <i className="fa fa-envelope"></i>
                  {/* <span className="badge">{0}</span> */}
                </button>
            </div>
            <div className="dropdown">
              {/* {
              location?.pathname!=='/gigs/notifications'?  */}
              <button className="btn btn-default px-md-3 px-2 dropdown-toggle noti-toggle  notification-dropdown" onClick={toggleDropdown} type="button" id="dropdownMenuButton1" aria-expanded={isNotificationDropdown ? 'true' : 'false'} >
              <i className="fa fa-bell"></i>
              <span className="badge">{getNotificationCount() || 0}</span>
            </button>
            {/* :null
              } */}
            
              {
                isNotificationDropdown?
                <ul className="profile-dropdowns mw-300 mt-3 p-0" style={{maxHeight:'calc(100vh - 15vh)',overflow:'auto'}}>
                  {
                    unreadNotifications?.notifications?.length?unreadNotifications?.notifications.filter((f)=>f.viewed==0).map((notification, index)=>(
                      <li className="group-title p-0 n-title m-0" key={'unread-notifcation'+index}>
                        <a onClick={()=>handleNotificationClick(notification)} className="d-flex py-2 px-3 align-items-center">
                        <NotificationsIcon
                              role="button"
                              className="me-2"
                              style={{ color: "#ccc",width:18,height:18,marginTop:2  }}
                            />{" "}
                          {notification?.notification_text}</a>
                      </li>
                    )):null
                  }
                
               
                <li className="group-title p-0 n-title m-0 p-0">
                  <hr className="m-0"/>
                  <a onClick={()=>{setIsNotificationDropdown(false);navigate('/gigs/notifications');}} className="w-100 btn py-2 btn-outline btn-default text-center p-0 text-theme-color">
                    View All
                  </a>
                </li>
            </ul>:null
              }
             
            </div>
        </div>
              <button
                ref={myAccountRef}
                type="button"
                className="btn btn-transparent account-toggle pe-md-3 pe-0 d-flex align-items-center dropdown-toggle outline-0 shadow-0"
                onClick={() => showHideProfileMenu()}
                style={{ outline: "none", boxShadow: "none" }}
              >
                
                <img
                  src={
                    user?.profile_image?user?.profile_image:require(`../../../assets/images/profile_placeholder.png`)
                  }
                  width={28}
                  height={28}
                  className="siderbarIco"
                  layout="fixed"
                  style={{ borderRadius: 100, width:28, height:28 }}
                />{" "}
                <span className="ms-2 text-capitalize d-none d-md-block">
                  {user?.name}
                </span>
                {}
              </button>

              {showProfileMenu ? (
                <ul ref={myAccountDropdownRef} className="profile-dropdowns" style={{maxHeight:'calc(100vh - 15vh)',overflow:'auto'}}>
                  {/* <li className="d-md-none d-block mb-2">
                  <div className="d-flex w-100 align-items-center">
                  <button onClick={()=>navigation('/offers')} className="btn offer-btn btn-block justify-content-center px-4 me-md-2 d-flex align-items-center border-0 btn-primarys w-100 btn-sm credit-btn">       <img src={require('../../../assets/images/discount.png')} width={20} height={20} />Offers</button>
            
                  </div>
                  </li> */}
                  <li className="d-md-none d-block">
                  <div className="d-flex w-100 align-items-center">
                      <button onClick={()=>navigation('/credit-history')} className="btn justify-content-center px-3 me-md-2 d-flex align-items-center border-0 btn-primary w-100 btn-sm credit-btn">
                          <img src={require('../../../assets/images/star_white.png')} width={20} height={20} />
                          {nFormatter(user?.credits)} Credits</button>
                  </div>
                  
                  </li>
                  <li className="group-title">My Accounts</li>
                  <li>
                    <button
                      className="dropdown-item py-1 my-1 ms-2"
                      type="button"
                      onClick={() => {
                        // navigation("myprofile");
                        navigate(`/my-profile`)
                        showHideProfileMenu();
                      }}
                    >
                      <PermIdentityOutlinedIcon style={{ color: "#ccc",width:18,height:18,marginTop:-2 }} /> My Profile
                    </button>
                  </li>
                  <li>
                    <button
                      className="dropdown-item py-1 my-1"
                      type="button"
                      onClick={() => {
                        handleMyFeeds();
                        showHideProfileMenu();
                      }}
                    >
                      <ListAlt
                        role="button"
                        className="ms-2"
                        style={{ color: "#ccc",width:18,height:18,marginTop:-2  }}
                      />{" "}
                      My Feeds
                    </button>
                  </li>
                  <li>
                    <button
                      className="dropdown-item py-1 my-1 ms-2"
                      type="button"
                      onClick={() => {
                        navigate(`/my-favourites`)
                        showHideProfileMenu();
                      }}
                    >
                      <FavoriteBorder style={{ color: "#ccc",width:18,height:18,marginTop:-2 }} /> My Favourites
                    </button>
                  </li>
                  <li>
                    <button
                      className="dropdown-item py-1 my-1 ms-2"
                      type="button"
                      onClick={() => {
                        _favFeedList();
                        showHideProfileMenu();
                      }}
                    >
                      <FavoriteBorder style={{ color: "#ccc",width:18,height:18,marginTop:-2 }} /> My Favourites Feeds
                    </button>
                  </li>
                  <li>
                    <button
                        className="dropdown-item py-1 my-1"
                        type="button"
                        onClick={() => {
                          navigation('profile-verification')
                          window.location.reload();
                          showHideProfileMenu();
                        }}
                      >
                        <CheckIcon
                          role="button"
                          className="ms-2"
                          style={{ color: "#ccc",width:18,height:18,marginTop:-2 }}
                        />{" "}
                        Profile Verification
                      </button>
                    {/* <a
                      className="dropdown-item py-1 my-1 ms-2"
                      type="button"
                      target="_blank"
                      href="https://showtalentapp.tuteehub.com"
                    >
                      <CheckIcon style={{ color: "#ccc",width:18,height:18,marginTop:-2 }} /> Profile
                      Verification
                    </a> */}
                  </li>
                  <li>
                    <button
                      className="dropdown-item py-1 my-1 ms-2"
                      type="button"
                      onClick={() => {
                        navigation("leaderboard");
                        window.location.reload();
                        showHideProfileMenu();
                      }}
                    >
                      <FlagOutlinedIcon style={{ color: "#ccc",width:18,height:18,marginTop:-2 }} /> Leaderboard
                    </button>
                  </li>
                  <div className="d-none">
                  <li>
                    <button
                      className="dropdown-item py-1 my-1 ms-2"
                      type="button"
                      onClick={() => {
                        navigation("payment-details");
                        showHideProfileMenu();
                      }}
                    >
                      <PaymentOutlined style={{ color: "#ccc",width:18,height:18,marginTop:-2 }} /> Payment Details
                    </button>
                  </li>
                  
                  <li>
                    <button
                      className="dropdown-item py-1 my-1 ms-2"
                      type="button"
                      onClick={() => {
                        navigation('withdrawal')
                        window.location.reload();
                        showHideProfileMenu();
                      }}
                    >
                      <MarkunreadMailboxOutlinedIcon style={{ color: "#ccc",width:18,height:18,marginTop:-2 }}/> Withdrawal
                      Money
                    </button>
                  </li>
                  <li>
                    <button
                      className="dropdown-item py-1 my-1"
                      type="button"
                      onClick={() => {
                        navigation('transaction-history');
                        window.location.reload();
                        showHideProfileMenu();
                      }}
                    >
                    <CompareArrowsOutlinedIcon
                        role="button"
                        className="ms-2"
                        style={{ color: "#ccc",width:18,height:18,marginTop:-2 }}
                      />{" "}
                      Transaction History
                    </button>
                  </li>
                  <li className="group-title">Credit Management</li>
                  <li>
                    <button
                      className="dropdown-item py-1 my-1"
                      type="button"
                      onClick={() => {
                        navigation('purchase-credits')
                        window.location.reload();
                        showHideProfileMenu();
                      }}
                    >
                      <AlbumOutlinedIcon
                        role="button"
                        className="ms-2"
                        style={{ color: "#ccc",width:18,height:18,marginTop:-2 }}
                      />{" "}
                      Purchase Credits
                    </button>
                  </li>
                  <li>
                    <button
                      className="dropdown-item py-1 my-1"
                      type="button"
                      onClick={() => {
                        navigation('credit-history')
                        window.location.reload();
                        showHideProfileMenu();
                      }}
                    >
                      <ReceiptOutlined
                        role="button"
                        className="ms-2"
                        style={{ color: "#ccc",width:18,height:18,marginTop:-2 }}
                      />{" "}
                      Credit History
                    </button>
                  </li>
                  <li>
                    <button
                      className="dropdown-item py-1 my-1"
                      type="button"
                      onClick={() => {
                        navigation('credit-purchase-history')
                        window.location.reload();
                        showHideProfileMenu();
                      }}
                    >
                      <CardMembershipOutlinedIcon
                        role="button"
                        className="ms-2"
                        style={{ color: "#ccc",width:18,height:18,marginTop:-2 }}
                      />{" "}
                      Credit Purchase History
                    </button>
                  </li>
                  <li>
                    <button
                      className="dropdown-item py-1 my-1"
                      type="button"
                      onClick={() => {
                        navigation('claim-credits')
                        window.location.reload();
                        showHideProfileMenu();
                      }}
                    >
                      <CardGiftcardOutlinedIcon
                        role="button"
                        className="ms-2"
                        style={{ color: "#ccc",width:18,height:18,marginTop:-2 }}
                      />{" "}
                      Claim Credits
                    </button>
                  </li>
                  </div>
                  <div className="d-none">
                  <li className="group-title">Gigs</li>
                  <li>
                    <button
                      className="dropdown-item py-1 my-1"
                      type="button"
                      onClick={() => {
                        navigation('/gigs/tasks')
                      }}
                      // onClick={() => {
                      //   handleMyFeeds();
                      //   showHideProfileMenu();
                      // }}
                      // href={"/gigs/tasks"}
                    >
                      <ListAltOutlined
                        role="button"
                        className="ms-2"
                        style={{ color: "#ccc",width:18,height:18,marginTop:-2 }}
                      />{" "}
                      Tasks
                    </button>
                  </li>
                  <li>
                    <button
                      className="dropdown-item py-1 my-1"
                      type="button"
                      // onClick={() => {
                      //   handleMyFeeds();
                      //   showHideProfileMenu();
                      // }}
                      // href={"/gigs/create-campaign"}
                      onClick={() => {
                        navigation('/gigs/create-campaign')
                      }}
                    >
                      <ListAltOutlined
                        role="button"
                        className="ms-2"
                        style={{ color: "#ccc",width:18,height:18,marginTop:-2 }}
                      />{" "}
                      Create Campaign
                    </button>
                  </li>
                  <li>
                    <button
                      className="dropdown-item py-1 my-1"
                      type="button"
                      // onClick={() => {
                      //   handleMyFeeds();
                      //   showHideProfileMenu();
                      // }}
                      // href={"/gigs/my-campaign"}
                      onClick={() => {
                        navigation('/gigs/my-campaign')
                      }}
                    >
                      <ListAltOutlined
                        role="button"
                        className="ms-2"
                        style={{ color: "#ccc",width:18,height:18,marginTop:-2 }}
                      />{" "}
                      My Campaign
                    </button>
                  </li>
                  <li>
                    <button
                      className="dropdown-item py-1 my-1"
                      type="button"
                      // onClick={() => {
                      //   handleMyFeeds();
                      //   showHideProfileMenu();
                      // }}
                      // href={"/gigs/accepted-task"}
                      onClick={() => {
                        navigation('/gigs/my-task')
                      }}
                    >
                      <ListAltOutlined
                        role="button"
                        className="ms-2"
                        style={{ color: "#ccc",width:18,height:18,marginTop:-2 }}
                      />{" "}
                      My Task
                    </button>
                  </li>
                  <li>
                    <button
                      className="dropdown-item py-1 my-1"
                      type="button"
                      // onClick={() => {
                      //   handleMyFeeds();
                      //   showHideProfileMenu();
                      // }}
                      // href={"/gigs/review-task"}
                      onClick={() => {
                        navigation('/gigs/review-task')
                      }}
                    >
                      <ListAltOutlined
                        role="button"
                        className="ms-2"
                        style={{ color: "#ccc",width:18,height:18,marginTop:-2 }}
                      />{" "}
                      Review Task
                    </button>
                  </li>
                  <li>
                    <button
                      className="dropdown-item py-1 my-1"
                      type="button"
                      // onClick={() => {
                      //   handleMyFeeds();
                      //   showHideProfileMenu();
                      // }}
                      // href={"/gigs/favourite-tasks"}
                      onClick={() => {
                        navigation('/gigs/favourite-campaigns')
                      }}
                    >
                      <ListAltOutlined
                        role="button"
                        className="ms-2"
                        style={{ color: "#ccc",width:18,height:18,marginTop:-2 }}
                      />{" "}
                      Favourite Campaigns
                    </button>
                  </li>
                  <li>
                    <button
                      className="dropdown-item py-1 my-1"
                      type="button"
                      // onClick={() => {
                      //   handleMyFeeds();
                      //   showHideProfileMenu();
                      // }}
                      // href={"/gigs/favourite-employers"}
                      onClick={() => {
                        navigation('/gigs/favourite-employers')
                      }}
                    >
                      <ListAltOutlined
                        role="button"
                        className="ms-2"
                        style={{ color: "#ccc",width:18,height:18,marginTop:-2 }}
                      />{" "}
                      Favourite Employers
                    </button>
                  </li>

                  {/* <li>
                    <a
                      className="dropdown-item py-1 my-1"
                      type="button"
                      // onClick={() => {
                      //   handleMyFeeds();
                      //   showHideProfileMenu();
                      // }}
                      href={"/gigs/tasks"}
                    >
                      <ListAltOutlined
                        role="button"
                        className="ms-2"
                        style={{ color: "#ccc",width:18,height:18,marginTop:-2 }}
                      />{" "}
                      Tasks
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdown-item py-1 my-1"
                      type="button"
                      // onClick={() => {
                      //   handleMyFeeds();
                      //   showHideProfileMenu();
                      // }}
                      href={"/gigs/create-campaign"}
                    >
                      <ListAltOutlined
                        role="button"
                        className="ms-2"
                        style={{ color: "#ccc",width:18,height:18,marginTop:-2 }}
                      />{" "}
                      Create Campaign
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdown-item py-1 my-1"
                      type="button"
                      // onClick={() => {
                      //   handleMyFeeds();
                      //   showHideProfileMenu();
                      // }}
                      href={"/gigs/my-campaign"}
                    >
                      <ListAltOutlined
                        role="button"
                        className="ms-2"
                        style={{ color: "#ccc",width:18,height:18,marginTop:-2 }}
                      />{" "}
                      My Campaign
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdown-item py-1 my-1"
                      type="button"
                      // onClick={() => {
                      //   handleMyFeeds();
                      //   showHideProfileMenu();
                      // }}
                      href={"/gigs/accepted-task"}
                    >
                      <ListAltOutlined
                        role="button"
                        className="ms-2"
                        style={{ color: "#ccc",width:18,height:18,marginTop:-2 }}
                      />{" "}
                      Accepted Task
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdown-item py-1 my-1"
                      type="button"
                      // onClick={() => {
                      //   handleMyFeeds();
                      //   showHideProfileMenu();
                      // }}
                      href={"/gigs/review-task"}
                    >
                      <ListAltOutlined
                        role="button"
                        className="ms-2"
                        style={{ color: "#ccc",width:18,height:18,marginTop:-2 }}
                      />{" "}
                      Review Task
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdown-item py-1 my-1"
                      type="button"
                      // onClick={() => {
                      //   handleMyFeeds();
                      //   showHideProfileMenu();
                      // }}
                      href={"/gigs/favourite-tasks"}
                    >
                      <ListAltOutlined
                        role="button"
                        className="ms-2"
                        style={{ color: "#ccc",width:18,height:18,marginTop:-2 }}
                      />{" "}
                      Favourite Tasks
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdown-item py-1 my-1"
                      type="button"
                      // onClick={() => {
                      //   handleMyFeeds();
                      //   showHideProfileMenu();
                      // }}
                      href={"/gigs/favourite-employers"}
                    >
                      <ListAltOutlined
                        role="button"
                        className="ms-2"
                        style={{ color: "#ccc",width:18,height:18,marginTop:-2 }}
                      />{" "}
                      Favourite Employers
                    </a>
                  </li> */}
                  {/* <li>
                    <button
                      className="dropdown-item py-1 my-1"
                      type="button"
                      onClick={()=>
                        navigate("/offers")
                      }
                    >
                      <ListAltOutlined
                        role="button"
                        className="ms-2"
                        style={{ color: "#ccc",width:18,height:18,marginTop:-2 }}
                      />{" "}
                      Offers<sup className="new-text">New</sup>
                    </button>
                  </li> */}
                  {/* <li>
                    <button
                      className="dropdown-item py-1 my-1"
                      type="button"
                      onClick={()=>
                        navigate("/offers/my-offers")
                      }
                    >
                      <ListAltOutlined
                        role="button"
                        className="ms-2"
                        style={{ color: "#ccc",width:18,height:18,marginTop:-2 }}
                      />{" "}
                      My Offers
                    </button>
                  </li> */}
                  <li>
                    <button
                      className="dropdown-item py-1 my-1"
                      type="button"
                      // onClick={() => {
                      //   handleMyFeeds();
                      //   showHideProfileMenu();
                      // }}
                      // href={"/surveys"}
                      onClick={()=>
                        navigate("/surveys")
                      }
                    >
                      <ListAltOutlined
                        role="button"
                        className="ms-2"
                        style={{ color: "#ccc",width:18,height:18,marginTop:-2 }}
                      />{" "}
                      Surveys<sup className="new-text">New</sup>
                    </button>
                  </li>
                  <li>
                    <button
                      className="dropdown-item py-1 my-1"
                      type="button"
                      // onClick={() => {
                      //   handleMyFeeds();
                      //   showHideProfileMenu();
                      // }}
                      onClick={()=>
                        navigate("/surveys/my-surveys")
                      }
                    >
                      <ListAltOutlined
                        role="button"
                        className="ms-2"
                        style={{ color: "#ccc",width:18,height:18,marginTop:-2 }}
                      />{" "}
                      My Surveys
                    </button>
                  </li>
                  </div>
                  <li className="group-title">Settings & Privacy</li>
                  <div className="d-none">
                  <li>
                    <a
                      className="dropdown-item py-1 my-1"
                      type="button"
                      // onClick={() => {
                      //   handleMyFeeds();
                      //   showHideProfileMenu();
                      // }}
                      target="_blank"
                      href="https://tuteehub.com/about-us"
                    >
                      <InfoOutlined
                        role="button"
                        className="ms-2"
                        style={{ color: "#ccc",width:18,height:18,marginTop:-2 }}
                      />{" "}
                      About Us
                    </a>
                  </li>
                  <li>
                  <a
                      className="dropdown-item py-1 my-1"
                      type="button"
                      // onClick={() => {
                      //   handleMyFeeds();
                      //   showHideProfileMenu();
                      // }}
                      target="_blank"
                      href="https://tuteehub.com/privacy-policy"
                    >
                      <PolicyOutlined
                        role="button"
                        className="ms-2"
                        style={{ color: "#ccc",width:18,height:18,marginTop:-2 }}
                      />{" "}
                      Privacy Privacy
                    </a>
                  </li>
                  <li>
                  <a
                      className="dropdown-item py-1 my-1"
                      type="button"
                      // onClick={() => {
                      //   handleMyFeeds();
                      //   showHideProfileMenu();
                      // }}
                      target="_blank"
                      href="https://tuteehub.com/terms-and-condition"
                    >
                      <PolicyOutlined
                        role="button"
                        className="ms-2"
                        style={{ color: "#ccc",width:18,height:18,marginTop:-2 }}
                      />{" "}
                      Terms & Conditions
                    </a>
                  </li>
                  <li>
                  <a
                      className="dropdown-item py-1 my-1"
                      type="button"
                      // onClick={() => {
                      //   handleMyFeeds();
                      //   showHideProfileMenu();
                      // }}
                      target="_blank"
                      href="https://tuteehub.com/cancellation-and-refund-policy"
                    >
                      <PolicyOutlined
                        role="button"
                        className="ms-2"
                        style={{ color: "#ccc",width:18,height:18,marginTop:-2 }}
                      />{" "}
                      Cancellation & Refund Policy
                    </a>
                  </li>
                  </div>
                  <li>
                  <a
                      className="dropdown-item py-1 my-1"
                      type="button"
                      onClick={() => {
                        openHideDeleteAccountConfirmationModal()
                      }}
                      // target="_blank"
                      // href="https://tuteehub.com/terms-and-condition"
                    >
                      <RemoveCircleOutline
                        role="button"
                        className="ms-2"
                        style={{ color: "#ccc",width:18,height:18,marginTop:-2 }}
                      />{" "}
                      Delete Account
                    </a>
                  </li>
                  {/* <li>
                  <a
                      className="dropdown-item py-1 my-1"
                      type="button"
                      // onClick={() => {
                      //   handleMyFeeds();
                      //   showHideProfileMenu();
                      // }}
                      target="_blank"
                      href="https://showtalentapp.tuteehub.com"
                    >
                      <LiveHelpOutlinedIcon
                        role="button"
                        className="ms-2"
                        style={{ color: "#ccc",width:18,height:18,marginTop:-2 }}
                      />{" "}
                      FAQ
                    </a>
                  </li> */}
                   <div className="d-none">
                  <li className="group-title">Help & Support</li>
                  <li>
                  <button
                        className="dropdown-item py-1 my-1"
                        type="button"
                        onClick={() => {
                          navigation('help-and-support')
                          window.location.reload();
                          showHideProfileMenu();
                        }}
                      >
                        <HelpOutline
                          role="button"
                          className="ms-2"
                          style={{ color: "#ccc",width:18,height:18,marginTop:-2 }}
                        />{" "}
                        Help & Support
                      </button>
                 
                 
                  </li>
                  </div>
                  <li className="group-title">
                    <hr className="my-2" />
                  </li>
                  {/* <li>
                  <Link
                      className="dropdown-item py-1 my-1 ms-2"
                      type="button"
                      to={'finance'}
                    >
                      <AccountBalanceWalletOutlinedIcon style={{ color: "#ccc" }} /> Finance
                    </Link>
                  </li> */}
                  {/* <li>
                    <Link
                      className="dropdown-item py-1 my-1 ms-2"
                      type="button"
                      to={'plans'}
                    >
                      <MoneyOutlinedIcon style={{ color: "#ccc" }} /> Plans
                    </Link>
                  </li> */}
                  <li>
                    <button
                      className="dropdown-item py-1 my-1"
                      type="button"
                      onClick={() => handleLogout()}
                    >
                      <PowerSettingsNew
                        role="button"
                        className="ms-2"
                        style={{ color: "#ccc",width:18,height:18,marginTop:-2 }}
                      />{" "}
                      Logout
                    </button>
                  </li>
                </ul>
              ) : null}
            </div>
            
            </>
          ) : null}
        </div>
      </div>

      <Modal
        show={voiceDetectModal}
        onHide={handleVoiceDetectModal}
        size="sm"
        centered
      >
        {/* <Modal.Header closeButton>
          <Modal.Title>
            <h5 className="mb-0">Report</h5>
          </Modal.Title>
        </Modal.Header> */}
        <Modal.Body>
          <img src={require("../../../assets/images/listening.gif")} />
        </Modal.Body>
        {/* <Modal.Footer>
          <button
            type="button"
            disabled={isLoading ? true : false}
            className="btn btn-warning custom-theme-button d-flex align-items-center"
            onClick={() => reportFeed()}
          >
            Submit{" "}
            {isLoading ? (
              <CircularProgress size={15} color={"inherit"} className="ms-2" />
            ) : null}
          </button>
        </Modal.Footer> */}
      </Modal>


      <Modal
              show={isDeleteAccountOpen}
              onHide={openHideDeleteAccountConfirmationModal}
              size="md"
              centered
                // closeModal={openHideDeleteAccountConfirmationModal}
                // setIsOpen={setIsDeleteAccountOpen}
                // isOpen={isDeleteAccountOpen}
                // callBack={openHideDeleteAccountConfirmationModal}
                // title={'Delete Account'}
                // modalFooter={true}
                // isMaxW={true}
            >
               <Modal.Body>
              <p className="text-capitalize">
                    <h5><strong>Confirm Account Deletion</strong></h5> <br/>Deleting your TuteeHub account is irreversible. All your data will be permanently lost. Do you wish to proceed?
              </p>
              <div className="footer-bottom-modal w-100 d-flex align-items-center justify-content-end mt-5">
                  <button
                      type="button"
                      className="bg-tranparent border py-2 px-8 mr-3 rounded-lg border-themecolor text-themecolor text-lg w-[119px]"
                      onClick={() => { openHideDeleteAccountConfirmationModal() }}
                  >
                      No
                  </button>
                  <button

                      type="submit"
                      disabled={deleteAccountBtnLoader}
                      className="bg-themecolor text-white border py-2 px-8 mr-3 rounded-lg border-themecolor text-themecolor text-lg disabled:opacity-50 w-[119px] flex justify-center items-center"
                      onClick={() => handleAccountDelete()}
                  >
                      {deleteAccountBtnLoader ? (
                          <div className="h-5 w-5">
                              <Loadingicon />
                          </div>
                      ) : 'Yes'
                      }
                  </button>
                </div>
                </Modal.Body>
            </Modal>
    </>
  );
}
