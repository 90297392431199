import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import InfiniteScroll from "react-infinite-scroll-component";
import { followProfile, getAccountDetail, getGigsConfigurations, getPlansList, getPublicUserProfile, onAdduserDetails, onUpdateuserDetails } from "../../../api/OurService";
import notFound from '../../../assets/images/not_found_new.png';
import * as Yup from "yup";
import { Loadingicon } from "../../../AppIcons";
import BreadCrumbs from "../../common/BreadCrumbs";
import { useAuth } from "../../../Context/AuthContext";
import { CDN_BASE_URL, convertDateFormat, fetchUserInfo, nFormatter, reverseDateFormat } from "../../../utils/utils";
import { Formik } from "formik";
import PhoneNumberInput from "../../common/phonenumberinput";
import { userProfileUpdate } from "../../../api/AuthService";
import Modal from "../../common/Modal";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import CustomSelect from "../../common/customselect";
import indianStates from '../../../utils/indiaStates.json';
function EditProfile() {
    const formRef = useRef(null);
    const [loading, setLoading] = useState(false);
    const[isValidationOnSubmit, setIsValidationOnSubmit] = useState(false);
    const [imageLoading, setImageLoading] = useState(false);
    const [isProfilePicCropModalOpen, setIsProfilePicCropModalOpen] = useState(false);
    const [isCoverPicCropModalOpen, setIsCoverPicCropModalOpen] = useState(false);
    const [bannerImageLoading, setbannerImageLoading] = useState(false);
    const [publicUserDetails, setPublicUserDetails] = useState();
    const [isCountryLoading, setIsCountryLoading] = useState(true);
    const [countryFormatted, setCountryFormatted] = useState();
    const [defaultCountry, setDefaultCountry] = useState(null);
    const[preSelectedCountry, setPreSelectedCountry] = useState(null);
    const [userDetails, setUserDetails] = useState({});
    const [isIndia, setIsIndia] = useState(false);
    const cropperRef = useRef(null);
    const [coverImage, setCoverImage] = useState();
    const [pageNo, setPageNo] = useState(1);
    const { user, setUser, setFinance, globalToken } = useAuth();

    const navigate = useNavigate();
    const { state } = useLocation();

    const userPublicProfile = async (uTId, pNo) => {
        setLoading(true)
        const publicUserData = await getPublicUserProfile({ tuteehub_id: uTId, page: pNo });
        // console.log(publicUserData)
        if (publicUserData?.status == 1) {
            setPublicUserDetails(publicUserData?.result); 
            setPreSelectedCountry(publicUserData?.result?.country);
            // fetchChoosenCountryDetail(publicUserData?.result?.country, countryFormatted)          
        }
        setLoading(false)
    }

    useEffect(()=>{
        if(preSelectedCountry && countryFormatted){
          
            fetchChoosenCountryDetail(preSelectedCountry, countryFormatted);
            if(preSelectedCountry?.toLowerCase()=='india'){
                setIsIndia(true);
            }
        }
    },[preSelectedCountry, countryFormatted])

    const handleConfig = async (conf) => {
        try {
            const gigsConfRes = await getGigsConfigurations({ token: globalToken, conf: conf });
            if (gigsConfRes?.success) {
                if (conf == 'country') {
                    const countryData = gigsConfRes.data?.country?.map(option => ({
                        value: option.countryname,
                        label: option.countryname,
                        flag: CDN_BASE_URL + option?.image
                    })) || [];
                    setCountryFormatted(countryData);

                }                
                setIsCountryLoading(false);
            }
        } catch (error) {
            console.error('Error fetching gigs configurations:', error);
            // Handle the error, e.g., display an error message to the user
        }
    }

    const getCroppedData = (type) =>{
       
        setImageLoading(true)
        if(type=='cover'){
                if (typeof cropperRef.current?.cropper !== "undefined") {
                    cropperRef.current?.cropper.getCroppedCanvas().toBlob((blob) => {
                        const timestamp = new Date().getTime(); // Get current timestamp
                        const fileName = `cropped-image-${timestamp}.jpg`; 
                        const croppedFile = new File([blob], fileName, { type: 'image/jpeg' });
                        updateuserDetails(null, croppedFile, 'cover'); 
                    }, 'image/jpeg', 0.5);
                }
        }else{
            if (typeof cropperRef.current?.cropper !== "undefined") {
                cropperRef.current?.cropper.getCroppedCanvas().toBlob((blob) => {
                    const timestamp = new Date().getTime(); // Get current timestamp
                    const fileName = `profile-image-${timestamp}.jpg`; 
                    const croppedFile = new File([blob], fileName, { type: 'image/jpeg' });
                    console.log(croppedFile)
                    updateuserDetails(null, croppedFile, 'profile'); 
                }, 'image/jpeg', 0.7);
            }
        }
    }

    const updateuserDetails = async (values, image, type) => {
        
        if(!isIndia){
            delete values?.state;
        }
        
        if(values?.dob!=="")
        values = {...values, dob: reverseDateFormat(values?.dob)}
        
        values = {...values, phone:values?.phoneMask};
        if (image && type == 'profile') {
            setImageLoading(true)
        }
        else if (image && type == 'cover') {
            setImageLoading(true)
        }else{
            setImageLoading(true)
        }

        delete values?.phoneMask;
    
        values = image && type == 'cover' ? { token: globalToken, cover_image: image } : image && type == 'profile' ? { token: globalToken, image: image } : { ...values, token: globalToken }

        try {
            
            const res = await userProfileUpdate(values);
            
            if (res?.success) {
                getUserInfo();
                toast.success('Profile updated!');
                if(state?.comingFrom){
                    navigate('/purchase-credits');
                }else{
                    navigate('/my-profile');
                }
            
            } else {
                toast.error(res?.message)
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
        // setLoading(false)

        setImageLoading(false)

    };

    useEffect(() => { userPublicProfile(user?.tuteehub_id, pageNo);handleConfig("country"); }, [])



    const getUserInfo = async () => {
        fetchUserInfo(user?.tuteehub_id, globalToken, setUser, setFinance, user)
        // const res = await getUserInfo(user?.tuteehub_id, user?.access_token);
        // if(res.success){
        // setUser({...user, profile_image:(res?.data?.profileImage)?CDN_BASE_URL + res?.data?.profileImage:null})
        // localStorage.setItem('userDetails',JSON.stringify({...user, profile_image:(res?.data?.profileImage)?CDN_BASE_URL + res?.data?.profileImage:null}))
        // localStorage.setItem('____fi', JSON.stringify({__crdt:res.data?.credits}))
        // setFinance({__crdt:res.data?.credits});
        // // navigate("/");      
        // }
    }


    const DisplayingErrorMessagesSchema = Yup.object().shape({
        name: Yup.string().required("Name is required"),
        email: Yup.string().required("Email is required"),
        // phone: Yup.string().required("Phone Number is required"),
        phoneMask: Yup.string().required("Phone Number is required").min(10, "Phone number must be at least 10 digits."),
        ccode: Yup.string().required("Country Code is required"),
        address: Yup.string().required("Address is required"),
        state: Yup.string().required("State is required"),
        city: Yup.string().required("City is required"),
        country: Yup.string().required("Country is required")
    });
    const formData = [
        {
            id: 'name',
            title: 'Name',
            type: 'text',
            name: 'name',
            placeholder: 'Name',
            required: true,
            editable: true
        }, {
            id: 'email',
            title: 'Email',
            type: 'text',
            name: 'email',
            placeholder: 'Email',
            required: true,
            editable: false
        }, {
            id: 'phone',
            title: 'Phone Number',
            type: 'text',
            name: 'phone',
            placeholder: 'Phone Number',
            isPhone: true,
            required: true,
            editable: true
        }, {
            id: 'dob',
            title: 'Date of Birth',
            type: 'date',
            name: 'dob',
            placeholder: 'Date of Birth',
            required: false,
            editable: true
        }, {
            id: 'gender',
            title: 'Gender',
            type: 'text',
            name: 'gender',
            placeholder: 'Gender',
            required: false,
            editable: true,
            isGender: true
        },{
            id: 'fatherName',
            title: 'Father Name',
            type: 'text',
            name: 'fatherName',
            placeholder: 'Father Name',
            required: false,
            editable: true
        },{
            id: 'motherName',
            title: 'Mother Name',
            type: 'text',
            name: 'motherName',
            placeholder: 'Mother Name',
            required: false,
            editable: true
        },{
            id: 'address',
            title: 'Address',
            type: 'text',
            name: 'address',
            placeholder: '',
            required: true,
            editable: true
        },{
            id: 'country',
            title: 'Country',
            type: 'text',
            name: 'country',
            placeholder: 'Pick one from the list',
            required: true,
            editable: true,
            isCountry: true
        },{
            id: 'state',
            title: 'State',
            type: 'text',
            name: 'state',
            placeholder: '',
            required: true,
            editable: true,
            isState:true
        },{
            id: 'city',
            title: 'City',
            type: 'text',
            name: 'city',
            placeholder: '',
            required: true,
            editable: true
        },{
            id: 'postal_code',
            title: 'Zip Code',
            type: 'number',
            name: 'postal_code',
            placeholder: '',
            required: false,
            editable: true
        }, {
            id: 'about',
            title: 'About Me',
            type: 'text',
            name: 'about',
            placeholder: 'About Me',
            width: true,
            required: false,
            editable: true
        }, {
            id: 'isPrivate',
            title: 'Profile Access',
            type: 'text',
            name: 'isPrivate',
            placeholder: 'Profile Access',
            required: false,
            editable: true,
            isPrivate: true
        }

    ]

    const chooseProfilePicRef = useRef(null);
    const chooseCoverPicRef = useRef(null);

    const handleUserPicChange = () => {
        chooseProfilePicRef.current.click();
    };

    const handleCoverPicChange = () => {
       
        chooseCoverPicRef.current.click();
    };

    const handleImageChange = (event, type) => {
      
        const file = event.target.files[0];
        if (file) {
            // Validate file type (only allow images)
            const allowedTypes = ['image/jpeg', 'image/png', 'image/gif'];
            if (allowedTypes.includes(file.type)) {
                // Process the selected image
                // updateuserDetails(null, file, type);
                 const reader = new FileReader();
                reader.onload = () => {
                  setCoverImage(reader.result);
                };
                reader.readAsDataURL(file);  
                if(type=='cover'){
                    closeCoverPicCropModal();
                }  
                if(type=='profile'){
                    closeProfilePicCropModal();
                }   
                
            } else {
                alert('Please choose a valid image file.');
            }
        }
    };

    const closeProfilePicCropModal = () => {
        setIsProfilePicCropModalOpen(!isProfilePicCropModalOpen)
    }

    const closeCoverPicCropModal = () => {
        setIsCoverPicCropModalOpen(!isCoverPicCropModalOpen)
    }
    const handleCropProfilePic = () =>{
        // alert(1)
    }

    const handleCustomSelectOnChange = (value, type, label, setFieldValue) => {
        
        if (type == 'country') {
            if(value?.toLowerCase()=='india'){
                setIsIndia(true);
            }else{
                setIsIndia(false)
            }
            // alert(value)
            // let cValue = value?.map(cV => (cV?.value))
            // setDefaultCountry(ccData?.length?ccData:null);
            setFieldValue('country', value);
            
        }

    }

    const fetchChoosenCountryDetail = (c, cData) =>{      
        const ccData = cData?.filter((cd)=>(cd.value)?.toLowerCase()==c?.toLowerCase());  
            
       setDefaultCountry(ccData?.length?ccData:null);
    }

    const validateAndScroll = async (values) => {
        
        try {
          await DisplayingErrorMessagesSchema.validate(values, { abortEarly: false });
          return {}; 
        } catch (err) {
          const errors = {};
          if (err.inner) {
            err.inner.forEach((error) => {
              errors[error.path] = error.message;
            });
          }
          if (Object.keys(errors).length > 0) {
            const firstErrorField = Object.keys(errors)[0]; 
            console.log(firstErrorField)
            if (formRef.current) {
                const fieldElement = document.querySelector(`[name="${firstErrorField}"]`);
                if (fieldElement && isValidationOnSubmit) {
                    fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                    fieldElement.focus(); 
                    setIsValidationOnSubmit(false)
                }
            }
          }
      
          return errors; 
        }
      };
      
    return (
        <>
            <BreadCrumbs />
            <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.5.2/css/all.min.css" />
            {loading ? (
                <div className=" py-0 px-0 grid grid-cols-1 group-card card-body gap-4s">
                    <div className="w-full flex items-center justify-center mt-20 mb-20 text-themecolor min-h-[400px]">
                        <div className="h-10 w-10">
                            <Loadingicon />
                        </div>
                    </div>
                </div>) : (
                <>
                    {
                        publicUserDetails ? <>

                            <div className="py-0 px-0 grid grid-cols-1 group-card card-body gap-4s">
                                <div className="top-banner-part" style={{ background: publicUserDetails?.coverImage ? `url(${publicUserDetails?.SpaceBaseUrl}/${publicUserDetails?.coverImage})` : `url('https://tuteehub.nyc3.cdn.digitaloceanspaces.com/showtalent/images/cover-image/658eb4928b1181175868885.png')` }}>
                                    <div className="edit_profile_btn">
                                        <button type="button" onClick={() => handleCoverPicChange()} disabled={imageLoading} className="px-3 py-1 btn btn-default text-white" style={{ background: 'rgba(0,0,0,0.3)', border: 'none' }}>
                                            {
                                                imageLoading ? <div className="h-5 w-5 m-auto">
                                                    <Loadingicon />
                                                </div> : <i className="fa fa-pencil"></i>
                                            }
                                        </button>
                                    </div>
                                    <div className="cover-black"></div>
                                    <div className="profile-initial-info text-center">
                                        <div className="profile-img m-auto">

                                            {
                                                (publicUserDetails?.profileImage) ? <img src={`${publicUserDetails?.SpaceBaseUrl}/${publicUserDetails?.profileImage}`} alt="profile image" /> :
                                                (user?.imageUrl) ? <img src={`${user?.imageUrl}`} alt="profile image" /> : <img
                                                    src={require('../../../assets/images/user_placeholder.jpg')}
                                                    style={{height:140, maxHeight:140, width:'auto', maxWidth:'max-content'}}

                                                />
                                            }

                                        </div>
                                        <input
                                            type="file"
                                            id="chooseProfilePic"
                                            ref={chooseProfilePicRef}
                                            style={{ display: 'none' }}
                                            accept="image/*"
                                            onChange={(e) => handleImageChange(e, 'profile')}
                                        />
                                        <input
                                            type="file"
                                            id="chooseCoverPic"
                                            ref={chooseCoverPicRef}
                                            style={{ display: 'none' }}
                                            accept="image/*"
                                            onChange={(e) => handleImageChange(e, 'cover')}
                                        />

                                        <button onClick={() => handleUserPicChange()} disabled={imageLoading} className="edit-pencil-btn" type="button">
                                            {
                                                imageLoading ? <div className="h-5 w-5 m-auto">
                                                    <Loadingicon />
                                                </div> : <i className="fa fa-pencil"></i>
                                            }
                                        </button>
                                        <h3 className="text-white mt-4 mb-2">{publicUserDetails?.name}</h3>
                                        <h5 className="text-white mb-2">@{publicUserDetails?.username}</h5>
                                    </div>
                                </div>

                              

                                <div className="px-md-5 px-3 py-3 my-2 d-flex align-items-center gap-20">
                                    <Formik
                                        innerRef={formRef} 
                                        enableReinitialize={true}
                                        initialValues={{
                                            name: publicUserDetails?.name || "",
                                            email: publicUserDetails?.email || "",
                                            phone: publicUserDetails?.phone ? (publicUserDetails?.country_code + publicUserDetails?.phone) : "",
                                            ccode: publicUserDetails?.country_code || "",
                                            phoneMask: publicUserDetails?.phone ? (publicUserDetails?.phone) : "",
                                            gender: publicUserDetails?.gender || "Male",
                                            isPrivate: publicUserDetails?.isPrivate || "0",
                                            fatherName: publicUserDetails?.fatherName || "",
                                            motherName: publicUserDetails?.motherName || "",
                                            dob: publicUserDetails?.dob || "",
                                            about: publicUserDetails?.about || "",
                                            address:publicUserDetails?.address || "",
                                            state:(publicUserDetails?.state)?.toLowerCase() || indianStates[0]?.name,
                                            country:!publicUserDetails?.country || publicUserDetails?.country=="undefined"?"": publicUserDetails?.country,
                                            city:publicUserDetails?.city || "",
                                            postal_code:publicUserDetails?.postal_code || ""
                                        }}
                                        validate={validateAndScroll}
                                        validationSchema={DisplayingErrorMessagesSchema}
                                        onSubmit={(values) => {
                                            if (values)
                                                updateuserDetails(values, null, null)
                                        }}
                                    >
                                        {({
                                            values,
                                            errors,
                                            touched,
                                            handleChange,
                                            handleBlur,
                                            setFieldValue,
                                            handleSubmit,
                                        }) => (
                                            <form className="" onSubmit={handleSubmit}>
                                                <div className="row">
                                                    {
                                                        formData?.map(fieldMeta => (
                                                            <div className={`position-relative  ${fieldMeta.width ? 'col-md-12' : 'col-md-12'}`}>
                                                                <label
                                                                    htmlFor={fieldMeta?.id}
                                                                    className={`block mb-2 text-sm font-medium text-gray-900 dark:text-gray-500 ${!isIndia && fieldMeta?.isState?'d-none':''}`}
                                                                >
                                                                    {fieldMeta?.title}

                                                                    {
                                                                        fieldMeta?.required ? <span className="text-red-400 text-base">*</span> : null
                                                                    }
                                                                </label>
                                                                <input type="password" name="fake-password" autoComplete="new-password" style={{ display: 'none' }} />
                                                                {
                                                                    fieldMeta.width ?
                                                                        <>
                                                                            <textarea type={fieldMeta?.type}
                                                                                
                                                                                name={fieldMeta?.name}
                                                                                autoComplete={'off'}
                                                                                onChange={handleChange}
                                                                                            onBlur={handleBlur}
                                                                                id={fieldMeta?.name} rows={6} placeholder="About Me" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5">{publicUserDetails[fieldMeta?.name]}</textarea>
                                                                            <div className="text-red-500 mt-1 text-sm text-right min-h-[20px]">
                                                                                {touched[fieldMeta?.name] && errors[fieldMeta?.name] && errors[fieldMeta?.name]}
                                                                            </div>
                                                                        </>
                                                                       : fieldMeta.isCountry ?
                                                                        <div className="w-100">
                                                                            {console.log(defaultCountry)}
                                                                            {
                                                                                countryFormatted?.length ?
                                                                                    <div className="d-block w-100">
                                                                                            {
                                                                                                defaultCountry?.length ?<CustomSelect options={countryFormatted} defaultOption={defaultCountry} isMultiple={false} isClearable={true} handleCustomSelectOnChange={handleCustomSelectOnChange}
                                                                                                setFieldValue={setFieldValue}
                                                                                                type={'country'} isSelectLoading={isCountryLoading} placeholder={'Country'} />:null
                                                                                            }
                                                                                            
                                                                                        </div>:null
                                                                            }
                                                                             {
                                                                                countryFormatted?.length ?
                                                                                    <div className="d-block w-100">
                                                                                            {
                                                                                                !defaultCountry?.length ?<CustomSelect options={countryFormatted} isMultiple={false} isClearable={true} handleCustomSelectOnChange={handleCustomSelectOnChange}
                                                                                                setFieldValue={setFieldValue}
                                                                                                type={'country'} isSelectLoading={isCountryLoading} placeholder={'Country'} />:null
                                                                                            }
                                                                                            
                                                                                        </div>:null
                                                                            }

                                                                            <div className="text-red-500 mt-1 text-sm text-right min-h-[20px]">
                                                                                {touched[fieldMeta?.name] && errors[fieldMeta?.name] && errors[fieldMeta?.name]}
                                                                            </div>
                                                                        </div>
                                                                         :
                                                                         isIndia && fieldMeta?.isState?<div className="w-100">
                                                                            
                                                                            {
                                                                               indianStates?.length ?
                                                                                    <div className="d-block w-100">
                                                                                       
                                                                                        <select
                                                                                            className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                                                                            name={fieldMeta?.name}
                                                                                            id={fieldMeta?.id}
                                                                                            onChange={handleChange}
                                                                                            onBlur={handleBlur}
                                                                                        >
                                                                                            {
                                                                                                indianStates?.map((state, index)=>(
                                                                                                    <option value={state?.name} selected={publicUserDetails?.state==state?.name?true:false}>{state?.name}</option>
                                                                                                ))
                                                                                            }
                                                                                        </select>
                                                                                        </div>:null
                                                                            }
                                                                            
                                                                            <div className="text-red-500 mt-1 text-sm text-right min-h-[20px]">
                                                                                {touched[fieldMeta?.name] && errors[fieldMeta?.name] && errors[fieldMeta?.name]}
                                                                            </div>
                                                                        </div>
                                                                        : fieldMeta?.isPhone && publicUserDetails?.country_code ?
                                                                            <>
                                                                                <div className="d-flex edit-phone gap-1">
                                                                                    <PhoneNumberInput
                                                                                        name={fieldMeta?.name}
                                                                                        style={{ maxWidth: 103 }}
                                                                                        onChange={(value, country, e, formattedValue) => {

                                                                                            setFieldValue('ccode', country.dialCode);
                                                                                            // const dialCodeWithoutPlus = (country.dialCode).replace("+", "");
                                                                                            // setFieldValue('phone', value.replace(dialCodeWithoutPlus, ""));

                                                                                        }}
                                                                                        disabled={!fieldMeta?.editable}
                                                                                        value={publicUserDetails?.country_code + publicUserDetails[fieldMeta?.id]}
                                                                                        defaultDialCode={publicUserDetails?.country_code || 91}
                                                                                    />
                                                                                    <div className="flex-1" style={{ marginBottom: 10 }}>
                                                                                        <input type="text"
                                                                                            // style={{position:'absolute', bottom:30}}
                                                                                            className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                                                                            placeholder={fieldMeta?.title}
                                                                                            defaultValue={publicUserDetails[fieldMeta?.id]}
                                                                                            onKeyDown={(e) => {
                                                                                                if (!(e.key === 'Backspace' || e.key === 'Delete' || e.key === 'ArrowLeft' || e.key === 'ArrowRight' || e.key === 'Tab' || (e.key >= '0' && e.key <= '9'))) {
                                                                                                    e.preventDefault();
                                                                                                }else{
                                                                                                    setFieldValue('phone', e.target.value);
                                                                                                }
                                                                                                
                                                                                            }}
                                                                                            onChange={handleChange}
                                                                                            onBlur={handleBlur}
                                                                                            name="phoneMask"
                                                                                        />
                                                                                    </div>
                                                                                </div>

                                                                                <div className="text-red-500 mt-1 text-sm text-right min-h-[20px]">
                                                                                    {touched['phoneMask'] && errors['phoneMask'] && errors['phoneMask']}
                                                                                </div>
                                                                            </>
                                                                            : fieldMeta?.isGender ? <>
                                                                                <select
                                                                                    className="bg-gray-50 mb-4 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                                                                    name={fieldMeta?.name}
                                                                                    id={fieldMeta?.id}
                                                                                    onChange={handleChange}
                                                                                    onBlur={handleBlur}
                                                                                >
                                                                                    <option value="Male" selected={publicUserDetails?.gender=='Male'?true:false}>Male</option>
                                                                                    <option value="Female" selected={publicUserDetails?.gender=='Female'?true:false}>Female</option>
                                                                                </select>
                                                                            </>
                                                                                : fieldMeta?.isPrivate ? <>
                                                                                    <select className="bg-gray-50 mb-4 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                                                                        onChange={handleChange}
                                                                                        onBlur={handleBlur} name={fieldMeta?.name} 
                                                                                        id={fieldMeta?.id}>
                                                                                        <option value={0} selected={publicUserDetails?.is_private==0?true:false}>Public</option>
                                                                                        <option value={1} selected={publicUserDetails?.is_private==1?true:false}>Private</option>
                                                                                    </select>
                                                                                </> : !fieldMeta?.isState?<>
                                                                                    <input
                                                                                        disabled={!fieldMeta?.editable}
                                                                                        type={fieldMeta?.type}
                                                                                        name={fieldMeta?.id}
                                                                                        autoComplete={'off'}
                                                                                        id={fieldMeta?.name}
                                                                                        className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"

                                                                                        placeholder={fieldMeta?.title}
                                                                                        onChange={handleChange}
                                                                                        onBlur={handleBlur}
                                                                                        value={values[fieldMeta?.id] || ""}
                                                                                    />
                                                                                    <div className="text-red-500 mt-1 text-sm text-right min-h-[20px]">
                                                                                        {touched[fieldMeta?.name] && errors[fieldMeta?.name] && errors[fieldMeta?.name]}
                                                                                    </div>
                                                                                </>:null
                                                                }


                                                            </div>
                                                        ))
                                                    }

                                                </div>
                                                {/* </div> */}
                                                <div className="col-md-2 mt-3">
                                                    <button
                                                        type="submit"
                                                        disabled={imageLoading}
                                                        onMouseDown={()=>setIsValidationOnSubmit(true)}
                                                        className="mt-2 w-full text-white bg-themecolor hover:bg-opacity-75 disabled:bg-opacity-50 focus:ring-1 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center flex justify-center"
                                                    >
                                                        {imageLoading ? (
                                                            <div className="h-5 w-5">
                                                                <Loadingicon />
                                                            </div>
                                                        ) : (
                                                            "Save"
                                                        )}
                                                    </button>
                                                </div>
                                            </form>
                                        )}

                                    </Formik>
                                </div>
                            </div>

                        </> : null
                    }
                </>
            )
            }
{/* modal for logo cropper */}
<Modal
                closeModal={closeProfilePicCropModal}
                setIsOpen={setIsProfilePicCropModalOpen}
                isOpen={isProfilePicCropModalOpen}
                callBack={handleCropProfilePic}
                title="Crop Profile Pic"
                modalFooter={true}
                isMaxL={true}
            >
                <Cropper
                    ref={cropperRef}
                    style={{ height: 400, width: 400,margin:'0 auto' }}
                    zoomTo={0.5}
                    initialAspectRatio={1}
                    preview=".img-preview"
                    src={coverImage}
                    // viewMode={1}
                    dragMode="move"
                    minCropBoxHeight={300}
                    minCropBoxWidth={300}
                    background={false}
                    responsive={true}
                    cropBoxResizable={true}
                    // autoCropArea={1}
                    checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                    guides={true}

                    />
                    {/* <div
                    className="img-preview"
                    style={{ width: "100%", float: "left", height: "300px" }}
                /> */}
                <div className="mt-4 modal-footer gap-2 mt-10 flex justify-end">
                    {/* <button
                      type="button"
                      className="bg-tranparent border py-2 px-8 mr-3 rounded-lg border-themecolor text-themecolor text-lg w-[119px]"
                      onClick={closeProfilePicCropModal}
                    >
                      Skip
                    </button> */}
                    <button
                      type="button"
                      className="bg-themecolor text-white border py-2 px-8 mr-3 rounded-lg border-themecolor text-themecolor text-lg disabled:opacity-50 w-[119px] flex justify-center items-center"
                      onClick={()=>getCroppedData('profile')}
                    >
                     {
                            imageLoading ? <div className="h-5 w-5 m-auto">
                                <Loadingicon />
                            </div> : <span>Crop</span>
                        }
                    </button>
                  </div>

            </Modal>
            {/* modal for logo cropper end */}  


            {/* modal for logo cropper */}
<Modal
                closeModal={closeCoverPicCropModal}
                setIsOpen={setIsCoverPicCropModalOpen}
                isOpen={isCoverPicCropModalOpen}
                callBack={handleCropProfilePic}
                title="Crop Cover Pic"
                modalFooter={true}
                isMaxL={true}
            >
                <Cropper
                    ref={cropperRef}
                    style={{ height: 400, width: 1266,margin:'0 auto' }}
                    zoomTo={0.5}
                    // initialAspectRatio={1}
                    preview=".img-preview"
                    src={coverImage}
                    // viewMode={1}
                    dragMode="move"
                    minCropBoxHeight={330}
                    minCropBoxWidth={1100}
                    background={false}
                    responsive={true}
                    cropBoxResizable={true}
                    // autoCropArea={1}
                    checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                    guides={true}

                    />
                    {/* <div
                    className="img-preview"
                    style={{ width: "100%", float: "left", height: "300px" }}
                /> */}
                <div className="mt-4 modal-footer gap-2 mt-10 flex justify-end">
                    {/* <button
                      type="button"
                      className="bg-tranparent border py-2 px-8 mr-3 rounded-lg border-themecolor text-themecolor text-lg w-[119px]"
                      onClick={closeCoverPicCropModal}
                    >
                      Skip
                    </button> */}
                    <button
                      type="button"
                      className="bg-themecolor text-white border py-2 px-8 mr-3 rounded-lg border-themecolor text-themecolor text-lg disabled:opacity-50 w-[119px] flex justify-center items-center"
                      onClick={()=>getCroppedData('cover')}
                    >
                       {
                            imageLoading ? <div className="h-5 w-5 m-auto">
                                <Loadingicon />
                            </div> : <span>Crop</span>
                        }
                    </button>
                  </div>

            </Modal>
            {/* modal for logo cropper end */}  
        </>
    );
}
export default EditProfile;
