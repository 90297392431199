import React, { useEffect, useRef, useState } from "react";
import SectionDescription from "../../../common/sectionDescription";
import BreadCrumbs from "../../../common/BreadCrumbs";
import {
  CDN_BASE_URL,
  ERROR_SOMETHING_WENT_WRONG,
  nFormatter,
  yearSatra,
} from "../../../../utils/utils";
import { toast } from "react-toastify";
import notFound from "../../../../assets/images/not_found_new.png";
import { Loadingicon } from "../../../../AppIcons";
import ViewAllButton from "../../../common/viewallbutton";
import { useNavigate } from "react-router-dom";
import { getExamCategory, getSearchedData } from "../../../../api/exam";
import ExamCatSubCatList from "../common/catsubcat.js";
function ExamCategory() {
  const [pageNo, setPageNo] = useState(1);
  const [showLoadMore, setShowLoadMore] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isNotFound, setIsNotFound] = useState(false);
  const [loadingLoadMore, setLoadingLoadMore] = useState(false);
  const [allContent, setAllContent] = useState([]);
  const [allFilteredContent, setAllFilteredContent] = useState([]);
  const [isSearch, setIsSearch] = useState(false);
  const [searchTerm, setSearchTerm] = useState();
  const [totalRecordFound, setTotalRecordFound] = useState(0);
  const searchBox = useRef(null);
  const navigation = useNavigate();

  useEffect(() => {
    handleFetchAllExamsCategory(1);
  }, []);

  // fetch all category
  const handleFetchAllExamsCategory = async (pageNo) => {
    const resAllExamCategory = await getExamCategory({ pageNo: pageNo });
    if (resAllExamCategory?.success) {
      setIsNotFound(false);
      resAllExamCategory?.subjects?.next_page_url
        ? setShowLoadMore(true)
        : setShowLoadMore(false);
      // console.log(resAllExamCategory);
      if (resAllExamCategory?.subjects?.data?.length && pageNo == 1) {
        setAllContent(resAllExamCategory?.subjects?.data);
        setAllFilteredContent(resAllExamCategory?.subjects?.data);
      } else if (resAllExamCategory?.subjects?.data?.length && pageNo !== 1) {
        setAllContent([...allContent, ...resAllExamCategory?.subjects?.data]);
        setAllFilteredContent([
          ...allContent,
          ...resAllExamCategory?.subjects?.data,
        ]);
      } else {
        if (pageNo == 1) {
          setAllContent([]);
          setAllFilteredContent([]);
          setIsNotFound(true);
        }
      }
    } else {
      toast.error(ERROR_SOMETHING_WENT_WRONG);
    } 

    setLoading(false);
    setLoadingLoadMore(false);
  };

  //load more streams data
  const loadMore = () => {
    setLoadingLoadMore(true);
    const nxtPage = pageNo + 1;
    handleFetchAllExamsCategory(nxtPage);
    setPageNo(nxtPage);
    searchBox.current.value = "";
    setIsSearch(false);
  };

  const handleSearchCategory = (e) => {
    const searchTxt = e.target.value;
    setSearchTerm(searchTxt);
    if (searchTxt == "") {
      setIsSearch(false);
      handleFetchAllExamsCategory(1);
      return;
    }
    if (searchTxt !== "" && e.key === "Enter") {
      setIsSearch(true);
      searchCategory(pageNo, searchTxt);
    }
  };

  const searchCategory = async (pNo, searchTerm) => {
    setLoading(true);
        const res = await getSearchedData({keyword : searchTerm});
        setIsSearch(true);

        if (res?.success) {
          console.log(res)
            let filteredResults = res.subject;
            setTotalRecordFound(filteredResults?.length || 0)
            setIsNotFound(false)
            filteredResults?.next_page_url ? setShowLoadMore(true) : setShowLoadMore(false);
            if (filteredResults?.length && pNo == 1) {
                setAllFilteredContent(filteredResults);
            } else if (filteredResults?.length && pNo !== 1) {
                setAllFilteredContent([...allContent, ...filteredResults]);
            } else {
                if (pageNo == 1) {
                    setAllFilteredContent([]);
                    setIsNotFound(true);
                }
            }
        } else {
            toast.error(ERROR_SOMETHING_WENT_WRONG)
        }
        setLoading(false);
        setLoadingLoadMore(false);
  };

  return (
    <>
      <div className="d-flex align-items-center justify-content-between">
  <BreadCrumbs />
  
  {/* Right-side elements container */}
  <div className="d-flex align-items-center justify-content-end">
    {/* Search Box */}
    <div
      className="search-boxs px-2 d-flex align-items-center border rounded bg-white"
      style={{ marginBottom: "1rem" }}
    >
      <input
        type="text"
        autoComplete="chrome-off"
        onKeyUp={handleSearchCategory}
        className="searchInput px-md-3 px-0 form-control border-0 bg-transparent outline-0"
        placeholder={`Search Category`}
        ref={searchBox}
      />
      <i
        className="fa fa-search opacity-1 cursor-pointer"
        style={{ color: "#ff7200" }}
        onClick={() => searchCategory(1, searchTerm)}
      ></i>
    </div>
    
    {/* My Blogs Button */}
    <div className="btn-group question-box question-box-card ms-3 mb-3">
      <button
        className="btn-soft cursor-pointer hover:bg-themecolor text-white"
        style={{ backgroundColor: "#ff7200" }}
        onClick={() => navigation("/exams/myExams")}
      >
        My Exams
      </button>
    </div>
  </div>
</div>
      <div className="row">
        <div className="col-md-12">
          <div className="pb-4 px-2 px-md-4 grid grid-cols-1 group-card card-body gap-1">
            {!isSearch ? (
              <>
                <div className="d-flex align-items-center justify-content-between">
                  <SectionDescription
                    title={`<strong> All Latest Exams Mock Test For Practice Session ${yearSatra()}</strong>`}
                    subTitle={`To help you prepare for any exam, we have compiled a huge collection of exhuastive mock tests subjectwise/topicwise that are typically included in any exam syllabus. There are free mock tests for beginners along with subscription based packages with unlimited test series of various difficulty levels. If you're preparing for any competition, employment, entrance exam that includes multiple-choice questions then TuteeHub can help you practice with confidence.`}
                  />
                </div>

                <hr
                  style={{ borderColor: "#ccc", marginBottom: 0 }}
                  className="mb-4"
                />
              </>
            ) : null}

            {isSearch ? (
              <>
                <h6 className="text-black-50s label label-default w-100 py-2">
                  Search Result For '{searchTerm}' : {totalRecordFound} Result
                  Found
                </h6>
                <hr style={{ borderColor: "#f0f0f0", marginTop: 0 }} />
              </>
            ) : null}
            {loading ? (
              <div className=" py-0 px-0 grid grid-cols-1 group-card card-body gap-4s">
                <div className="w-full flex items-center justify-center mt-20 mb-20 text-themecolor min-h-[400px]">
                  <div className="h-10 w-10">
                    <Loadingicon />
                  </div>
                </div>
              </div>
            ) : isNotFound ? (
              <div className="flex-column not-found d-flex text-center">
                <img src={notFound} className="notFoundImg" />
                <span style={{ opacity: 0.7 }}>No data found</span>
              </div>
            ) : (
              <div className="row">
                {allFilteredContent?.map((content, sIndex) => (
                  <ExamCatSubCatList
                    content={content}
                    boxSize="col-md-4 mb-3"
                    key={sIndex}
                    type="content"
                    redirectionFn={() => navigation(`/exams/${content?.slug}`)}
                  />
                ))}
              </div>
            )}
          </div>
          {showLoadMore && !isNotFound ? (
            <ViewAllButton
              disabled={false}
              redirectionUrl={"/"}
              handleClickFn={loadMore}
              btnText={"Load More"}
              type={"button"}
              btnClass={"loadButton card-btn-1"}
              loading={loadingLoadMore}
            />
          ) : null}
        </div>
        {/* <div className='col-md-3'>
                <div className="pb-4 px-2 px-md-4 grid grid-cols-1 group-card card-body gap-1 position-sticky" style={{ top: 80 }}>
                  
                    <PopularCareerCategories catsList={popularCareerCats} title={`Popular Categories`}/>
                </div>
            </div> */}
      </div>
    </>
  );
}

export default ExamCategory;
