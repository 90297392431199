import React from 'react';

const Navigation = ({ questions, setCurrentQuestionIndex, status, setStatus, onSubmitTest, isFullscreen }) => {
  const handleQuestionClick = (index) => {
    setCurrentQuestionIndex(index);

    // Update the status to 'not_answered' if it is currently 'not_visited'
    if (status[index] === 'not_visited') {
      const updatedStatus = [...status];
      updatedStatus[index] = 'not_answered';
      setStatus(updatedStatus);
    }
  };
  return (
    <div className="navigation-panel">
      <div className="legend">
  <div className="legend-item-container">
    <div className="upper-legend">
      <div className="legend-item">
        <span className="legend-color not-visited"></span> Not Visited
      </div>
      <div className="legend-item">
        <span className="legend-color answered"></span> Answered
      </div>
    </div>
    <div className="legend-item bottom-legend">
      <span className="legend-color marked"></span> Marked for Review
    </div>
  </div>
</div>

      <hr/>
      <div className="question-buttons">
        {questions.map((_, index) => (
          <button 
            key={index} 
            className={`question-button ${status[index]}`} 
            onClick={() => handleQuestionClick(index)}
          >
            {index + 1}
          </button>
        ))}
      </div>
      <div className={`submit-btn ${isFullscreen ? 'fullscreen' : ''}`}>
        <button onClick={onSubmitTest}>
          Submit Test
        </button>
      </div>
    </div>
  );
};

export default Navigation;
